import React from 'react'
import { useRoutes } from 'react-router-dom'
import { ListRule } from './Rule'
import { AddRule } from './Rule/add'
import { ListSkill } from './Skill'
import { AddSkill } from './Skill/add'

function EmployeeRouter() {
  const routes = useRoutes([
    {
      path: '/skill',
      element: <ListSkill />
    },
    {
      path: '/skill/add',
      element: <AddSkill />
    },
    {
      path: '/rule',
      element: <ListRule />
    },
    {
      path: '/rule/add',
      element: <AddRule />
    }
  ])

  return <>{routes}</>
}

export default EmployeeRouter
