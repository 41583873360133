import React from 'react'
import { useRoutes } from 'react-router-dom'
import { List } from './List'
import { Services } from './Services'
import { Partitions } from './Shrink'
import { SalonAddPage } from './List/add'
import { SalonDetailtPage } from './List/detailt'

function SalonRouter() {
  const routes = useRoutes([
    {
      path: '/list',
      element: <List />
    },
    {
      path: '/list/add',
      element: <SalonAddPage />
    },
    {
      path: '/list/*',
      element: <SalonDetailtPage />
    },
    {
      path: '/service',
      element: <Services />
    },
    {
      path: '/shrink',
      element: <Partitions />
    }
  ])

  return <>{routes}</>
}

export default SalonRouter
