import '../index.scss'
import { useState } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Switch, Button } from 'antd'
import { getListSalon } from '../api'
import { EditOutlined } from '@ant-design/icons'

export const ListSkill = () => {
  const [reload, setReload] = useState(false)
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'ID',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Bậc kỹ năng',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Mô tả',
      dataIndex: 'ShortName',
      key: 'staffTypeName'
    },
    {
      title: 'Order',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Publish',
      key: 'Publish',
      render: (record) => (
        <Switch defaultChecked={record.Publish} onChange={() => handleChangeStatus(record, 'Publish')} />
      )
    },
    {
      title: 'Chỉnh sửa',
      key: 'Publish',
      render: (record) => (
        <Button onClick={() => handleChangeStatus(record, 'Publish')}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên Bậc kỹ năng',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên bậc kỹ năng'
      // require: true
    },
    {
      label: 'ID Bậc kỹ năng',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập ID bậc kỹ năng'
      // require: true
    },
    {
      label: 'Publish',
      param: 'Publish',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    },
    {
      label: 'Order',
      param: 'Order',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/employee/skill'
        title='Quản lý bậc kỹ năng'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListSalon}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/employee/skill/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
