import { author } from './config'

interface paramsProps {
  page: number
  pageSize: number
}
export const getListSalon = (params: paramsProps) => {
  return author.get(`/api/salon/list`, {
    params: params
  })
}
export const getListServiceSalon = (params: paramsProps) => {
  return author.get(`/api/salon-service/list`, {
    params: params
  })
}
