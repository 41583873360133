import '../index.scss'
import { Working } from './Working'
import { Employee } from './Employee'
import { Permission } from './Permission'
import { History } from './History'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useState } from 'react'

export const MenuStaff = () => {
  const [tab, setTab] = useState('1')

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Thông tin làm việc',
      children: <Working tab={tab} />
    },
    {
      key: '2',
      label: 'Thông tin nhân viên',
      children: <Employee tab={tab} />
    },
    {
      key: '3',
      label: 'Phân quyền',
      children: <Permission tab={tab} />
    },
    {
      key: '4',
      label: 'Lịch sử',
      children: <History tab={tab} />
    }
  ]
  return (
    <div className=' min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <Tabs onChange={(e: string) => setTab(e)} defaultActiveKey='1' items={items} />
    </div>
  )
}
