/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  TimePicker,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  message,
  Upload,
  Switch,
  InputNumber,
  Checkbox
} from 'antd'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import type { DatePickerProps } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const { TextArea } = Input
export const AddProductsPage: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [isAddedType, setIsAddedType] = useState(0)
  const [isSalon, setIsSalon] = useState(true)
  const [isGroup, setIsGroup] = useState()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const onValuesChange = async (changedValues: any) => {
    // if (changedValues?.provinceId !== undefined) {
    //   const res: any = await getDistrict(changedValues.provinceId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, districtId: res.data }))
    //   }
    //   form.resetFields(['districtId', 'wardId'])
    // }
    // if (changedValues?.districtId !== undefined) {
    //   const res: any = await getWard(changedValues.districtId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, wardId: res.data }))
    //   }
    //   form.resetFields(['wardId'])
    // }
    // if (changedValues?.salonId !== undefined) {
    //   const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
    //   form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    // }
    // if (changedValues?.addedType !== undefined) {
    //   setIsAddedType(changedValues?.addedType)
    // }
    // if (changedValues?.staffTypeId !== undefined) {
    //   const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
    //     (i) => i.id === changedValues?.staffTypeId
    //   )
    //   dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    //   if (changedValues?.staffTypeId === 17) setIsSalon(false)
    //   else setIsSalon(true)
    // }
  }
  const ListDepartment = [
    { label: 'Stylist', value: 1 },
    { label: 'Skiner', value: 2 },
    { label: 'Bảo vệ', value: 3 },
    { label: 'Chuyên viên tư vấn', value: 3 }
  ]
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  return (
    <div style={{ minHeight: 'calc(100vh)', margin: 'auto' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-5'>THÊM MỚI SẢN PHẨM</h3>
        <div className='mt-12 custom-form-details-form'>
          <div>
            <Form.Item
              label='Loại sản phẩm:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  // defaultValue='lucy'
                  style={{ width: '220px' }}
                  onChange={handleChange}
                  placeholder='Chọn loại sản phẩm'
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Select
                  // defaultValue='lucy'
                  style={{ width: '220px', margin: '0 20px' }}
                  onChange={handleChange}
                  placeholder='Chọn loại vật tư'
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Input defaultValue={'SP04105'} style={{ width: 120 }} />
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <span style={{ fontWeight: '500' }}>Combo</span>
                  <Checkbox defaultChecked={false} style={{ marginLeft: 10, marginTop: 2 }} />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label='Nhịp kiểm hàng:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 0 }}>
                  <span style={{ fontWeight: '500' }}>Kiểm hàng ngày</span>
                  <Checkbox defaultChecked={false} style={{ marginLeft: 15, marginTop: 2 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 90 }}>
                  <span style={{ fontWeight: '500' }}>Kiểm khi salon cần</span>
                  <Checkbox defaultChecked={false} style={{ marginLeft: 15, marginTop: 2 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 90 }}>
                  <span style={{ fontWeight: '500' }}>Không cần kiểm</span>
                  <Checkbox defaultChecked={false} style={{ marginLeft: 15, marginTop: 2 }} />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label='Đơn vị:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Select
                // defaultValue='lucy'
                style={{ width: '220px' }}
                onChange={handleChange}
                placeholder='Chọn Đơn vị'
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                  { value: 'disabled', label: 'Disabled', disabled: true }
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Tên danh mục/Tên nhóm sản phẩm:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Select
                  // defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  placeholder='Chọn danh mục'
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Select
                  // defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  placeholder='Chọn tên nhóm sản phẩm'
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
              </div>
            </Form.Item>
            <Form.Item
              label='Tên sản phẩm/Barcode:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Nhập tên sản phẩm' />
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Nhập barcode' />
              </div>
            </Form.Item>
            <Form.Item
              label='Thương hiệu/Map 30shine Store:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Select
                  // defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  placeholder='Chọn thương hiệu'
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='30shinestore Id' />
              </div>
            </Form.Item>
            <Form.Item
              label='Tên model/Thể tích:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Tên model' />
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Thể tích' />
              </div>
            </Form.Item>
            <Form.Item label='Mô tả:' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <TextArea maxLength={600} placeholder='Nhập mô tả' style={{ height: 120 }} />
            </Form.Item>
            <Form.Item
              label='Giá nhập:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 1000000' />
                <span style={{ fontWeight: '500', marginLeft: 20 }}>VNĐ</span>
              </div>
            </Form.Item>
            <Form.Item
              label='Giá bán:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 1000000' />
                <span style={{ fontWeight: '500', marginLeft: 20 }}>VNĐ</span>
              </div>
            </Form.Item>
            <Form.Item
              label='Khuyến mại giảm giá:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 5' />
                <span style={{ fontWeight: '500', marginLeft: 20 }}> ( % )</span>
              </div>
            </Form.Item>
            <Form.Item
              label='Chiết khấu lương:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 5' />
                <span style={{ fontWeight: '500', marginLeft: 20 }}> ( % )</span>
              </div>
            </Form.Item>
            <Form.Item
              label='Order:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 1' />
            </Form.Item>
            <Form.Item
              label='Hệ số ngày chờ hàng:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 1' />
            </Form.Item>
            <Form.Item
              label='Mặc định tồn max:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input style={{ width: 'calc(50% - 10px)' }} placeholder='Ví dụ: 1' />
            </Form.Item>
            <Form.Item
              label='CampaignID:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input style={{ width: '220px' }} placeholder='CampaignID' />
            </Form.Item>
            <Form.Item
              label='Số ngày sử dụng:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <InputNumber min={1} max={50000} defaultValue={3} />
            </Form.Item>
            <Form.Item
              label='Nổi bật:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Checkbox defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label='Publish:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Checkbox defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label='Ghi nhận tiền thu trước:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Checkbox defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label='Sản phẩm tồn kho (Hóa chất):'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Checkbox defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label='Theo dõi mức dùng vật tư:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Checkbox defaultChecked={true} />
            </Form.Item>
            <Form.Item
              label='Sản phẩm ngừng kinh doanh:'
              name='userName'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Checkbox defaultChecked={true} />
            </Form.Item>

            <Form.Item label='Hình ảnh:' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} className='mt-8'>
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                beforeUpload={beforeUpload}
                onChange={handleChangeImage}
              >
                {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Form.Item label='' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Button
            className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
