import React, { useState, Dispatch, SetStateAction, Key, useRef } from 'react'
import { Button, Modal, notification, Form, Input } from 'antd'
import { validateVariantName } from '../utils/validate'
import ItemBox from './ItemBox'
import ApiEndpoint from '../api'
type FieldType = {
  title: string
}
const initListImage = ['', '', '', '']

const ListText = [
  { text: 'Ảnh chụp chính diện' },
  { text: 'Ảnh chụp đằng sau' },
  { text: 'Ảnh chụp bên trái' },
  { text: 'Ảnh chụp bên phải' }
]
interface ModalVariantProps {
  nameHairStye: string
  idHairStye: string
  setIsModalVariantOpen: Dispatch<SetStateAction<boolean>>
  isModalVariantOpen: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleGetData: any
}

const ModalVariantData: React.FC<ModalVariantProps> = ({
  nameHairStye,
  idHairStye,
  setIsModalVariantOpen,
  isModalVariantOpen,
  setLoading,
  handleGetData
}) => {
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [listImage, setListImage] = useState(initListImage)
  const [isChange, setIsChange] = useState(false)
  const formRef = useRef(null)

  const handleFileChange = (index: number, url: string) => {
    const images = [...listImage]
    images[index] = url
    setListImage(images)
    setIsChange(true)
  }
  const handleOk = () => {
    setIsModalVariantOpen(false)
    setLoading(true)
  }
  const handleCancel = () => {
    setIsModalVariantOpen(false)
    formRef.current.resetFields()
    setListImage(initListImage)
  }
  const onFinish = async (values: FieldType) => {
    // Xử lý khi Form hoàn thành
    console.log('Received values:', values)
    try {
      listImage.forEach(function (element) {
        if (element === '') {
          throw new Error('Bạn cần upload đủ 4 ảnh của kiểu tóc')
        }
      })
      const body = {
        id: idHairStye,
        name: nameHairStye,
        hairStyleDetails: [
          {
            name: values.title,
            createdBy: 'Admin',
            isDelete: false,
            urlImage: listImage
          }
        ]
      }
      setLoading(true)
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công thêm mới biến thể cho kiểu tóc'
        })
        formRef.current.resetFields()
        setListImage(initListImage)
        handleGetData()
        setLoading(false)
        setIsModalVariantOpen(false)
      }
    } catch (error) {
      notification.error({
        message: 'Thất bại',
        description: `${error}`
      })
      setLoading(false)
      setIsModalVariantOpen(false)
    }
  }
  const onFinishFailed = (errorInfo: {
    values: FieldType
    errorFields: { name: Key[]; errors: string[] }[]
    outOfDate: boolean
  }) => {
    console.log('Failed:', errorInfo)
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setInputValue(value)
    setDisableSubmit(!value)
  }
  const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
  return (
    <>
      <Modal open={isModalVariantOpen} footer={false} onOk={handleOk} onCancel={handleCancel}>
        <div className='modal'>
          <Form
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            style={{ width: '100%', marginTop: 10 }}
            ref={formRef}
          >
            <div className='header'>
              <div className='style-title'>Kiểu Layer</div>
              <Form.Item<FieldType>
                label=''
                name='title'
                rules={[{ validator: (_, value) => validateVariantName(idHairStye, value, '', '') }]}
                style={{ marginTop: 5, width: '100%' }}
              >
                <Input
                  placeholder='Nhập tên biến thể'
                  onKeyPress={preventEnterSubmit}
                  onChange={handleInputChange}
                  style={{ marginTop: 15 }}
                  //   value={inputValue}
                />
              </Form.Item>
              <div style={{ fontSize: 15 }}>Bạn cần upload đủ 4 ảnh của kiểu tóc</div>
            </div>
            <div className='body grid grid-cols-2 gap-4 body-row'>
              {listImage?.map((item, index) => (
                <ItemBox
                  key={index}
                  index={index}
                  image={listImage[index]}
                  text={ListText[index].text}
                  onFileChange={(url) => handleFileChange(index, url)}
                />
              ))}
            </div>
            <div className='footer'>
              <Button
                type='primary'
                disabled={disableSubmit}
                className={disableSubmit ? 'save-button2' : 'save-button'}
                htmlType='submit'
              >
                Lưu
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default ModalVariantData
