import { useContext, useEffect, useState } from 'react'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { Checkbox } from 'antd'
import { AppContext } from 'layouts/DashboardLayout'
import { getSalons } from './api'

export const BookingList = () => {
  const [reload, setReload] = useState(false)
  const [options, setOptions] = useState({ salon: [] })
  const { notification } = useContext(AppContext)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Tên khác hàng',
      dataIndex: 'customer_name',
      key: 'customer_name'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Giờ đặt lịch',
      dataIndex: 'booking_time',
      key: 'booking_time'
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'booking_date',
      key: 'booking_date'
    },
    {
      title: 'Stylist book',
      dataIndex: 'stylist_book',
      key: 'stylist_book'
    },

    {
      title: 'Stylist hiện tại',
      dataIndex: 'stylist_current',
      key: 'stylist_current'
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'BookStylist',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate',
      render: (item: boolean) => <Checkbox checked={true}></Checkbox>
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'date',
      type: TypeSearch.date,
      buttonDate: true
    },

    {
      label: 'Chọn salon',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: options.salon,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Số điện thoại',
      type: TypeSearch.input,
      param: 'phone',
      placeholder: 'Số điện thoại...'
    },
    {
      label: 'trạng thái',
      param: 'statusName',
      type: TypeSearch.select,
      defaultValue: 3,
      options: [
        { value: 1, label: 'Tất cả' },
        { value: 2, label: 'Đã đến' },
        { value: 3, label: 'Chưa đến/không đến' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const handleFetchData = async () => {
    try {
      const [salon]: any = await Promise.all([getSalons()])
      if (salon.statusCode === 0) {
        setOptions({
          salon: [...salon.data]
        })
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/booking/list'
        title='Danh sách'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        // linkADD='/add'
        reload={reload}
        setReload={setReload}
        middleComponent={
          <div className='mt-4'>
            <span className='border border-[#ccc] pt-2 pl-2 pr-2 pb-2 border-r-0'>Tổng lượt book</span>
            <span className='border border-[#ccc] pt-2 pl-2 pr-2 pb-2'>0</span>
          </div>
        }
      ></FormComponent>
    </div>
  )
}
