import { author } from './config'

interface paramsProps {
  page: number
  pageSize: number
}
export const getListStylist = (params: paramsProps) => {
  return author.get(`/api/report-performance/stylist-list`, {
    params: params
  })
}
export const getListSkiner = (params: paramsProps) => {
  return author.get(`/api/report-performance/skiner-list`, {
    params: params
  })
}