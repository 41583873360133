import ApiEndpoint from '../api'

const validateHairStyleName = async (value: string) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập tên kiểu tóc')
  }
  if (value.includes('  ')) {
    return Promise.reject('Tên kiểu tóc không được chứa hơn 2 khoảng trắng')
  }
  if (value.length > 50) {
    return Promise.reject('Tên kiểu tóc không được vượt quá 50 ký tự')
  }

  try {
    const response: any = await ApiEndpoint.CheckTitle(value)
    const { statusCode, data } = response
    if (statusCode === 200 && !data) {
      return Promise.reject('Tên kiểu tóc đã tồn tại hoặc không hợp lệ')
    }
  } catch (error) {
    console.error('Error checking Name:', error)
    return Promise.reject('Lỗi API Check tên kiểu tóc !')
  }

  return Promise.resolve()
}

const validateVariantName = async (id: string, value: string, name: string, idDetail: string) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập tên biến thể')
  }
  if (value.length > 50) {
    return Promise.reject('Tên biến thể không được vượt quá 50 ký tự')
  }
  if (value !== name) {
    if (value.includes('  ')) {
      return Promise.reject('Tên biến thể không được chứa hơn 2 khoảng trắng')
    }
    try {
      const response: any = await ApiEndpoint.CheckTitleVariant(id, idDetail, value)
      const { statusCode, data } = response
      if (statusCode === 200 && !data) {
        return Promise.reject('Tên biến thể đã tồn tại hoặc không hợp lệ')
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      return Promise.reject('Lỗi API Check tên biến thể !')
    }
  }

  return Promise.resolve()
}

export { validateHairStyleName, validateVariantName }
