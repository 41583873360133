/* eslint-disable @typescript-eslint/ban-ts-comment */
import '../index.scss'
import dayjs from 'dayjs'
import { Form, Button, Select, DatePicker } from 'antd'
import { useEffect, useMemo, useState, useContext } from 'react'
import { listSearch } from '../constant'
import { setSearchParams } from '../staffSlice'
import { ColumnSearch_instance_type, ListSearchType, ParamsSearchType } from '../interface'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { AppContext } from 'layouts/DashboardLayout'
import { removeVietnamese } from 'utils/selector/removeVietnamese'
const { RangePicker } = DatePicker

type TypeFormSearch = {
  setColumnSearchExpend: (item: ColumnSearch_instance_type[]) => void
  columnSearchExpend: ColumnSearch_instance_type[]
  setFilterVisible: (value: boolean) => void
}
export default function FormSearchExpend({
  setFilterVisible,
  setColumnSearchExpend,
  columnSearchExpend
}: TypeFormSearch) {
  const dispatch = useAppDispatch()
  const { resource, searchParams }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)
  const { notification } = useContext(AppContext)

  const [form] = Form.useForm()
  const [listOption, setListOption] = useState(listSearch)
  const [optionValue, setOptionValue] = useState()

  const onFinish = (value: ParamsSearchType) => {
    switch (value.filterType) {
      case 'birthday':
        dispatch(
          setSearchParams({
            ...searchParams,
            birthday: [
              value?.birthday && value.birthday[0] ? dayjs(value.birthday[0]).format('YYYY-MM-DD') : '',
              value?.birthday && value.birthday[1] ? dayjs(value.birthday[1]).format('YYYY-MM-DD') : ''
            ]
          })
        )
        break

      default:
        dispatch(
          setSearchParams({
            ...searchParams,
            // @ts-ignore: Unreachable code error
            [value?.filterType as string]: value[`${value?.filterType}`]
          })
        )
        break
    }
  }
  const handlePinColum = async () => {
    await form
      .validateFields(['filterType'])
      .then(async () => {
        console.log('Validators đã được kiểm tra và form hợp lệ.')
        const columStorage = JSON.parse((await localStorage.getItem('SEARCH_COLUMN')) as string)
        const value = form.getFieldsValue()
        let result = form.getFieldValue('filterType')
        const fieldValue = form.getFieldValue(result)
        if (result === undefined) notification('error', 'Lỗi', 'Vui lòng chọn trường tìm kiếm')
        if (value.filterType === undefined) result = Object.keys(value)[1]
        const newColumn = columStorage.map((i: ColumnSearch_instance_type) => {
          if (i.column === result) return { ...i, show: true }
          return i
        })
        await localStorage.setItem('SEARCH_COLUMN', JSON.stringify(newColumn))
        setColumnSearchExpend([...newColumn])
        form.resetFields()
        setOptionValue(undefined)
        // set value form search
        if (result === 'birthday') {
          const data = result
            ? [dayjs(fieldValue[0]).format('YYYY-MM-DD'), dayjs(fieldValue[1]).format('YYYY-MM-DD')]
            : undefined
          dispatch(
            setSearchParams({
              ...searchParams,
              [result]: data
            })
          )
        } else {
          dispatch(
            setSearchParams({
              ...searchParams,
              [result]: fieldValue
            })
          )
        }
      })
      .catch((errorInfo) => {
        console.log('Form không hợp lệ:', errorInfo)
      })
  }

  const componentListSearch = useMemo(() => {
    return [
      {
        key: 'birthday',
        type: 'RangePicker',
        value: (
          <Form.Item key='birthday' name='birthday' rules={[{ required: true, message: 'Không được để trống' }]}>
            <RangePicker
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              format={'DD-MM'}
              disabledDate={(current) => {
                const currentDate = dayjs()
                const startDate = currentDate.startOf('year')
                const endDate = currentDate.endOf('year')
                return current < startDate || current > endDate
              }}
              onClick={(e) => {
                e.preventDefault()
                const arr = window.document.querySelectorAll(
                  '.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn, .ant-picker-year-btn'
                )
                if (arr.length > 0) {
                  arr.forEach((e: any) => (e.style.display = 'none'))
                }
              }}
            />
          </Form.Item>
        )
      },
      {
        key: 'asms',
        type: 'Select',
        value: (
          <Form.Item key='asms' name='asms' rules={[{ required: true, message: 'Không được để trống' }]}>
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn vùng ASM'
              options={role === 'ADMIN' ? resource.asms : []}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      },
      {
        key: 'SalonId',
        type: 'Select',

        value: (
          <Form.Item key='SalonId' name='SalonId' rules={[{ required: true, message: 'Không được để trống' }]}>
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn Salon'
              fieldNames={{ label: 'name', value: 'id' }}
              options={userInfo.listSalon}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      },
      {
        key: 'StaffType',
        type: 'Select',

        value: (
          <Form.Item key='StaffType' name='StaffType' rules={[{ required: true, message: 'Không được để trống' }]}>
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn bộ phận'
              options={[...resource.staffTypeId, ...resource.staffTypeId2]}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      },
      {
        key: 'SkillLevelId',
        type: 'Select',

        value: (
          <Form.Item
            key='SkillLevelId'
            name='SkillLevelId'
            rules={[{ required: true, message: 'Không được để trống' }]}
          >
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn bậc kĩ năng'
              options={resource.allSkillLevel}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      },
      {
        key: 'PermissionIds',
        type: 'Select',

        value: (
          <Form.Item
            key='PermissionIds'
            name='PermissionIds'
            rules={[{ required: true, message: 'Không được để trống' }]}
          >
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn nhóm quyền'
              options={resource.permission}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      },
      {
        key: 'ProfileTypes',
        type: 'Select',
        value: (
          <Form.Item
            key='ProfileTypes'
            name='ProfileTypes'
            rules={[{ required: true, message: 'Không được để trống' }]}
          >
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn loại hồ sơ'
              options={resource.profileTypes}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      },
      {
        key: 'ContractTypes',
        type: 'Select',

        value: (
          <Form.Item
            key='ContractTypes'
            name='ContractTypes'
            rules={[{ required: true, message: 'Không được để trống' }]}
          >
            <Select
              allowClear
              mode='multiple'
              placeholder='Chọn loại hợp đồng'
              options={resource.contractType}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </Form.Item>
        )
      }
    ]
  }, [])

  useEffect(() => {
    setListOption(
      listSearch.filter((item: ListSearchType) => {
        return !columnSearchExpend.find((i: ColumnSearch_instance_type) => i.column === item.value)?.show
      })
    )
  }, [columnSearchExpend])

  return (
    <div className='w-[300px] max-w-[300px] p-0'>
      <Form className='flex flex-col gap-y-2' form={form} onFinish={onFinish}>
        <Button
          onClick={handlePinColum}
          className='text-[10px] h-6 bg-[#C6EEFF] border-[#C6EEFF] absolute top-2 right-3'
        >
          Lưu trường này
        </Button>
        <p>Hiển thị tất cả nhân sự theo</p>

        <Form.Item
          name='filterType'
          style={{ marginBottom: 0 }}
          rules={[{ required: true, message: 'Không được để trống' }]}
        >
          <Select
            showSearch
            allowClear
            onChange={(e) => {
              setOptionValue(e)
            }}
            value={optionValue}
            options={listOption}
            placeholder='Chọn trường tìm kiếm'
            filterOption={(input: any, option: any) =>
              removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
            }
          />
        </Form.Item>
        {optionValue && <div>Là</div>}

        {componentListSearch.find((i) => i.key === optionValue)?.value}
        <div className='flex justify-between'>
          <Button onClick={() => setFilterVisible(false)} type='default'>
            Hủy
          </Button>
          <Button className='bg-[#4096ff]' htmlType='submit' type='primary'>
            Thêm điều kiện lọc
          </Button>
        </div>
      </Form>
    </div>
  )
}
