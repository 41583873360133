import { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Checkbox, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'

export const ServiceListPage = () => {
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)
  const handleGoDetailt = (item: any) => {
    console.log(item)
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Mô tả',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Giá',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Phụ trợ (Miễn phí)',
      key: 'staffName',
      render: (item) => <Checkbox />
    },
    {
      title: 'Nổi bật',
      key: 'staffName',
      render: (item) => <Checkbox />
    },
    {
      title: 'Publish',
      key: 'staffName',
      render: (item) => <Checkbox />
    },
    {
      title: 'Chấm SCSC',
      key: 'staffName',
      render: (item) => <Checkbox />
    },
    {
      title: 'Chấm Uốn',
      key: 'staffName',
      render: (item) => <Checkbox />
    },
    {
      title: 'Công cụ',
      key: 'Publish',
      render: (record) => (
        <Button onClick={() => handleGoDetailt(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên dịch vụ',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Họ và tên/ID/Tên đăng nhập'
      // require: true
    },
    {
      label: 'Mã dịch vụ',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Họ và tên/ID/Tên đăng nhập'
      // require: true
    },
    {
      label: 'Khoảng giá',
      param: 'statusName',
      type: TypeSearch.select,
      // defaultValue: 1,
      options: [
        { label: 'dưới 50.000', value: 1 },
        { label: '50.000 - 200.000', value: 2 },
        { label: '200.000 - 500.000', value: 3 },
        { label: '500.000 - 1.000.000', value: 4 },
        { label: '1.000.000 - 3.000.000', value: 5 },
        { label: 'trên 3.000.000', value: 6 }
      ],
      placeholder: 'Nhập khoảng giá',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Publish',
      param: 'statusName',
      type: TypeSearch.select,
      // defaultValue: 1,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ],
      placeholder: 'Publish',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Chấm SCSC',
      param: 'statusName',
      type: TypeSearch.select,
      // defaultValue: 1,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ],
      placeholder: 'Chấm SCSC',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Chấm Uốn',
      param: 'statusName',
      type: TypeSearch.select,
      // defaultValue: 1,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ],
      placeholder: 'Chấm Uốn',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/service/list'
        title='Quản lý dịch vụ'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/service/list/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
