import React, { useEffect, useState } from 'react';
import { Transfer } from 'antd';
import type { TransferDirection } from 'antd/es/transfer';

interface RecordType {
    key: string;
    name: string;
    chosen: boolean;
}

interface Props {
    listDataP: { id: number; name: string }[];
    setSelectedList: any;
    listDataSelected: { id: number; name: string }[];
}

export default function TransferComponent(props: Props) {
    const { listDataP, setSelectedList, listDataSelected } = props;
    const [listData, setListData] = useState<RecordType[]>([]);
    const [targetDataKeys, setTargetDataKeys] = useState<string[]>([]);

    useEffect(() => {
        if (listDataSelected) {
            const targetDataKeys = listDataSelected.map(item => item.id.toString());
            setTargetDataKeys(targetDataKeys);
        }

        const tempListData = listDataP.map((data: any) => {
            const chosen = listDataSelected?.some(selectedItem => selectedItem.id === data.id);
            return {
                key: data.id.toString(),
                name: data.name,
                chosen: chosen,
            };
        });

        setListData(tempListData);
    }, [listDataP, listDataSelected]);

    useEffect(() => {
        const updatedChosenItems = listData
            .filter((item) => targetDataKeys.includes(item.key))
            .map((item) => ({ id: parseInt(item.key), name: item.name }));

        setSelectedList(updatedChosenItems);
    }, [listData, targetDataKeys]);

    const filterOption = (inputValue: string, option: RecordType) =>
        option.name.indexOf(inputValue) > -1;

    const handleChange = (newTargetKeys: string[]) => {
        const addedItems = newTargetKeys.filter(
            (key) => !targetDataKeys.includes(key)
        );
        const removedItems = targetDataKeys.filter(
            (key) => !newTargetKeys.includes(key)
        );

        const updatedListData = listData.map((item) => {
            if (addedItems.includes(item.key)) {
                return { ...item, chosen: true };
            }
            if (removedItems.includes(item.key)) {
                return { ...item, chosen: false };
            }
            return item;
        });

        setListData(updatedListData);
        setTargetDataKeys(newTargetKeys);
    };

    const handleSearch = (dir: TransferDirection, value: string) => {
        console.log('search:', dir, value);
    };

    return (
        <>
            <Transfer
                titles={['', 'Đã chọn']}
                dataSource={listData}
                showSearch
                filterOption={filterOption}
                targetKeys={targetDataKeys}
                onChange={handleChange}
                onSearch={handleSearch}
                render={(item) => item.name}
            />
        </>
    );
}
