import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

interface Props {
    data: any
}
export default function ViewNotification(props: Props) {
    const { data } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button
                style={{ display: "flex", alignItems: "center", marginRight: 10 }}
                type="primary"
                icon={<EyeOutlined />}
                shape="round"
                onClick={showModal}
            >
                Xem
            </Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={700}
            >
                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "60%" }}>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                            <div style={{ width: "130px", marginRight: "10px" }}>
                                Tên thông báo
                            </div>
                            <div style={{ display: "flex", fontWeight: "600" }} >
                                {data.name[0].label}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                            <div style={{ width: "130px", marginRight: "10px" }}>
                                Ngày bắn/ Ngày đã lên lịch bắn
                            </div>
                            <div style={{ display: "flex", fontWeight: "600" }}>
                                {data.date}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                            <div style={{ width: "130px", marginRight: "10px" }}>
                                Trạng thái
                            </div>
                            <span style={{ fontWeight: "600" }}>
                                {data.status === 1 ? "Đã bắn" : "Đã lên lịch"}
                            </span>
                        </div>

                        {data?.type == 1 ? (
                            <div>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                    <div style={{ width: "130px", marginRight: "10px" }}>
                                        Danh sách salon
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                        {data.listSalon?.map((salon) => (
                                            <span key={salon.id} style={{ marginRight: "5px", fontWeight: "600" }}>{salon.name},</span>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                    <div style={{ width: "130px", marginRight: "10px" }}>
                                        Danh sách bộ phận
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        {data.listPosition?.map((salon) => (
                                            <span key={salon.id} style={{ marginRight: "5px", fontWeight: "600" }}>{salon.name},</span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    File data
                                </div>
                                <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                    {data.fileData}
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{ width: "40%" }}>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                            <div style={{ width: "130px", marginRight: "10px" }}>
                                Người tạo
                            </div>
                            <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                {data.createdBy}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                            <div style={{ width: "130px", marginRight: "10px" }}>
                                Tổng data
                            </div>
                            <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                {data.totalData}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                            <div style={{ width: "130px", marginRight: "10px" }}>
                                Lượt click
                            </div>
                            <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                {data.totalSeen}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
};