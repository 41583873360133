import { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import { ColumnConfig, SearchConfig, TypeSearch } from './util'
import FormComponent from './Form'

export const BaseListPage = () => {
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'ID nhân viên',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Tên nhân viên',
      key: 'staffName',
      render: (item) => (
        <Link className='text-[#1677ff] hover:text-black' to={`/staff/detail/${item.staffIdMongo}`}>
          {item.staffName}
        </Link>
      )
    },
    {
      title: 'Bộ phận',
      dataIndex: 'staffTypeName',
      key: 'staffTypeName'
    },
    {
      title: 'Bậc',
      dataIndex: 'skillLevelName',
      key: 'skillLevelName'
    },
    {
      title: 'Salon hiện tại',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'Salon thay đổi',
      dataIndex: 'salonChangeName',
      key: 'salonChangeName'
    },

    {
      title: 'Trạng thái',
      dataIndex: 'statusName',
      key: 'statusName'
    },
    {
      title: 'Ngày biến động',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (item: Date) => <div>{item ? dayjs(item).format('DD/MM/YYYY') : ''}</div>
    },
    {
      title: 'Ngày phát sinh công cuối cùng',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate',
      render: (item: Date) => <div>{item ? dayjs(item).format('DD/MM/YYYY') : ''}</div>
    },
    {
      title: 'Tổng thâm niên xét lên bậc',
      dataIndex: 'totalSeniorityLevelUp',
      key: 'totalSeniorityLevelUp'
    },
    {
      title: 'Tổng thâm niên nghề tại 30shine',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Họ và tên',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Họ và tên/ID/Tên đăng nhập',
      require: true
    },
    {
      label: 'Chọn salon',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: resource.salonId,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' },
      require: true
    },
    {
      label: 'trạng thái',
      param: 'statusName',
      type: TypeSearch.select,
      options: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 2, label: 'Nghỉ tạm thời' },
        { value: 0, label: 'Xin nghỉ' },
        { value: 3, label: 'Cho nghỉ' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Ngày tạo',
      param: 'createdDate',
      type: TypeSearch.date,
      buttonDate: true,
      dateDefault: true
    },
    {
      label: 'Tổng thâm niên xét lên bậc',
      param: 'totalSeniorityLevelUp',
      type: TypeSearch.input,
      placeholder: 'Tổng thâm niên xét lên bậc'
    },
    {
      label: 'Yêu cầu',
      param: 'type',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/base'
        title='title'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        // linkADD='/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
