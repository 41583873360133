import { createSlice } from '@reduxjs/toolkit'
const initStaff = {
  staffTypeId: [],
  staffTypeId2: [],
  skillLevel: [],
  salonId: [],
  provinceId: [],
  districtId: [],
  recruitmentSource: [],
  permission: [],
  profileTypes: [],
  contractType: [],
  asms: [],
  managers: [],
  wardId: [],
  allSkillLevel: [],
  gender: [
    { value: 1, label: 'Nam' },
    { value: 2, label: 'Nữ' },
    { value: 3, label: 'Giới tính khác' }
  ],
  groupId: [
    { name: 'Salon', id: 1 },
    { name: 'Văn phòng', id: 2 }
  ],
  addedType: [
    { value: 1, label: 'Mã chính' },
    { value: 2, label: 'Mã kiêm nhiệm' },
    { value: 3, label: 'Mã ảo' }
  ],
  requireEnroll: [
    { value: true, label: 'Có' },
    { value: false, label: 'Không' }
  ],
  appearence: [
    { value: 1, label: 'Tốt' },
    { value: 2, label: 'Trung bình' },
    { value: 3, label: 'Dưới trung bình' }
  ],
  isAccountLogin: [
    { value: false, label: 'Tài khoản nhân viên' },
    { value: true, label: 'Tài khoản đăng nhập' }
  ],
  active: [
    { value: 1, label: 'Đang hoạt động' },
    { value: 2, label: 'Nghỉ tạm thời' },
    { value: 0, label: 'Xin nghỉ' },
    { value: 3, label: 'Cho nghỉ' }
  ]
}
const innitState = {
  resource: initStaff,
  staffDetail: {},
  searchParams: {
    Keyword: undefined,
    ActiveStatus: undefined,
    AddedType: undefined,
    PageSize: 10,
    PageIndex: 1
  },
  searchParamsStaffChange: {
    Keyword: undefined,
    birthday: undefined,
    ActiveStatus: undefined,
    StaffType: undefined,
    GroupId: undefined,
    SalonId: undefined,
    PageSize: 10,
    PageIndex: 1
  }
}

const staffSlice = createSlice({
  name: 'staff',
  initialState: innitState,
  reducers: {
    setResource: (state, action) => {
      state.resource = action.payload
    },
    setStaff: (state, action) => {
      state.staffDetail = action.payload
    },
    setSearchParams: (state, action) => {
      state.searchParams = action.payload
    },
    setSearchParamsStaffChange: (state, action) => {
      state.searchParamsStaffChange = action.payload
    },
    setResourceByKey: (state, action) => {
      state.resource[action.payload.key] = action.payload.data
    },
    resetStaff: (state) => {
      state.staffDetail = initStaff
    }
  }
})

const { reducer: userReducer, actions } = staffSlice
export const { setResource, setStaff, setSearchParams, setSearchParamsStaffChange, setResourceByKey, resetStaff } =
  actions
export default userReducer
