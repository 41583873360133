import { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { Button } from 'antd'

export const ApproveShineMemberPage = () => {
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Số ĐT cũ',
      dataIndex: 'old_phone',
      key: 'old_phone'
    },
    {
      title: 'Tên KH',
      dataIndex: 'customer',
      key: 'customer'
    },
    {
      title: 'Bill ID',
      dataIndex: 'bill_id',
      key: 'bill_id'
    },
    {
      title: 'Số ĐT mới',
      dataIndex: 'new_phone',
      key: 'new_phone'
    },
    {
      title: 'Người đề nghị',
      dataIndex: 'suggester',
      key: 'suggester'
    },
    {
      title: 'Ghi chú của Telesale',
      dataIndex: 'note',
      key: 'note'
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
      render: (item: Date) => <div>{item ? dayjs(item).format('DD/MM/YYYY') : ''}</div>
    },
    {
      title: 'Kết quả',
      dataIndex: 'result',
      key: 'result',
      render: (item: Date) => (
        <div className='flex justify-center gap-x-2'>
          <Button>Xác nhận</Button>
          <Button>Hủy</Button>
        </div>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      type: TypeSearch.date,
      param: 'date'
    },
    {
      label: 'Trạng thái',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: [
        { value: 1, label: 'Chờ duyệt' },
        { value: 2, label: 'Duyệt' },
        { value: 3, label: 'Không duyệt' }
      ],
      placeholder: 'Chọn trạng thái'
    },
    {
      label: 'Kết quả',
      param: 'statusName',
      type: TypeSearch.select,
      defaultValue: 1,
      options: [
        { value: 1, label: 'Thay đổi' },
        { value: 2, label: 'Hủy' }
      ],
      placeholder: 'Chọn kết quả'
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/shinemember/approve'
        title='Cập nhật Shine Member'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        // linkADD='/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
