import '../index.scss'
import { useState, useMemo } from 'react'
import { SearchConfig, TypeSearch } from '../../base/util'
import { ColumnConfig } from '../type'
import FormComponent from '../../base/Form'
import { Switch, Button, Tooltip } from 'antd'
import { getListStylist } from '../api'
import { EditOutlined, FileDoneOutlined } from '@ant-design/icons'

export const ListService = () => {
  const [reload, setReload] = useState(false)
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const [arrow, setArrow] = useState('Show')

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false
    }

    if (arrow === 'Show') {
      return true
    }

    return {
      pointAtCenter: true
    }
  }, [arrow])
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'Ngày',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Tên Salon',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'ShortName',
      key: 'staffTypeName'
    },
    {
      title: 'Số lượng',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Đơn giá',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Thành tiền',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Chiết khấu hàng bán(%)',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Tiền chiết khấu',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Tiền giảm trừ thẻ trả trước shinecombo',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: (
        <Tooltip placement='topLeft' title={'(VNĐ) từ sử dụng TK Topup'}>
          <span>Tổng doanh thu DV phát sinh</span>
        </Tooltip>
      ),
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: (
        <Tooltip placement='topLeft' title={'(chưa bao gồm doanh thu phát sinh từ Topup)(VNĐ)'}>
          <span>Doanh thu dịch vụ</span>
        </Tooltip>
      ),
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Chiết khấu lũy kế (%)',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: (
        <Tooltip placement='topLeft' title={'(VND) từ việc sử dụng TK topup'}>
          <span>Chiết khấu Doanh thu DV phát sinh</span>
        </Tooltip>
      ),
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: (
        <Tooltip
          placement='topLeft'
          title={
            '= Tổng thành tiền - Tiền chiết khấu - tiền giảm trừ thẻ trả trước SC - Chiết khấu Doanh thu DV từ việc sử dụng TK topup'
          }
        >
          <span>Tổng doanh thu DV thực tế phát sinh</span>
        </Tooltip>
      ),
      dataIndex: 'Address',
      key: 'skillLevelName'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'createdDate',
      type: TypeSearch.date,
      require: true,
      buttonDate: true
    },
    {
      label: 'Tên sản phẩm',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên sản phẩm'
      // require: true
    },
    {
      label: 'Chọn tất cả Salon',
      param: 'salon',
      type: TypeSearch.select,
      options: [
        { label: 'VP HCM', value: '1' },
        { label: 'VP HN', value: '2' },
        { label: 'CSVC HN', value: '3' },
        { label: 'CSVC HCM', value: '4' },
        { label: 'QLCL', value: '5' },
        { label: '346 KT', value: '6' },
        { label: '235 DC', value: '7' },
        { label: '168 NVC', value: '8' },
        { label: '386 NGT BN', value: '9' }
      ],
      placeholder: 'Chọn tất cả Salon',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const dataReport = [
    {
      key: 1,
      title: 'Tổng số lần sử dụng dịch vụ',
      number: '15.342'
    },
    {
      key: 2,
      title: 'Tổng tiền',
      number: '1.473.431.000'
    },
    {
      key: 3,
      title: 'Tổng chiết khấu',
      number: '15.342'
    },
    {
      key: 4,
      title: 'Tổng doanh thu DV phát sinh (VNĐ) từ sử dụng TK Topup',
      number: '1.473.431.000'
    },
    {
      key: 5,
      title: 'Chiết khấu Doanh thu DV phát sinh (VND) từ việc sử dụng TK topup',
      number: '15.342'
    },
    {
      key: 6,
      title: 'Tổng doanh thu DV thực tế phát sinh	1.355.169.000',
      number: '1.473.431.000'
    }
  ]
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/report/service'
        title='Báo cáo Dịch vụ'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStylist}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        // linkADD='/employee/skill/add'
        reload={reload}
        setReload={setReload}
        middleComponent={
          <div className='service-report-table'>
            <div className='service-report-table-header'>
              <FileDoneOutlined className='service-report-table-header-icon' />
              <span className='service-report-table-header-text'>Báo cáo dịch vụ</span>
            </div>
            <div className='service-report-table-body'>
              {dataReport.map((report, index) => (
                <>
                  <div className='service-report-table-body-item' key={report.key}>
                    <div className='service-report-table-body-item-title'>{report.title}</div>
                    <div className='service-report-table-body-item-number'>{report.number}</div>
                  </div>
                </>
              ))}
            </div>
          </div>
        }
      ></FormComponent>
    </div>
  )
}
