import { TeamOutlined, MenuOutlined, UserSwitchOutlined, ScissorOutlined, HeatMapOutlined } from '@ant-design/icons'
import { MenuProps } from 'pages/staff/interface'

const MENU_WEBFORM = [
  {
    isParent: true,
    key: 'csvc',
    path: '',
    title: 'CSVC',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/csvc/add',
        path: '/csvc/add',
        title: 'Thêm mới yêu cầu',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/csvc/list',
        path: '/csvc/list',
        title: 'Thống kê sự cố',
        icon: <UserSwitchOutlined />
      },
      {
        isParent: true,
        key: '/csvc/process',
        path: '/csvc/process',
        title: 'Tiến độ hoàn thành',
        icon: <TeamOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'report',
    path: '',
    title: 'Báo cáo',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/report/perfomance-v2',
        path: '/report/perfomance-v2',
        title: 'Năng suất V2',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/report/topup',
        path: '/report/topup',
        title: 'Topup',
        icon: <UserSwitchOutlined />
      },
      {
        isParent: true,
        key: '/report/service',
        path: '/report/service',
        title: 'Dịch vụ',
        icon: <UserSwitchOutlined />
      },
      {
        isParent: true,
        key: '/report/cosmetic',
        path: '/report/cosmetic',
        title: 'Mỹ phẩm',
        icon: <UserSwitchOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'kqkd',
    path: '',
    title: 'BC KQKD',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/kqkd/summary',
        path: '/kqkd/summary',
        title: 'Tóm tắt Báo cáo KQKD V3',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'operation',
    path: '',
    title: 'BC Vận hành',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/operation/report',
        path: '/operation/report',
        title: 'BC Sale - Vận hành V3',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'booking',
    path: '',
    title: 'Booking',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/booking/list',
        path: '/booking/list',
        title: 'Danh sách',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'checkin',
    path: '',
    title: 'Chấm công',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/checkin/daily',
        path: '/checkin/list',
        title: 'Checkin - Hàng ngày',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/checkin/report',
        path: '/checkin/report',
        title: 'Checkin - Báo cáo',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/checkin/cv-staff',
        path: '/checkin/cv-staff',
        title: 'Thống kê hồ sơ nhân sự',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'quality',
    path: '',
    title: 'Chất lượng',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/quality/check-scsc',
        path: '/quality/check-scsc',
        title: 'Check SCSC',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/quality/view-scsc',
        path: '/quality/view-scsc',
        title: 'Xem SCSC',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/quality/kcs',
        path: '/quality/kcs',
        title: 'KCS/Stylist level C',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/quality/satisfied',
        path: '/quality/satisfied',
        title: 'Điểm hài lòng 2018',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'service',
    path: '',
    title: 'Dịch vụ',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/service/config',
        path: '/service/config',
        title: 'Cấu hình dịch vụ - Salon',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/service/list',
        path: '/service/list',
        title: 'Danh sách dịch vụ',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'supervise',
    path: '',
    title: 'Giám sát',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/supervise/tkgs',
        path: '/supervise/tkgs',
        title: 'TKGS',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/supervise/report-error',
        path: '/supervise/report-error',
        title: 'Báo cáo lỗi 2019',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/supervise/add-error',
        path: '/supervise/add-error',
        title: 'Nhập lỗi GS 2019',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'bill',
    path: '',
    title: 'Hóa đơn',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/bill/back',
        path: '/bill/back',
        title: 'Danh sách hàng trả lại',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'employee',
    path: '',
    title: 'Nhân sự',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/employee/skill',
        path: '/employee/skill',
        title: 'Bậc kỹ năng',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/employee/rule',
        path: '/employee/rule',
        title: 'Quy trình/ Quy định',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'salon',
    path: '',
    title: 'Salon',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/salon/list',
        path: '/salon/list',
        title: 'Danh sách Salon',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/salon/service',
        path: '/salon/service',
        title: 'Dịch vụ Salon',
        icon: <MenuOutlined />
      }
    ]
  },
  {
    isParent: true,
    key: 'product',
    path: '',
    title: 'Sản phầm',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/product/list',
        path: '/product/list',
        title: 'Danh sách Sản phẩm',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/product/config',
        path: '/product/config',
        title: 'Cấu hình sản phẩm - salon',
        icon: <MenuOutlined />
      }
      // {
      //   isParent: true,
      //   key: '/product/config-all',
      //   path: '/product/config-all',
      //   title: 'Cấu hình sản phẩm - all salon',
      //   icon: <MenuOutlined />
      // }
    ]
  },
  {
    isParent: true,
    key: 'shinemember',
    path: '',
    title: 'Đổi ShineMember',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/shinemember/approve',
        path: '/shinemember/approve',
        title: 'Duyệt yêu cầu',
        icon: <MenuOutlined />
      }
    ]
  }
]

const MENU_APPROVE = [
  {
    isParent: true,
    key: 'stylist',
    path: '',
    title: 'Duyệt thông tin Stylist',
    icon: <UserSwitchOutlined />,
    nested: [
      {
        isParent: true,
        key: '/stylist/avatar',
        path: '/stylist/avatar',
        title: 'Duyệt avatar booking',
        icon: <MenuOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/stylist/hair',
        path: '/stylist/hair',
        title: 'Duyệt kiểu tóc sở trường',
        icon: <UserSwitchOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/stylist/introduction',
        path: '/stylist/introduction',
        title: 'Duyệt giới thiệu bản thân',
        icon: <UserSwitchOutlined />,
        authorization: true
      }
    ]
  }
]

const MENU_NOTI = [
  {
    key: 'noti',
    path: '',
    title: 'Push notification',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/pushNotification/NotificationSalon',
        path: '/pushNotification/NotificationSalon',
        title: 'Gửi thông báo (Salon - bộ phận)',
        icon: <MenuOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/pushNotification/NotificationFile',
        path: '/pushNotification/NotificationFile',
        title: 'Gửi thông báo (File)',
        icon: <MenuOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/pushNotification',
        path: '/pushNotification',
        title: 'Quản lý noti',
        icon: <MenuOutlined />,
        authorization: true
      }
    ]
  }
]

const PreMenu: MenuProps[] = [
  {
    isParent: true,
    key: 'staff',
    path: '',
    title: 'Quản lý nhân viên',
    icon: <TeamOutlined />,
    nested: [
      {
        isParent: true,
        key: '/staff/list',
        path: '/staff/list',
        title: 'Danh sách nhân viên',
        icon: <MenuOutlined />,
        authorization: true
      },
      {
        isParent: true,
        key: '/change',
        path: '/change',
        title: 'Biến động nhân sự',
        icon: <UserSwitchOutlined />,
        authorization: true
      },

      {
        isParent: true,
        key: '/staff/add',
        path: '/staff/add',
        title: 'thêm mới nhân sự',
        icon: <MenuOutlined />,
        hidden: true
      },
      {
        isParent: true,
        key: '/staff/detail',
        path: '/staff/detail',
        title: 'sửa thông tin nhân viên',
        icon: <UserSwitchOutlined />,
        hidden: true
      }
    ]
  }
]

const MENU_UPLOAD_STYLE = [
  {
    isParent: false,
    key: 'upload-hair-styles',
    path: '/upload-hair-styles',
    title: 'Kiểu tóc mẫu',
    icon: <ScissorOutlined />,
    nested: []
  }
]

const MENU_BI = [
  {
    isParent: true,
    key: 'bi',
    path: 'bi',
    title: 'BI',
    icon: <HeatMapOutlined />,
    nested: [
      {
        isParent: true,
        key: '/bi/export',
        path: '/bi/export',
        title: 'Export data',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/bi/create',
        path: '/bi/create',
        title: 'Tạo mới Script',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/bi/update',
        path: '/bi/update',
        title: 'Chỉnh sửa Script',
        icon: <MenuOutlined />
      }
    ]
  }
]

export const MENUS = ['staging', 'production'].includes(process.env.REACT_APP_ENV)
  ? [...PreMenu, ...MENU_APPROVE, ...MENU_BI, ...MENU_UPLOAD_STYLE]
  : [...PreMenu, ...MENU_APPROVE, ...MENU_NOTI, ...MENU_BI, ...MENU_WEBFORM, ...MENU_UPLOAD_STYLE]
