import React from 'react'
import { useRoutes } from 'react-router-dom'

import { ListService } from './service'
import { ListPerfomanceV2 } from './perfomance-v2'
import { ListCosmetic } from './cosmetic'
import { ListTopup } from './topup'
function ReportRouterTopup() {
  const routes = useRoutes([
    {
      path: '/service',
      element: <ListService />
    },
    {
      path: '/cosmetic',
      element: <ListCosmetic />
    },
    {
      path: '/perfomance-v2',
      element: <ListPerfomanceV2 />
    },
    {
      path: '/topup',
      element: <ListTopup />
    }
  ])

  return <>{routes}</>
}

export default ReportRouterTopup
