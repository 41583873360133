import Table, { ColumnsType, TableProps } from 'antd/es/table'
import './index.scss'
import dayjs from 'dayjs'
import { Button, Tag } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import ViewNotification from './Component/viewNotification'
import DeleteNotification from './Component/deleteNotification'
import { useNavigate } from 'react-router-dom'

interface RecordType {
  id: number
  name: string
}
interface Noti {
  value: string
  label: string
}
interface DataType {
  id: number
  name: Noti[]
  date: string
  createdBy: string
  listSalon: RecordType[]
  listPosition: RecordType[]
  fileData: string
  status: number
  type: number
  target: number
  totalData: number
  totalSeen: number
}
// status là trạng thái đã bắn/đã lên lịch
// type loại đẩy salon/file
const ListNoti: DataType[] = [
  {
    id: 1,
    name: [
      {
        value: '1',
        label: 'Ngày hội cha con'
      }
    ],
    date: '2023-07-20 14:30:00',
    listSalon: [
      {
        id: 1,
        name: 'Salon 1'
      },
      {
        id: 2,
        name: 'Salon 2'
      }
    ],

    listPosition: [
      {
        id: 1,
        name: 'Stylist'
      },
      {
        id: 2,
        name: 'Skinner'
      }
    ],

    createdBy: 'Thinh',
    fileData: '',
    status: 1,
    type: 1,
    target: 0,
    totalData: 1000,
    totalSeen: 200
  },
  {
    id: 2,
    name: [
      {
        value: '2',
        label: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ'
      }
    ],
    date: '2023-08-07 12:08:23',
    listSalon: [
      {
        id: 4,
        name: 'Salon 4'
      },
      {
        id: 5,
        name: 'Salon 5'
      }
    ],

    listPosition: [
      {
        id: 4,
        name: 'Bao ve'
      },
      {
        id: 5,
        name: 'Suporter'
      }
    ],

    createdBy: 'Thinh',
    fileData: '',
    status: 2,
    type: 1,
    target: 0,
    totalData: 1000,
    totalSeen: 200
  },
  {
    id: 3,
    name: [
      {
        value: '3',
        label: 'Mở app xem ngay ưu đãi'
      }
    ],
    date: '2023-08-02 20:08:23',
    listSalon: [],
    listPosition: [],
    createdBy: 'Thinh',
    fileData: 'filedataB.scv',
    status: 1,
    type: 2,
    target: 1,
    totalData: 1000,
    totalSeen: 200
  },
  {
    id: 4,
    name: [
      {
        value: '4',
        label: 'Mở app xem ngay ưu đãi pro'
      }
    ],
    date: '2023-08-06 20:08:23',
    listSalon: [],
    listPosition: [],
    createdBy: 'Thinh',
    fileData: 'filedataACBVS.scv',
    status: 2,
    type: 2,
    target: 2,
    totalData: 1000,
    totalSeen: 200
  }
]

export default function ListNotification() {
  const navigate = useNavigate()

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => {
        return <span> {id} </span>
      }
    },
    {
      title: 'Tên thông báo',
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return <span>{name[0].label}</span>
      }
    },
    {
      title: 'Ngày bắn/ Ngày đã lên lịch bắn',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a, b) => {
          const dateA = new Date(a.date).getTime()
          const dateB = new Date(b.date).getTime()
          return dateA - dateB
        },
        multiple: 1
      },
      render: (date) => {
        return <span> {date && dayjs(date).format('YYYY-MM-DD HH:mm:ss')} </span>
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a, b) => a.status - b.status,
        multiple: 1
      },
      render: (status) => {
        let color = status === 1 ? 'default' : 'default'
        if (status === 1) {
          color = 'blue'
        }
        return (
          <span>
            <Tag
              color={color}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '10px'
              }}
            >
              <span style={{ color: status === 1 ? '#1890FF' : '' }}>{status === 1 ? 'Đã bắn' : 'Đã lên lịch'}</span>
            </Tag>
          </span>
        )
      }
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy) => {
        return <span> {createdBy} </span>
      }
    },
    {
      title: 'Hành động',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => (
        <div style={{ display: 'flex' }}>
          <ViewNotification data={record} />
          {record?.status === 2 ? (
            <div style={{ display: 'flex' }}>
              {record?.type === 1 ? (
                <Button
                  style={{ display: 'flex', alignItems: 'center', borderColor: '#1890FF', marginRight: 10 }}
                  icon={<EditTwoTone />}
                  shape='round'
                  onClick={() => {
                    navigate(`/pushNotification/UpdateNotificationSalon/${record.id}`, { state: record })
                  }}
                >
                  <span style={{ color: '#1890FF' }}>Sửa</span>
                </Button>
              ) : (
                <Button
                  style={{ display: 'flex', alignItems: 'center', borderColor: '#1890FF', marginRight: 10 }}
                  icon={<EditTwoTone />}
                  shape='round'
                  onClick={() => {
                    navigate(`/pushNotification/UpdateNotificationFile/${record.id}`, { state: record })
                  }}
                >
                  <span style={{ color: '#1890FF' }}>Sửa</span>
                </Button>
              )}
              <DeleteNotification data={record} />
            </div>
          ) : null}
        </div>
      )
    }
  ]

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
  }
  return (
    <div className='cover w-[calc(100vw-300px)]' style={{ padding: 20 }}>
      <h2 className='title'>Quản lý push noti</h2>
      <div className='content'>
        <Table columns={columns} dataSource={ListNoti} onChange={onChange} />
      </div>
    </div>
  )
}
