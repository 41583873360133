import { Button, DatePicker, Descriptions, Form, Image, Input, Select, Space, Typography } from 'antd'
import React, { Fragment } from 'react'
import SCSC1 from 'images/img_scsc_1.png'
import SCSC2 from 'images/img_scsc_2.png'
import SCSC3 from 'images/img_scsc_3.png'
import SCSC4 from 'images/img_scsc_4.png'
import SCSC5 from 'images/img_scsc_5.png'
import SCSC6 from 'images/img_scsc_6.png'
import SCSC7 from 'images/img_scsc_7.png'
import SCSC8 from 'images/img_scsc_8.png'
import SCSC9 from 'images/img_scsc_9.png'
import SCSC10 from 'images/img_scsc_10.png'
import SCSC11 from 'images/img_scsc_11.png'
import SCSC12 from 'images/img_scsc_12.png'
import SCSC13 from 'images/img_scsc_13.png'
import SCSC14 from 'images/img_scsc_14.png'
import SCSC15 from 'images/img_scsc_15.png'
import SCSC16 from 'images/img_scsc_16.png'

const listSelectSalon = [
  {
    value: '2',
    label: '346KT'
  },
  {
    value: '3',
    label: '82TDN'
  }
]

const listSelectStaff = [
  {
    value: '2000',
    label: 'Stylist 2000'
  },
  {
    value: '2001',
    label: 'Stylist 2001'
  },
  {
    value: '2002',
    label: 'Stylist 2002'
  },
  {
    value: '2004',
    label: 'Stylist 2004'
  },
  {
    value: '2005',
    label: 'Stylist 2005'
  },
  {
    value: '2006',
    label: 'Stylist 2006'
  }
]

const listSelectFilter = [
  {
    value: '1',
    label: 'Lỗi thiếu ảnh, ảnh mờ lệch, lỗi 1 trong 4 tiêu chí (SCSC)'
  },
  {
    value: '4',
    label: 'Lỗi thiếu ảnh, ảnh mờ lệch (uốn)'
  },
  {
    value: '2',
    label: 'Bill cao bất thường: >= lv.1 nhưng điểm scsc = 5'
  },
  {
    value: '3',
    label: 'Bill của level >= 2 lấy ngẫu nhiên 2 bill/stylist'
  }
]

const listSelectLevel = [
  {
    value: '1',
    label: 'Level 1'
  },
  {
    value: '2',
    label: 'Level 2'
  },
  {
    value: '6',
    label: 'Level 3'
  },
  {
    value: '7',
    label: 'Level 4'
  },
  {
    value: '3',
    label: 'Level C'
  },
  {
    value: '9',
    label: 'Level D'
  },
  {
    value: '16',
    label: 'Level E'
  }
]

const listSelectHairStyle = [
  {
    value: '2',
    label: 'Side-swept'
  },
  {
    value: '3',
    label: 'Quiff'
  },
  {
    value: '5',
    label: 'Side Part'
  },
  {
    value: '7',
    label: 'Sport'
  },
  {
    value: '8',
    label: 'Layer'
  },
  {
    value: '9',
    label: 'Mohican'
  },
  {
    value: '10',
    label: 'Kiểu tóc khác'
  },
  {
    value: '15',
    label: 'Under'
  },
  {
    value: '16',
    label: 'Classic'
  },
  {
    value: '17',
    label: 'MIDDLE - PART'
  },
  {
    value: '18',
    label: 'MULLET'
  },
  {
    value: '19',
    label: 'SHORT QUIFF'
  },
  {
    value: '20',
    label: 'POMPADOUR'
  },
  {
    value: '21',
    label: 'MAN BUM'
  },
  {
    value: '22',
    label: 'SLICK BACK'
  }
]

const listSCSC = [
  { id: 1, img: SCSC1, imgActive: SCSC2, title: '1.SHAPE (HÌNH KHỐI)' },
  { id: 2, img: SCSC3, imgActive: SCSC4, title: '2.CONNECTIVE (LIÊN KẾT)' },
  { id: 3, img: SCSC5, imgActive: SCSC6, title: '3.SHARPNESS (SẮC NÉT)' },
  { id: 4, img: SCSC7, imgActive: SCSC8, title: '4.COMPLETION (HOÀN THIỆN)' },
  { id: 4, img: SCSC16, imgActive: SCSC9, title: '5.CREATIVE (SÁNG TẠO)' }
]

const listKCS = [
  { id: 1, img: SCSC10, imgActive: SCSC11, title: '1.NGỌN TÓC' },
  { id: 2, img: SCSC12, imgActive: SCSC13, title: '2.CHÂN TÓC' },
  { id: 3, img: SCSC14, imgActive: SCSC15, title: '3.SÓNG TÓC' }
]

const listImage = [
  { id: 1, link: 'https://storage.30shine.com/customer/history/3420676/8689b8e7-9a1c-4539-b252-69c51928db22' },
  { id: 2, link: 'https://storage.30shine.com/customer/history/3420676/8689b8e7-9a1c-4539-b252-69c51928db22' },
  { id: 3, link: 'https://storage.30shine.com/customer/history/3420676/8689b8e7-9a1c-4539-b252-69c51928db22' }
]

const imageFinish = 'https://storage.30shine.com/customer/history/3420676/8689b8e7-9a1c-4539-b252-69c51928db22'

const CheckSCSC = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className='search-wrapper px-5 md:w-[calc(100vw-300px)]'>
      <Typography.Title level={4} className='wrapper mt-4'>
        XEM ẢNH LỊCH SỬ
      </Typography.Title>
      <div className='wrapper' style={{ paddingBottom: 10 }}>
        <Form
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          layout='inline'
        >
          <Form.Item name='date' rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}>
            <DatePicker placeholder='Chọn ngày' />
          </Form.Item>
          <Form.Item name='salon' rules={[{ required: true, message: 'Vui lòng chọn salon' }]}>
            <Select
              showSearch
              placeholder='Chọn salon'
              optionFilterProp='children'
              style={{ width: 200 }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={listSelectSalon}
            />
          </Form.Item>
          <Form.Item name='staff'>
            <Select
              showSearch
              placeholder='Chọn nhân viên'
              optionFilterProp='children'
              style={{ width: 200 }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={listSelectStaff}
            />
          </Form.Item>
          <Form.Item name='filter'>
            <Select
              showSearch
              placeholder='Lọc theo nhóm'
              optionFilterProp='children'
              style={{ width: 300 }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={listSelectFilter}
            />
          </Form.Item>
          <Form.Item name='level'>
            <Select
              showSearch
              placeholder='Lọc theo bậc'
              optionFilterProp='children'
              style={{ width: 150 }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={listSelectLevel}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Tìm
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className='wrapper mt-[14px]'>
        <Descriptions>
          <Descriptions.Item label='KH'>Tên KH</Descriptions.Item>
          <Descriptions.Item label='SĐT'>*****1234</Descriptions.Item>
          <Descriptions.Item label='Dịch vụ'>[DV] ShineCombo 10 bước Ultra Care 2023 120k. </Descriptions.Item>
          <Descriptions.Item label='Kiểu tóc stylist chọn'>Side-swept</Descriptions.Item>
          <Descriptions.Item label='Stylist'>5374 - Khổng Hữu Báo</Descriptions.Item>
        </Descriptions>
        <Select
          showSearch
          placeholder='Chọn kiểu tóc'
          optionFilterProp='children'
          style={{ width: 200 }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={listSelectHairStyle}
        />
      </div>

      <div className='wrapper mt-[14px]'>
        <div className='mb-4 bg-black px-6 py-2 text-white text-xl font-semibold w-max'>SCSC (score: 0/5)</div>
        <div className='mb-4 bg-black px-6 py-2 text-white text-xl font-semibold w-max hover:underline hover:text-yellow-400 cursor-pointer'>
          Ảnh THIẾU/MỜ/LỆCH/SAI THIẾT KẾ
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
          <div className='col-span-1 order-2 lg:order-1'>
            <div className='grid grid-cols-2 gap-5'>
              {listSCSC.map((item) => (
                <Fragment key={item.id}>
                  <div className='col-span-1 text-base lg:text-lg font-medium'>{item.title}</div>
                  <div className='col-span-1 flex gap-6'>
                    <div className='flex-1'>
                      <img src={item.img} alt='' className=' cursor-pointer' />
                    </div>
                    <div className='flex-1'>
                      <img src={item.imgActive} alt='' className='cursor-pointer' />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className='col-span-1 order-1 lg:order-2'>
            <div className='grid grid-cols-3 gap-5'>
              {listImage.map((item) => (
                <div key={item.id}>
                  <Image src={item.link} />
                </div>
              ))}
              <Image src={imageFinish} />
            </div>
          </div>
        </div>
      </div>

      <div className='wrapper mt-[14px]'>
        <div className='mb-4 bg-black px-6 py-2 text-white text-xl font-semibold w-max'>KCS Uốn (score: 0/3)</div>
        <div className='mb-4 bg-black px-6 py-2 text-white text-xl font-semibold w-max hover:underline hover:text-yellow-400 cursor-pointer'>
          Ảnh thiếu MỜ/LỆCH
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-10'>
          <div className='col-span-1 order-2 lg:order-1'>
            <div className='grid grid-cols-2 gap-5'>
              {listKCS.map((item) => (
                <Fragment key={item.id}>
                  <div className='col-span-1 text-base lg:text-lg font-medium'>{item.title}</div>
                  <div className='col-span-1 flex gap-6'>
                    <div className='flex-1'>
                      <img src={item.img} alt='' className=' cursor-pointer' />
                    </div>
                    <div className='flex-1'>
                      <img src={item.imgActive} alt='' className='cursor-pointer' />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className='col-span-1 order-1 lg:order-2'>
            <div className='grid grid-cols-3 gap-5'>
              {listImage.map((item) => (
                <div key={item.id}>
                  <Image src={item.link} />
                </div>
              ))}
              <Image src={imageFinish} />
            </div>
          </div>
        </div>
      </div>
      <div className='wrapper mt-[14px]'>
        <Input.TextArea placeholder='Ghi chú' allowClear />
      </div>
      <div className='wrapper mt-[14px] text-center'>
        <div>
          Đang xem hoá đơn <b>0/0</b>
        </div>
        <div>
          Hoá đơn chưa check <b>0</b>
        </div>
        <Space className='pt-3'>
          <Button className='min-w-[100px]' type='primary'>
            Trước
          </Button>
          <Button className='min-w-[100px]'>Sau</Button>
        </Space>
      </div>
    </div>
  )
}

export default CheckSCSC
