import { ListBill } from './list'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerBill: Router = [
  {
    path: '/bill/back',
    element: <ListBill />
  }
]
