import React from 'react'
import { useRoutes } from 'react-router-dom'

import { ProductConfiguration } from './ProductConfiguration'
import { AddProductsPage } from './AddProducts'
import { DetailProductsPage } from './DetailProducts'

function ProductsRouter() {
  const routes = useRoutes([
    {
      path: '/config',
      element: <ProductConfiguration />
    },
    {
      path: '/config/add',
      element: <AddProductsPage />
    },
    {
      path: '/detail/*',
      element: <DetailProductsPage />
    }
  ])

  return <>{routes}</>
}

export default ProductsRouter
