import React, { useState, useEffect } from 'react'
import TitleBox from './components/title'
import HairStyles from './components/HairStyles'
import { Spin } from 'antd'
import ApiEndpoint from './api'

export const UploadHairStyles = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState([])
  const handleGetData = async () => {
    setLoading(true)
    try {
      const response: any = await ApiEndpoint.getHairStyles()
      const { statusCode, data, message } = response
      if (statusCode === 200) {
        setData(data.hairStyles)
        setLoading(false)
      } else {
        setLoading(false)
        console.log(message, 'message')
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    handleGetData()
  }, [])

  return (
    <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
      <TitleBox setLoading={setLoading} data={data} handleGetData={handleGetData} />
      <div>
        {data.map((data) => (
          <>
            <HairStyles setLoading={setLoading} data={data} handleGetData={handleGetData} />
          </>
        ))}
      </div>
    </Spin>
  )
}
