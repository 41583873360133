import { useEffect, useState, useContext } from 'react'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { getASMS, getSalons, getStaffSkillLevel, getStaffType } from './api'
import { getListStaffChange } from 'pages/staff/api'
export const SatisfiedPage = () => {
  const { notification } = useContext(AppContext)
  const [reload, setReload] = useState(false)
  const [options, setOptions] = useState({ salon: [], staffType: [], asm: [], skill: [] })
  const [salonOptions, setSalonOptions] = useState([])
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'ID',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'staffName',
      key: 'staffName'
    },
    {
      title: 'Tên Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level'
    },
    {
      title: '1 Sao',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: '2 Sao',
      dataIndex: 'salonChangeName',
      key: 'salonChangeName'
    },
    {
      title: '3 Sao',
      dataIndex: 'statusName',
      key: 'statusName'
    },
    {
      title: '4 Sao',
      dataIndex: 'createdDate',
      key: 'createdDate'
    },
    {
      title: '5 Sao',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate'
    },
    {
      title: 'Tổng bill',
      dataIndex: 'totalSeniorityLevelUp',
      key: 'totalSeniorityLevelUp'
    },
    {
      title: 'Tổng điểm đánh giá',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'createdDate',
      type: TypeSearch.date
    },
    {
      label: 'ASM',
      param: 'asm',
      type: TypeSearch.select,
      options: options.asm,
      placeholder: 'Chọn vùng ASM',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Chọn salon',
      param: 'salonId',
      type: TypeSearch.select,
      options: salonOptions,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Bộ phận',
      param: 'type',
      type: TypeSearch.select,
      options: options.staffType,
      placeholder: 'trạng thái',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Level',
      param: 'level',
      type: TypeSearch.select,
      options: options.skill,
      placeholder: 'trạng thái',
      fieldNames: { label: 'name', value: 'id' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (Object.keys(changedValues)[0] === 'asm') {
      onReset(['salonId'])
      if (changedValues?.asm) {
        const listIdSalon = options.asm.find((i) => i.id === changedValues.asm).salonIds
        setSalonOptions(options.salon.filter((i) => listIdSalon.includes(i.id)))
      } else {
        setSalonOptions(options.salon)
      }
    }
  }

  const handleFetchData = async () => {
    try {
      const [type, asm, salon, skill]: any = await Promise.all([
        getStaffType(1),
        getASMS(),
        getSalons(),
        getStaffSkillLevel()
      ])
      if ([type.statusCode, asm.statusCode, salon.statusCode, skill.statusCode].every((code) => code === 0)) {
        setOptions({
          salon: [...salon.data],
          asm: [...asm.data],
          staffType: [...type.data],
          skill: [...skill.data]
        })
        setSalonOptions([...salon.data])
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/quality/satisfied'
        title='Điểm chất lượng theo bill'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
