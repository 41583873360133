/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import '../index.scss'
import { Button, DatePicker, Form, Input, Radio, Select, Spin, message, Upload } from 'antd'
import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const { TextArea } = Input
export const AddRule: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [isAddedType, setIsAddedType] = useState(0)
  const [isSalon, setIsSalon] = useState(true)
  const [isGroup, setIsGroup] = useState()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const onValuesChange = async (changedValues: any) => {
    // if (changedValues?.provinceId !== undefined) {
    //   const res: any = await getDistrict(changedValues.provinceId)
  }
  const ListDepartment = [
    { label: 'Stylist', value: 1 },
    { label: 'Skiner', value: 2 },
    { label: 'Bảo vệ', value: 3 },
    { label: 'Chuyên viên tư vấn', value: 3 }
  ]
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-5'>THÊM MỚI NỘI DUNG QUY TRÌNH / QUY ĐỊNH</h3>
        <div className='mt-12  custom-form-details-form'>
          <div className='mt-20'>
            <Form.Item
              label='Tiêu đề:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input placeholder='Nhập tiêu đề Nội dung quy trình / quy định' />
            </Form.Item>

            <Form.Item
              label='Khối:'
              name='groupId'
              rules={[{ required: true, message: 'Không được để trống' }]}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Select placeholder='Chọn khối nhân sự' options={ListDepartment} onChange={handleChange} />
            </Form.Item>
            <Form.Item label='Mô Tả:' name='idNo' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <TextArea
                maxLength={600}
                placeholder='Nhập mô tả Nội dung quy trình / quy định'
                style={{ height: 120 }}
              />
            </Form.Item>
            <Form.Item label='Thêm ảnh' name='idNo' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} className='mt-8'>
              <div className='detail-block-uploadimage'>
                <Upload
                  name='avatar'
                  listType='picture-card'
                  className='avatar-uploader'
                  showUploadList={false}
                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                  beforeUpload={beforeUpload}
                  onChange={handleChangeImage}
                >
                  {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                <div className='detail-block-uploadimage-text'>
                  <Form.Item
                    label={<span className='detail-block-uploadimage-text-label'>Tiêu đề</span>}
                    name='userName'
                  >
                    <Input placeholder='Nhập tiêu đề Nội dung quy trình / quy định' />
                  </Form.Item>
                  <Form.Item label={<span className='detail-block-uploadimage-text-label'>Mô tả</span>} name='idNo'>
                    <TextArea
                      maxLength={600}
                      placeholder='Nhập mô tả Nội dung quy trình / quy định'
                      style={{ height: 120 }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
        <Form.Item label='' name='idNo'>
          <Button
            className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
