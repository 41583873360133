import React from 'react'
import { Spin } from 'antd'

export default function Loading() {
  return (
    <div
      style={{
        minHeight: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end'
      }}
    >
      <Spin />
    </div>
  )
}
