import TKGS from './tkgs'
import { ReportError } from './reportError'
import { EnterGSError2019 } from './EnterGSError2019'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerSupervise: Router = [
  {
    path: '/supervise/tkgs',
    element: <TKGS />
  },
  {
    path: '/supervise/report-error',
    element: <ReportError />
  },
  {
    path: '/add-error',
    element: <EnterGSError2019 />
  }
]
