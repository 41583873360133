import React, { useState, Dispatch, SetStateAction } from 'react'
import ModalVariantEdit from './ModalVariantEdit'
import './style.scss'

interface VariantDataProps {
  setLoading: Dispatch<SetStateAction<boolean>>
  handleGetData: any
  dataHairStyle: any
  createdBy: string
  createdDate: string
  id: string
  isDelete: boolean
  name: string
  urlImage: Array<string>
}
const VariantData: React.FC<VariantDataProps> = (data) => {
  const { setLoading, handleGetData, dataHairStyle, createdBy, createdDate, id, isDelete, name, urlImage } = data
  const [isModalVariantOpen, setIsModalVariantOpen] = useState<boolean>(false)
  // console.log(urlImage, 'urlImage')
  return (
    <>
      <div className='card-wrap'>
        <div className='card' onClick={() => setIsModalVariantOpen(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setIsModalVariantOpen(true)
              }
            }}
            role='button'
            tabIndex={0}>
          <div className='card-img' style={{ backgroundImage: `url(${urlImage[0]})` }}></div>
          <div
            className='card-button'
          >
            Sửa
          </div>
        </div>
        <div className='card-title'>{name}</div>
      </div>
      <ModalVariantEdit
        nameHairStye={dataHairStyle.name}
        idHairStye={dataHairStyle.id}
        nameVariant={name}
        urlImage={urlImage}
        idVariant={id}
        setIsModalVariantOpen={setIsModalVariantOpen}
        isModalVariantOpen={isModalVariantOpen}
        setLoading={setLoading}
        handleGetData={handleGetData}
      />
    </>
  )
}

export default VariantData
