import { ArrowLeftOutlined } from '@ant-design/icons'
import './../style.scss'
import ReactQuill from 'react-quill'
import { Button, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { postNewUpdateRequest, getUploadedInfo, getUploadedStatus } from '../../api'
import { useAppSelector } from 'redux/store'
import back from 'images/ic_arrow_left_2x.jpeg';

export const modules = {
  toolbar: [
    [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['bold', 'italic', 'underline'],
    ['strike', 'clean']
  ]
}
declare global {
  interface Window {
    ReactNativeWebView: any
  }
}

export const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'align'
]

export default function StylistSelectIntroduction() {
  const [text, setText] = useState('')
  const [charCount, setCharCount] = useState(0)
  const [buttonText, setButtonText] = useState('');
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [status, setStatus] = useState(0)
  const [loading, setLoading] = useState(false)
  const editorRef: any = useRef()
  const user = useAppSelector((state) => state.user)

  const maxCharCount = 250

  const handleUpload = async () => {
    if (loading) return
    try {
      setLoading(true)
      const response = await postNewUpdateRequest({
        data: [],
        description: text,
        type: 3
      })
      if (response) {
        if (response.data?.statusId) {
          setStatus(response.data.statusId)
          setUploadDisabled(true)
        }
        notification.success({
          message: 'Thông báo',
          description: 'Upload thành công, chờ duyệt.'
        })
      }
    } catch (error) {
      console.log('Lỗi không gửi được dữ liệu')
      notification.error({
        message: 'Thông báo',
        description: 'Có lỗi xảy ra, vui lòng thử lại sau'
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    switch (status) {
      case 1:
        setButtonText("UPLOAD - CHỜ DUYỆT");
        break;
      case 2:
        setButtonText("KHÔNG DUYỆT");
        break;
      case 3:
        setButtonText("ĐÃ DUYỆT");
        break;
      default:
        setButtonText("UPLOAD - CHỜ DUYỆT");
        break;
    }
  }, [status]);
  const getPlainTextFromRichText = (richTextValue) => {
    const parser = new DOMParser()
    const parsedHtml = parser.parseFromString(richTextValue, 'text/html')
    const plainText = parsedHtml.body.textContent || ''
    return plainText
  }

  const handleChangeText = (value) => {
    const plainText = getPlainTextFromRichText(value);
    const charCount = plainText.replace(/\s+/g, '').length;
    setText(value);
    setCharCount(charCount);
  }

  useEffect(() => {
    const isDisabled = charCount === 0 || charCount > maxCharCount;
    setUploadDisabled(isDisabled);

    if (!isDisabled) {
      setStatus(1);
    }

    handleChangeText(text);
  }, [text]);

  const getInfo = async () => {
    try {
      const response = await getUploadedInfo(3)
      if (response.data?.data) {
        setText(response.data.data)
        const status = response.data?.statusId || 0
        setStatus(status)
        if (status > 0) setUploadDisabled(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnClickBack = () => {
    const message = {
      type: 'UploadInfoStylist',
      message: 'press_back'
    }
    if (window?.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message))
    }
  }

  useEffect(() => {
    if (user.userInfo.listSalon.length) getInfo()
  }, [user])

  useEffect(() => {
    if (editorRef && editorRef.current) {
      editorRef.current?.focus()
    }
  }, [editorRef])

  return (
    <div className='page-cover relative bg-white min-h-screen'>
      <div className='flex items-center border-b border-[#EAEAEA] justify-between px-2'>
        <div className='title-hair' aria-hidden='true'>
          {/* eslint-disable-next-line */}
          <img alt="img" className='icon' style={{ margin: "0", width: "20px" }} src={back} onClick={handleOnClickBack} onKeyDown={handleOnClickBack} />
          <span className='text pl-1'>Nhập giới thiệu bản thân</span>
        </div>
        {status === 1 ?
          <Button
            onClick={handleUpload}
            className='upload-button'
            type={uploadDisabled ? 'default' : 'primary'}
            disabled={uploadDisabled}
            size='small'
            loading={loading}
          >
            {buttonText}
          </Button>
          :
          <Button
            onClick={handleUpload}
            className='upload-button'
            type={'default'}
            disabled={true}
            size='small'
            loading={loading}
          >
            {buttonText}
          </Button>
        }

      </div>

      <div className='text-box '>
        <ReactQuill
          theme='snow'
          value={text}
          modules={modules}
          onChange={handleChangeText}
          formats={formats}
          placeholder={'Viết vài dòng giới thiệu về bản thân...'}
          ref={editorRef}
        />
        <div className='counter' >
          <span>{charCount > maxCharCount && <div style={{ color: 'red' }}>Số ký tự vượt quá {maxCharCount}</div>}</span>

          <span style={{ marginLeft: "10px" }}>{charCount}/{maxCharCount}</span>
        </div>

      </div>
    </div>
  )
}
