import '../../../index.css'
import { EditOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Form, Button, Row, Table, Pagination, Spin, Modal } from 'antd'
import { useEffect, useMemo, useState, useContext } from 'react'
import { convertParamsAxios, convertQueryStringToObject, getSalons } from 'pages/staff/api'
import { setSearchParamsIntroduction } from '../stylistSlice'
import FormSearchChange from './FormSearch'
import { SpinIndicator } from 'antd/es/spin'
import { AppContext } from 'layouts/DashboardLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { ParamsSearchType } from 'pages/staff/interface'
export const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { getListStylistApproval, updateListStylistApproval, updateStylistIntroduction } from '../api'
import { STATUS_TYPE } from '../avatar/page'
import { fetchDataByApiKey } from 'pages/staff/hook/fetchViewStaff'

export const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }]
  ]
}

export const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'align']

export default function StylistIntroductionComponent() {
  const { notification } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { searchParamsIntroduction }: any = useAppSelector((state) => state.stylist)
  const { userInfo }: any = useAppSelector((state) => state.user)
  const [form] = Form.useForm()

  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [open, setOpen] = useState({ type: null, status: false })
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [richText, setRichText] = useState('')
  const [openRichText, setOpenRichText] = useState(false)
  const [currentStaff, setCurrentStaff] = useState<any>()

  const columns = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: 'Salon',
        dataIndex: 'salon',
        key: 'salon'
      },
      {
        title: 'Mã nhân viên',
        dataIndex: 'staffId',
        key: 'staffId'
      },
      {
        title: 'Họ và tên',
        dataIndex: 'fullName',
        key: 'fullName'
      },
      {
        title: 'Mô tả giới thiệu bản thân',
        key: 'data',
        render: (item) => (
          <div className='flex items-center justify-between gap-2'>
            <ReactQuill theme='bubble' value={item.data[0]} readOnly />
            <EditOutlined
              className={`text-[#4096ff] cursor-pointer ${
                ![3, 2].includes(item.statusId) ? 'activeReactQuill' : 'inActiveReactQuill'
              }`}
              onClick={() => {
                setOpenRichText(true)
                setRichText(item?.data[0])
                setCurrentStaff(item)
              }}
            />
          </div>
        )
      },
      {
        title: 'Trạng thái',
        dataIndex: 'statusId',
        key: 'statusId',
        render: (statusId) => (
          <div className='flex justify-center'>
            {statusId !== 1 &&
              (statusId === 3 ? (
                <p className='text-[#009F10]'>Đã duyệt</p>
              ) : (
                <p className='text-[#ff4d4f]'>Không duyệt</p>
              ))}
          </div>
        )
      },
      {
        title: 'Hành động',
        key: 'action',
        render: (item) => (
          <div className='flex justify-center gap-x-2'>
            {item.statusId === 1 && userInfo.staffType.id === 9 && (
              <>
                <Button
                  disabled={['đã duyệt', 'không duyệt'].includes(STATUS_TYPE[item.statusId])}
                  onClick={() => {
                    setOpen({ type: true, status: true })
                    setCurrentStaff(item)
                  }}
                  className='border-[#009F10] text-[#009F10]'
                  type='default'
                >
                  Duyệt
                </Button>
                <Button
                  disabled={['đã duyệt', 'không duyệt'].includes(STATUS_TYPE[item.statusId])}
                  onClick={() => {
                    setOpen({ type: false, status: true })
                    setCurrentStaff(item)
                  }}
                  danger
                >
                  Không Duyệt
                </Button>
              </>
            )}
          </div>
        )
      }
    ]
  }, [])

  const handleFetchListStaff = async (paramsSearch: ParamsSearchType) => {
    const payload = { ...paramsSearch }
    if (userInfo.staffType.id === 9) payload.SalonId = userInfo.listSalon[0].id
    try {
      setLoading(true)
      const res: any = await getListStylistApproval(payload)
      setLoading(false)
      if (res.statusCode === 0) {
        setTotal(res.data.totalRecords)
        setData(res.data.listData)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
    setLoading(false)
  }
  const prefetchDataStaffList = () => {
    const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
    if (Object.keys(paramsSearchResult).length > 0) {
      dispatch(setSearchParamsIntroduction({ ...paramsSearchResult, ...searchParamsIntroduction }))
      handleFetchListStaff({ ...paramsSearchResult, ...searchParamsIntroduction })
    } else {
      setData([])
    }
  }

  const onFinish = () => {
    navigate(`/stylist/introduction?${convertParamsAxios({ ...searchParamsIntroduction, Type: 3, PageIndex: 1 })}`)
  }
  const handleApprove = async () => {
    try {
      setConfirmLoading(true)
      const res: any = await updateListStylistApproval(currentStaff.id, open.type ? 3 : 2)
      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? `${open.type ? 'Duyệt' : 'Không duyệt'} thành công` : res.message
      )
      if (res.statusCode === 0) {
        prefetchDataStaffList()
      }
      setConfirmLoading(false)
      setOpen({ ...open, status: false })
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setConfirmLoading(false)
      setOpen({ ...open, status: false })
    }
  }
  const handleRichText = async () => {
    try {
      setConfirmLoading(true)
      const res: any = await updateStylistIntroduction(currentStaff.id, richText)

      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? 'Duyệt thành công' : res.message
      )
      if (res.statusCode === 0) {
        prefetchDataStaffList()
      }
      setConfirmLoading(false)
      setOpen({ ...open, status: false })
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setConfirmLoading(false)
      setOpen({ ...open, status: false })
    }
    setOpenRichText(false)
  }
  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])
  useEffect(() => {
    if (userInfo.staffType.id === 9)
      dispatch(
        setSearchParamsIntroduction({
          ...searchParamsIntroduction,
          SalonId: userInfo.listSalon.length > 0 ? userInfo.listSalon[0].id : undefined
        })
      )
  }, [userInfo.listSalon])
  const handleFetchData = async () => {
    try {
      await Promise.all([fetchDataByApiKey('salonId', getSalons, dispatch, notification)])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  return (
    <div className='w-full px-5 py-5 search-wrapper'>
      <Form className='pb-10' form={form} onFinish={onFinish}>
        <div className='text-sm flex gap-x-3 text-[#b7b3b3]'>
          <Link className='hover:text-black' to='#'>
            Duyệt thông tin Stylist
          </Link>
          <span>{`>`}</span>
          <span className='text-black'>Duyệt giới thiệu bản thân</span>
        </div>
        <h2 className='text-2xl mb-7'>Duyệt giới thiệu bản thân</h2>
        <FormSearchChange />
        <div className='bg-white mt-8 ' style={{ padding: '20px 20px 100px 20px' }}>
          <Row className='flex  gap-x-3'>
            <Button
              type='default'
              htmlType='submit'
              className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
          </Row>

          <Row style={{ display: 'contents' }} gutter={18}>
            <div className='flex items-center justify-between'>
              <h3>
                {'Tổng số : '}
                <span className='text-[red] font-semibold'>{total}</span>
              </h3>
              <Pagination
                className='float-right pb-8 mt-8'
                current={searchParamsIntroduction.PageIndex}
                pageSize={searchParamsIntroduction.PageSize}
                total={total}
                onChange={(page: number, pageSize: number) => {
                  dispatch(
                    setSearchParamsIntroduction({
                      ...searchParamsIntroduction,
                      PageSize: pageSize,
                      PageIndex: searchParamsIntroduction.PageSize !== pageSize ? 1 : page
                    })
                  )
                  navigate(
                    `/stylist/introduction?${convertParamsAxios({
                      ...searchParamsIntroduction,
                      PageSize: pageSize,
                      PageIndex: searchParamsIntroduction.PageSize !== pageSize ? 1 : page
                    })}`
                  )
                }}
                showSizeChanger
              />
            </div>
            <Table className='min-w-[1045px] mt-2' dataSource={data} columns={columns} pagination={false} bordered />
            <Pagination
              className='float-right pb-8 mt-8'
              current={searchParamsIntroduction.PageIndex}
              pageSize={searchParamsIntroduction.PageSize}
              total={total}
              onChange={(page: number, pageSize: number) => {
                dispatch(
                  setSearchParamsIntroduction({
                    ...searchParamsIntroduction,
                    Type: 3,
                    PageSize: pageSize,
                    PageIndex: searchParamsIntroduction.PageSize !== pageSize ? 1 : page
                  })
                )
                navigate(
                  `/stylist/introduction?${convertParamsAxios({
                    ...searchParamsIntroduction,
                    Type: 3,
                    PageSize: pageSize,
                    PageIndex: searchParamsIntroduction.PageSize !== pageSize ? 1 : page
                  })}`
                )
                setTimeout(
                  () =>
                    window.document.getElementById('body__page').scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    }),
                  500
                )
              }}
              showSizeChanger
            />
          </Row>
        </div>
      </Form>
      <Modal
        title={open.type ? <p className='text-[#009F10]'>Duyệt</p> : <p className='text-[#ff4d4f]'>Không duyệt</p>}
        open={open.status}
        onOk={handleApprove}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen({ ...open, status: false })}
        okText='Hoàn tất'
        cancelText='Hủy'
      >
        {`Xác nhận ${open.type ? 'duyệt' : 'không duyệt'} ${currentStaff?.fullName || ''} - ${
          currentStaff?.staffId || ''
        }`}
      </Modal>
      <Modal
        title={`Chỉnh sửa mô tả`}
        open={openRichText}
        onOk={handleRichText}
        confirmLoading={confirmLoading}
        onCancel={() => setOpenRichText(false)}
        okText='Hoàn tất'
        cancelText='Hủy'
      >
        <div className='text-editor pb-[50px]'>
          <ReactQuill
            className=' h-[200px]'
            theme='snow'
            value={richText}
            onChange={(value) => {
              if (mount) {
                setRichText(value)
              } else {
                setMount(true)
              }
            }}
            placeholder={'Write something awesome...'}
            modules={modules}
            formats={formats}
          />
        </div>
      </Modal>
    </div>
  )
}
