import { useEffect, useState, useContext } from 'react'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { getSalons } from './api'
import { getListStaffChange } from 'pages/staff/api'
export const KCSPage = () => {
  const { notification } = useContext(AppContext)
  const [reload, setReload] = useState(false)
  const [options, setOptions] = useState({ salon: [] })
  const column: ColumnConfig[] = [
    {
      title: '',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Tổng bill',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Tổng bill cần chấm',
      dataIndex: 'staffName',
      key: 'staffName'
    },
    {
      title: '% Bill không ảnh',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: '% Bill mờ lệch',
      dataIndex: 'level',
      key: 'level'
    },
    {
      title: 'Lỗi Shape (hình khối)',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'Lỗi Shape (hình khối)',
      dataIndex: 'salonChangeName',
      key: 'salonChangeName'
    },
    {
      title: 'Lỗi SharpNess (Đường nét)',
      dataIndex: 'statusName',
      key: 'statusName'
    },
    {
      title: 'Lỗi Completion (Hoàn thiện)',
      dataIndex: 'createdDate',
      key: 'createdDate'
    },
    {
      title: 'Điểm cộng Creative (Sáng tạo)',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate'
    },
    {
      title: 'Tổng lỗi SCSC',
      dataIndex: 'totalSeniorityLevelUp',
      key: 'totalSeniorityLevelUp'
    },
    {
      title: 'Điểm SCSC TB',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Tổng bill uốn',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: '% Bill không ảnh Uốn',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: '% Bill mờ lệch Uốn',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Lỗi Ngọn tóc',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Lỗi Chân tóc',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Lỗi Sóng tóc',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Tổng lỗi uốn',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Điểm TB Uốn',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'Bill chưa đánh giá',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: '% Lỗi KCS',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'createdDate',
      type: TypeSearch.date
    },
    {
      label: 'Salon',
      param: 'salonId',
      type: TypeSearch.select,
      options: options.salon,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Nhân viên',
      param: 'level',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Chọn nhân viên',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }

  const handleFetchData = async () => {
    try {
      const [salon]: any = await Promise.all([getSalons()])
      if (salon.statusCode === 0) {
        setOptions({
          salon: [...salon.data]
        })
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/quality/kcs'
        title='Điểm chất lượng theo bill'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
