import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'

export const getStaffType = (groupId: number) => {
  return StaffRequestView.get(`/v1/staff/types?groupId=${groupId}`)
}
export const getSalons = () => {
  return StaffRequestView.get(`/v1/salons`)
}
export const getASMS = () => {
  return StaffRequestView.get(`/v1/asms`)
}
export const getStaffSkillLevel = () => {
  return StaffRequestView.get(`/v1/staff/skill-levels`)
}
