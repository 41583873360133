import { useState, useEffect, useContext } from 'react'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { getSalons } from './api'

export const ReportError = () => {
  const [reload, setReload] = useState(false)
  const [options, setOptions] = useState({ salon: [] })
  const { notification } = useContext(AppContext)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Time xử lý',
      dataIndex: 'customer_name',
      key: 'customer_name'
    },
    {
      title: 'Time nhận tin',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Salon',
      dataIndex: 'booking_time',
      key: 'booking_time'
    },
    {
      title: 'Lần tranh tra',
      dataIndex: 'booking_date',
      key: 'booking_date'
    },
    {
      title: 'Time phát sinh',
      dataIndex: 'stylist_book',
      key: 'stylist_book'
    },

    {
      title: 'Ảnh đính kèm',
      dataIndex: 'stylist_current',
      key: 'stylist_current'
    },
    {
      title: 'Hình thức',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'Loại lỗi',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Tên lỗi',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Ghi chú GS',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Ảnh nghiệm thu',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Vị trí SM phản hồi',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Hình thức XL',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Ghi chú QL',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Trạng thái xác nhận lỗi',
      dataIndex: 'typeError',
      key: 'typeError'
    },
    {
      title: 'Ghi chú TTCL',
      dataIndex: 'typeError',
      key: 'typeError'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'date',
      type: TypeSearch.date
    },
    {
      label: 'Quản lý giám sát',
      param: 'qlgs',
      type: TypeSearch.autocomplete,
      options: [{ value: 'Giám sát - Quản lý' }],
      placeholder: 'Chọn salon'
    },
    {
      label: 'Salon giám sát',
      param: 'staffType',
      type: TypeSearch.select,
      options: options.salon,
      placeholder: 'Chọn bộ phận',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Trạng thái (SM/DSM/GSM quyết định)',
      param: 'status',
      type: TypeSearch.select,
      col: 4,
      options: [
        { label: 'Chọn trạng thái', value: 1 },
        { label: 'Nhắc nhở', value: 2 },
        { label: 'Không có lỗi', value: 3 },
        { label: 'Có lỗi', value: 4 },
        { label: 'Nghiêm trọng', value: 5 },
        { label: 'Không lỗi do khách quan', value: 6 },
        { label: 'Không lỗi do ghi nhận sai', value: 7 },
        { label: 'Chưa xử lý', value: 8 },
        { label: 'Đã xử lý', value: 9 },
        { label: 'Không thể xử lý do khách quan', value: 10 },
        { label: 'Đạt', value: 11 },
        { label: 'Không đạt', value: 12 },
        { label: 'Không đánh giá', value: 13 }
      ],
      placeholder: 'Chọn bộ phận'
    },
    {
      label: 'SM/DSM xử lý',
      param: 'handle',
      type: TypeSearch.autocomplete,
      options: [{ value: 'Không lọc' }, { value: 'Chưa xử lý' }, { value: 'Đang xử lý' }],
      placeholder: 'Click chọn'
    },
    {
      label: 'SM/DSM xác nhận',
      param: 'confirm',
      type: TypeSearch.autocomplete,
      options: [{ value: 'Không lọc' }, { value: 'Chưa xử lý' }, { value: 'Đang xử lý' }],
      placeholder: 'Click chọn'
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const handleFetchData = async () => {
    try {
      const [salon]: any = await Promise.all([getSalons()])
      if (salon.statusCode === 0) {
        setOptions({
          salon: [...salon.data]
        })
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/supervise/report-error'
        title='Báo cáo giám sát nhân viên'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        reload={reload}
        setReload={setReload}
      />
    </div>
  )
}
