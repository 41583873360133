import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import Page404 from '../pages/errors/404'
import Page403 from '../pages/errors/403'
import Page500 from '../pages/errors/500'
import ScrollToTop from 'components/SrollToTop'
import Loading from '../components/Loading'
import StaffComponent from 'pages/staff/list/page'
import { AddStaff } from 'pages/staff/add/page'
import Staff from 'pages/staff/detail/page'
import StaffChangeComponent from 'pages/staff/change/page'
import { useAppSelector } from 'redux/store'
import StylistAvatarComponent from 'pages/stylist/avatar/page'
import StylistHairComponent from 'pages/stylist/hair/page'
import { BaseListPage } from 'pages/base'
import StylistIntroductionComponent from 'pages/stylist/introduction/page'
import ReportRouterTopup from '../pages/Report/router'
import { routerSupervise } from 'pages/supervise/router'
import { routerBill } from 'pages/bill/router'
import { routerCheckin } from 'pages/checkin/router'
import { routerQuality } from 'pages/quality/router'
import { routerProduct } from 'pages/product/router'
import { routerShineMember } from 'pages/shinemember/router'
import { routerBooking } from 'pages/booking/routerStore'
import { routerCSVC } from 'pages/csvc/router'
// import ShineMemberRouter from '../pages/ShineMember/router'

import BIRouter from 'pages/BI/router'
import ProductsRouter from 'pages/Products/router'
import ServiceRouter from 'pages/Service/router'
import ReportRouter from 'pages/reportVanHanhV3/router'
import EmployeeRouter from 'pages/Employee/router'
import SalonRouter from 'pages/Salon/router'
import NotiSalon from 'pages/pushNotification/notiSalon/index'
import NotiFile from 'pages/pushNotification/notiFile/index'
import ListNotification from 'pages/pushNotification/index'
import UpdateNotiSalon from 'pages/pushNotification/notiSalon/updateSalonNotification'
import UpdateNotiFile from 'pages/pushNotification/notiFile/updateFileNotification'
import { routerKQKD } from 'pages/kqkd/router'
import StylistSelectHair from 'pages/stylist/webview/selectHair'
import StylistSelectIntroduction from 'pages/stylist/webview/selectIntroduction'
import UploadHairRouter from 'pages/UploadHairStyles/router'
import { ExportDataPage } from 'pages/BI/ExportDataPage'
import { CreateScriptPage } from 'pages/BI/CreateScript'
import { UpdateScriptPage } from 'pages/BI/UpdateScript'

const AuthorizationPage = (element, url: string) => {
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  if (urlAccess) {
    return urlAccess.find((i) => url.includes(i.path)) ? element : <Page403 />
  }
}
function MainRouter() {
  const routes = useRoutes([
    {
      path: '/staff/list',
      element: AuthorizationPage(<StaffComponent />, '/staff/list')
    },
    {
      path: '/staff/add',
      element: AuthorizationPage(<AddStaff />, '/staff/add')
    },
    {
      path: '/staff/detail/:id',
      element: AuthorizationPage(<Staff />, '/staff/detail')
    },
    {
      path: '/change',
      element: AuthorizationPage(<StaffChangeComponent />, '/change')
    },
    //noti
    {
      path: '/pushNotification',
      element: AuthorizationPage(<ListNotification />, '/change')
    },
    {
      path: '/pushNotification/NotificationSalon',
      element: AuthorizationPage(<NotiSalon />, '/change')
    },
    {
      path: '/pushNotification/UpdateNotificationSalon/:id',
      element: AuthorizationPage(<UpdateNotiSalon />, '/change')
    },
    {
      path: '/pushNotification/NotificationFile',
      element: AuthorizationPage(<NotiFile />, '/change')
    },
    {
      path: '/pushNotification/UpdateNotificationFile/:id',
      element: AuthorizationPage(<UpdateNotiFile />, '/change')
    },
    {
      path: '/stylist/avatar',
      element: AuthorizationPage(<StylistAvatarComponent />, '/stylist/avatar')
    },
    {
      path: '/stylist/hair',
      element: AuthorizationPage(<StylistHairComponent />, '/stylist/hair')
    },
    {
      path: '/stylist/introduction',
      element: AuthorizationPage(<StylistIntroductionComponent />, '/stylist/introduction')
    },
    {
      path: '/bi/export',
      element: AuthorizationPage(<ExportDataPage />, '/bi/export')
    },
    {
      path: '/bi/update',
      element: AuthorizationPage(<UpdateScriptPage />, '/bi/update')
    },
    {
      path: '/bi/create',
      element: AuthorizationPage(<CreateScriptPage />, '/bi/create')
    },
    // {
    //   path: '/bi/*',
    //   element: AuthorizationPage(<BIRouter />, '/bi/')
    // },
    {
      path: '/service/*',
      element: <ServiceRouter />
    },
    {
      path: '/stylist/webview/selectHair',
      element: <StylistSelectHair />
    },
    {
      path: '/stylist/webview/selectIntroduction',
      element: <StylistSelectIntroduction />
    },
    {
      path: '/base',
      element: <BaseListPage />
    },
    {
      path: '/salon/*',
      element: <SalonRouter />
    },
    {
      path: '/product/*',
      element: <ProductsRouter />
    },
    {
      path: '/operation/*',
      element: <ReportRouter />
    },
    {
      path: '/employee/*',
      element: <EmployeeRouter />
    },
    {
      path: '/report/*',
      element: <ReportRouterTopup />
    },
    {
      path: '/upload-hair-styles/*',
      // element: <UploadHairRouter />
      element: AuthorizationPage(<UploadHairRouter />, '/upload-hair-styles/*')
    },
    // {
    //   path: '/shinemember/*',
    //   element: <ShineMemberRouter />
    // },
    {
      path: '/403',
      element: <Page403 />
    },
    {
      path: '/404',
      element: <Page404 />
    },
    {
      path: '/500',
      element: <Page500 />
    },
    ...routerCheckin,
    ...routerQuality,
    ...routerSupervise,
    ...routerProduct,
    ...routerShineMember,
    ...routerBooking,
    ...routerCSVC,
    ...routerBill,
    ...routerKQKD
  ])
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  )
}

export default MainRouter
