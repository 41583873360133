import ListKQKD from './list'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerKQKD: Router = [
  {
    path: '/kqkd/summary',
    element: <ListKQKD />
  }
]
