import { useState } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import { ColumnConfig, SearchConfig, TypeSearch } from '../base/util'
import FormComponent from '../base/Form'

export const ProductConfiguration = () => {
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Đơn vị',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Mô tả',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Giá bán chung',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Giá bán',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Chiết khấu lương(%)',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Sử dụng',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'Cho phép kiểm',
      dataIndex: 'idSql',
      key: 'idSql'
    },
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Chọn Salon',
      param: 'statusName',
      type: TypeSearch.select,
      defaultValue: 1,
      options: [
        { value: 1, label: 'Chọn tất cả Salon' },
        { value: 2, label: 'VP HN' },
        { value: 0, label: 'Xin nghỉ' },
        { value: 3, label: 'Cho nghỉ' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Chọn danh mục',
      param: 'statusName',
      type: TypeSearch.select,
      defaultValue: 1,
      options: [
        { value: 1, label: 'Chọn danh mục' },
        { value: 2, label: 'CHĂM SÓC CƠ THỂ' },
        { value: 3, label: 'Nghỉ tạm thời' },
        { value: 4, label: 'Cho nghỉ' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Chọn muc Sản phẩm/ Vật tư',
      param: 'statusName',
      type: TypeSearch.select,
      defaultValue: 1,
      options: [
        { value: 1, label: 'Sản phẩm' },
        { value: 2, label: 'Vật tư' },
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    }

  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/product/config'
        title='Cấu hình sản phẩm cho Salon'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/product/config/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
