import '../index.scss'
import { useState, useEffect } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Button } from 'antd'
import { getListSalon, getListServiceSalon } from '../api'
import type { TabsProps } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'

export const ServicesConfig = () => {
  const location = useLocation()
  const [reload, setReload] = useState(false)
  const [activeTab, setActiveTab] = useState(true)
  const searchParams = new URLSearchParams(location.search)
  const tabsValue = searchParams.get('tabs')
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const columnSalon: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'Salon',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Chi tiết',
      key: 'detail',
      render: (record) => (
        <Button onClick={() => handleChangeStatus(record, 'Publish')}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnService: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Chi tiết',
      key: 'detail',
      render: (record) => (
        <Button onClick={() => handleChangeStatus(record, 'Publish')}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên Salon',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên Salon'
      // require: true
    },
    {
      label: 'Tên Dịch vụ',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên dịch vụ'
      // require: true
    },
    {
      label: 'Lọc kết quả',
      param: 'tabs',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Salon' },
        { value: 2, label: 'Dịch vụ' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.tabs) {
      console.log(changedValues)
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/service/config'
        title='Dịch vụ Salon'
        column={tabsValue === '2' ? columnService : columnSalon}
        columnSearch={columnSearch}
        fetchData={tabsValue === '2' ? getListServiceSalon : getListSalon}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/service/config/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
