import { setResourceByKey } from '../staffSlice'

export const fetchDataByApiKey = async (
  key: string,
  api: (...params) => void,
  dispatch?: (...params) => void,
  notification?: (type: any, message: string, description: string) => void,
  payload?: unknown
) => {
  try {
    const data = localStorage.getItem(key)
    if (data) {
      dispatch(
        setResourceByKey({
          key: key,
          data: JSON.parse(data)
        })
      )
      if (key === 'salonId') {
        dispatch(setResourceByKey({ salonId: JSON.parse(data) }))
      }
      return
    }
    const res: any = await api(payload)
    if (res.statusCode === 0) {
      if (key === 'managers') {
        dispatch(setResourceByKey({ key: 'managers', data: res.data.items }))
        localStorage.setItem(key, JSON.stringify(res.data.items))
      } else if (key === 'salonId') {
        dispatch(setResourceByKey({ key: 'salonId', data: res.data }))
        localStorage.setItem(key, JSON.stringify(res.data))
      } else {
        dispatch(
          setResourceByKey({
            key: key,
            data: res.data
          })
        )
        localStorage.setItem(key, JSON.stringify(res.data))
      }
    }
  } catch (error) {
    notification('error', 'Thất bại', 'Có lỗi xảy ra!')
  }
}
