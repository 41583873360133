import { useState } from 'react'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { Checkbox } from 'antd'

export const ListProduct = () => {
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'productName',
      key: 'productName'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'staffTypeName',
      key: 'staffTypeName'
    },
    {
      title: 'Mã barcode',
      dataIndex: 'skillLevelName',
      key: 'skillLevelName'
    },
    {
      title: 'Mô tả',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'Giá nhập',
      dataIndex: 'salonChangeName',
      key: 'salonChangeName'
    },

    {
      title: 'Giá bán',
      dataIndex: 'statusName',
      key: 'statusName'
    },
    {
      title: 'Chiết khấu lương (%)',
      dataIndex: 'createdDate',
      key: 'createdDate'
    },
    {
      title: 'Theo dõi tồn kho',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate',
      render: (item: Date) => (
        <div className='flex justify-center'>
          <Checkbox checked />
        </div>
      )
    },
    {
      title: 'Nổi bật',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate',
      render: (item: Date) => (
        <div className='flex justify-center'>
          <Checkbox checked />
        </div>
      )
    },
    {
      title: 'Publish',
      dataIndex: 'lastTimekeepingDate',
      key: 'lastTimekeepingDate',
      render: (item: Date) => (
        <div className='flex justify-center'>
          <Checkbox checked />
        </div>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Danh mục',
      param: 'menu',
      type: TypeSearch.select,
      options: resource.salonId,
      placeholder: 'Chọn danh mục',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Sản phẩm',
      param: 'product',
      type: TypeSearch.input,
      options: resource.salonId,
      placeholder: 'Tên sản phẩm'
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/product/list'
        title='Danh sách Sản phẩm'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        linkADD='/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
