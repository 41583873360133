import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'

export const getStaffType = (groupId: number) => {
  return StaffRequestView.get(`/v1/staff/types?groupId=${groupId}`)
}
export const getCity = () => {
  return StaffRequestView.get(`/v1/cities`)
}
export const getSalons = () => {
  return StaffRequestView.get(`/v1/salons`)
}
