import '../index.scss'
import { useState } from 'react'
import { ColumnConfig, JSONToCsv, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Switch, Button } from 'antd'
import { getListStylist } from '../api'
import { EditOutlined, FileDoneOutlined } from '@ant-design/icons'

export const ListTopup = () => {
  const [reload, setReload] = useState(false)
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'Ngày',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Tên Salon',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'ID',
      dataIndex: 'ShortName',
      key: 'staffTypeName'
    },
    {
      title: 'Item',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Mã ( Code )',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Số lượng',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Đơn giá',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Lương nhân viên',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Thành tiền',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Chiết khấu (%)',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Tiền chiết khấu',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Tổng doanh thu',
      dataIndex: 'Address',
      key: 'skillLevelName'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'createdDate',
      type: TypeSearch.date,
      require: true,
      buttonDate: true
    },
    {
      label: 'Tên sản phẩm',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên sản phẩm'
      // require: true
    },
    {
      label: 'Chọn tất cả Salon',
      param: 'salon',
      type: TypeSearch.select,
      options: [
        { label: 'VP HCM', value: '1' },
        { label: 'VP HN', value: '2' },
        { label: 'CSVC HN', value: '3' },
        { label: 'CSVC HCM', value: '4' },
        { label: 'QLCL', value: '5' },
        { label: '346 KT', value: '6' },
        { label: '235 DC', value: '7' },
        { label: '168 NVC', value: '8' },
        { label: '386 NGT BN', value: '9' }
      ],
      placeholder: 'Chọn tất cả Salon',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Chọn loại',
      param: 'type',
      type: TypeSearch.select,
      options: [
        { label: 'Thẻ nạp Topup', value: '1' },
        { label: 'Thẻ nạp Topup Campaign', value: '2' }
      ],
      placeholder: 'Chọn loại',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const onExportExcell = async () => {
    console.log('onExportExcell')
  }
  const dataReport = [
    {
      key: 1,
      title: 'Tổng thành tiền	',
      number: '520.000.000'
    },
    {
      key: 2,
      title: 'Tổng chiết khấu',
      number: '250.000.000'
    },
    {
      key: 3,
      title: 'Tổng doanh thu',
      number: '1.500.950.200'
    }
  ]
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/report/topup'
        title='Báo cáo Topup'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStylist}
        handleChangeValue={handleChangeValue}
        exportCsv={false}
        // linkADD='/employee/skill/add'
        reload={reload}
        setReload={setReload}
        middleComponent={
          <div>
            <div className='flex gap-x-5 mt-5 mb-5'>
              <Button
                onClick={onExportExcell}
                type='default'
                className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
              >
                <div className='flex items-center justify-center'>Xuất báo cáo chi tiết</div>
              </Button>
              <Button
                onClick={onExportExcell}
                type='default'
                className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
              >
                <div className='flex items-center justify-center'>Xuất báo cáo tổng hợp</div>
              </Button>
            </div>
            <div className='service-report-table'>
              <div className='service-report-table-header'>
                <FileDoneOutlined className='service-report-table-header-icon' />
                <span className='service-report-table-header-text'>Số lượng bán ra</span>
              </div>
              <div className='service-report-table-body' style={{ width: '300px' }}>
                {dataReport.map((report, index) => (
                  <>
                    <div className='service-report-table-body-item' key={report.key}>
                      <div className='service-report-table-body-item-title'>{report.title}</div>
                      <div className='service-report-table-body-item-number'>{report.number}</div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        }
      ></FormComponent>
    </div>
  )
}
