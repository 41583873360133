/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import { Button, DatePicker, Form, Input, Radio, Select, Spin, message, Upload, Switch } from 'antd'
import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import type { DatePickerProps } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const { TextArea } = Input
export const SalonDetailtPage: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [isAddedType, setIsAddedType] = useState(0)
  const [isSalon, setIsSalon] = useState(true)
  const [isGroup, setIsGroup] = useState()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const onValuesChange = async (changedValues: any) => {
    // if (changedValues?.provinceId !== undefined) {
    //   const res: any = await getDistrict(changedValues.provinceId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, districtId: res.data }))
    //   }
    //   form.resetFields(['districtId', 'wardId'])
    // }
    // if (changedValues?.districtId !== undefined) {
    //   const res: any = await getWard(changedValues.districtId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, wardId: res.data }))
    //   }
    //   form.resetFields(['wardId'])
    // }
    // if (changedValues?.salonId !== undefined) {
    //   const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
    //   form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    // }
    // if (changedValues?.addedType !== undefined) {
    //   setIsAddedType(changedValues?.addedType)
    // }
    // if (changedValues?.staffTypeId !== undefined) {
    //   const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
    //     (i) => i.id === changedValues?.staffTypeId
    //   )
    //   dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    //   if (changedValues?.staffTypeId === 17) setIsSalon(false)
    //   else setIsSalon(true)
    // }
  }
  const ListDepartment = [
    { label: 'Stylist', value: 1 },
    { label: 'Skiner', value: 2 },
    { label: 'Bảo vệ', value: 3 },
    { label: 'Chuyên viên tư vấn', value: 3 }
  ]
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3'>CHI TIẾT THÔNG TIN SALON</h3>
        <div className='mt-12 custom-form-details-form'>
          <div>
            <Form.Item
              label='Tên salon /Tên viết tắt:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input placeholder='Nhập tên Salon' style={{ width: 'calc(50% - 10px)' }} />
                <Input placeholder='Nhập tên viết tắt' style={{ width: 'calc(50% - 10px)' }} />
              </div>
            </Form.Item>
            <Form.Item
              label='SalonBackup /MacIP:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Select
                  defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Input placeholder='Nhập Imei hoặc MacIP' style={{ width: 'calc(50% - 10px)' }} />
              </div>
            </Form.Item>
            <Form.Item
              label='Tọa độ:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input placeholder='Nhập kinh độ' style={{ width: 'calc(50% - 10px)' }} />
                <Input placeholder='Nhập vĩ độ' style={{ width: 'calc(50% - 10px)' }} />
              </div>
              <div className='' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Select
                  defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Select
                  defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
              </div>
            </Form.Item>
            <Form.Item
              label='Địa chỉ:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <Select
                  defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
                <Select
                  defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
              </div>
              <Input placeholder='Nhập địa chỉ' />
            </Form.Item>
            <Form.Item
              label='Số điện thoại /Email:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input placeholder='Nhập tên Salon' style={{ width: 'calc(50% - 10px)' }} />
                <Input placeholder='Nhập tên viết tắt' style={{ width: 'calc(50% - 10px)' }} />
              </div>
            </Form.Item>
            <Form.Item label='Mô Tả:' name='idNo' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <TextArea
                maxLength={600}
                placeholder='Nhập mô tả Nội dung quy trình / quy định'
                style={{ height: 120 }}
              />
            </Form.Item>
            <Form.Item
              label='Chữ ký SMS:'
              name='groupId'
              rules={[{ required: true, message: 'Không được để trống' }]}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Input placeholder='Chữ ký SMS' />
            </Form.Item>
            <Form.Item
              label='Thứ tự/Loại salon:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input placeholder='Ví dụ: 1' style={{ width: 'calc(50% - 10px)' }} />
                <Select
                  defaultValue='lucy'
                  style={{ width: 'calc(50% - 10px)' }}
                  onChange={handleChange}
                  options={[
                    { value: 'jack', label: 'Jack' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true }
                  ]}
                />
              </div>
            </Form.Item>
            <Form.Item
              label='Thời gian khởi tạo:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <DatePicker placeholder='Chọn thời gian' style={{ width: 'calc(50% - 10px)' }} />
            </Form.Item>
            <Form.Item
              label='Thời gian chỉnh sửa cuối cùng:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <DatePicker placeholder='Chọn thời gian' style={{ width: 'calc(50% - 10px)' }} />
            </Form.Item>
            <Form.Item
              label='Lựa chọn salon:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 10 }}>Publish</span> <Switch defaultChecked={true} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 30px' }}>
                  <span style={{ marginRight: 10 }}>Salon hội quán</span> <Switch defaultChecked={false} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: 10 }}>Salon OTP</span> <Switch defaultChecked={false} />
                </div>
              </div>
            </Form.Item>
            <div className='items-baseline flex gap-6 mb-5 mt-5'>
              <div className='w-[345px] text-lg text-[#000] text-right font-bold'>CẤU HÌNH CHO MOBILE APP</div>
            </div>
            <Form.Item
              label='Quản lý salon:'
              name='groupId'
              rules={[{ required: true, message: 'Không được để trống' }]}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Input placeholder='Tên quản lý Salon' style={{ width: 'calc(50% - 10px)' }} />
            </Form.Item>
            <Form.Item
              label='Fanpage:'
              name='groupId'
              rules={[{ required: true, message: 'Không được để trống' }]}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Input placeholder='Link Fanpage' style={{ width: 'calc(50% - 10px)' }} />
            </Form.Item>
            <Form.Item
              label='Fanpage ID:'
              name='groupId'
              rules={[{ required: true, message: 'Không được để trống' }]}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
            >
              <Input placeholder='VD: 3789100098941520' style={{ width: 'calc(50% - 10px)' }} />
            </Form.Item>
            <Form.Item
              label='Thêm ảnh Salon'
              name='idNo'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              className='mt-8'
            >
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                beforeUpload={beforeUpload}
                onChange={handleChangeImage}
              >
                {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Form.Item label='' name='idNo'>
          <Button
            className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
