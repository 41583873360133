/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import {
  Button,
  Table,
  Tag,
  Form,
  Input,
  notification,
  Radio,
  Select,
  Spin,
  message,
  Upload,
  Checkbox,
  Space
} from 'antd'
import { useContext, useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import ApiEndpoint from './api'

const { TextArea } = Input

export const CreateScriptPage: React.FC = () => {
  const formRef = useRef(null)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [scriptDataId, setScriptDataId] = useState('')

  const onFinish = async (values) => {
    setIsloading(true)
    try {
      const body = {
        Name: values.Name,
        Script: values.Script
      }
      const response: any = await ApiEndpoint.createScript(body)
      if (response.statusCode === 0) {
        notification.success({
          description: 'Thành công',
          message: 'Thành công tạo mới dữ liệu Script'
        })
        formRef.current.resetFields()
      } else {
        notification.warning({
          description: 'Lỗi',
          message: 'Hãy kiểm tra lại tên của script bạn đặt xem có bị trùng không ?'
        })
      }
    } catch (error) {
      notification.error({
        description: 'Lỗi',
        message: 'Lỗi xảy ra trong quá trình tạo dữ liệu'
      })
      console.error('Error checking Name:', error)
    } finally {
      setIsloading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    console.log('')
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    setScriptDataId(value)
  }
  return (
    <Spin tip='Loading ..' spinning={isLoading}>
      <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
        <Form
          className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
          onValuesChange={onValuesChange}
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete='off'
          ref={formRef}
        >
          <h3 className='font-bold text-[22px] mt-3 ml-10'>TẠO MỚI SCRIPT DATA</h3>
          <div className=' mt-12 custom-form-details-form'>
            <div>
              <div>Tiêu Đề Script</div>
              <Form.Item
                label=''
                name='Name'
                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề Script!' }]}
                style={{ width: '100%' }}
              >
                <Input
                  type='text'
                  className='mt-5'
                  placeholder='Tiêu đề cho nội dung Script'
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <div className='mt-10'>Nội dung Script</div>
              <Form.Item
                label=''
                name='Script'
                rules={[{ required: true, message: 'Vui lòng nhập nội dung Script!' }]}
                style={{ width: '100%' }}
              >
                <TextArea placeholder='Nội dung Script' id='textarea' className='mt-5' />
              </Form.Item>
            </div>
          </div>
          <Form.Item label='' name='idNo'>
            <Button className='w-[130px] mt-12 mb-12 bg-[#4096ff] text-white button-submit-table' htmlType='submit'>
              {!loading ? 'Tạo mới Script' : <Spin />}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
}
