import React from 'react'
import { useRoutes } from 'react-router-dom'
import { UploadHairStyles } from './index'

function UploadHairRouter() {
  const routes = useRoutes([
    {
      path: '/',
      element: <UploadHairStyles />
    }
  ])

  return <>{routes}</>
}

export default UploadHairRouter
