/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  TimePicker,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  message,
  Upload,
  Switch,
  InputNumber
} from 'antd'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import type { DatePickerProps } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const { TextArea } = Input
const { Dragger } = Upload
export const EnterGSError2019: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const onValuesChange = async (changedValues: any) => {
    // if (changedValues?.provinceId !== undefined) {
    //   const res: any = await getDistrict(changedValues.provinceId)
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  return (
    <div style={{ minHeight: 'calc(100vh)', margin: 'auto' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-5'>THÊM MỚI LỖI GIÁM SÁT NHÂN VIÊN</h3>
        <div className='mt-12 custom-form-details-form'>
          <div className='' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Form.Item
              label='Salon giám sát:'
              name='userName'
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '32%' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Select
                // defaultValue='lucy'
                style={{ width: '100%' }}
                onChange={handleChange}
                placeholder='Chọn salon'
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                  { value: 'disabled', label: 'Disabled', disabled: true }
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Vị trí:'
              name='userName'
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '32%' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Select
                // defaultValue='lucy'
                style={{ width: '100%' }}
                onChange={handleChange}
                placeholder='Chọn vị trí'
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                  { value: 'disabled', label: 'Disabled', disabled: true }
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Thời gian mắc lỗi:'
              name='userName'
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '32%' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <div className='' style={{ display: 'flex', width: '100%' }}>
                <DatePicker placeholder='Chọn ngày' style={{ width: 'calc(100% - 110px)' }} />
                <TimePicker
                  placeholder='giờ:phút (mặc định là thời điểm submit)'
                  style={{ width: 680, marginLeft: 10 }}
                />
              </div>
            </Form.Item>
          </div>
          <div className='mt-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Form.Item
              label='Hình thức xử lý:'
              name='userName'
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '32%' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Select
                // defaultValue='lucy'
                style={{ width: '100%' }}
                onChange={handleChange}
                placeholder='Chọn hình thức'
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                  { value: 'disabled', label: 'Disabled', disabled: true }
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Loại lỗi:'
              name='userName'
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '32%' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Select
                // defaultValue='lucy'
                style={{ width: '100%' }}
                onChange={handleChange}
                placeholder='Chọn kiểu lỗi'
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                  { value: 'disabled', label: 'Disabled', disabled: true }
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Tên lỗi:'
              name='userName'
              labelCol={{ span: 16 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '32%' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Select
                // defaultValue='lucy'
                style={{ width: '100%' }}
                onChange={handleChange}
                placeholder='Chọn tên lỗi'
                options={[
                  { value: 'jack', label: 'Jack' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                  { value: 'disabled', label: 'Disabled', disabled: true }
                ]}
              />
            </Form.Item>
          </div>
          <Form.Item label='Lần thanh tra:' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <InputNumber min={1} max={1000} defaultValue={3} />
          </Form.Item>
          <Form.Item label='Ghi chú:' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <TextArea placeholder='Nhập ghi chú' style={{ height: 90 }} />
          </Form.Item>
          <div className='mt-5 mb-5'>
            <span className='mr-10' style={{ fontWeight: '500' }}>
              Hình ảnh lỗi (Có thể lựa chọn nhiều file ảnh):
            </span>
            <Upload>
              <Button type='primary'>Upload</Button>
            </Upload>
          </div>
          <Dragger height={160}></Dragger>
        </div>
        <Form.Item label='' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Button
            className='w-[90px] mt-10 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Submit' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
