/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import '../index.scss'
import { Button, DatePicker, Form, Input, Radio, Select, Spin, message, Upload, Checkbox } from 'antd'
import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const { TextArea } = Input
export const DetailService: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [isAddedType, setIsAddedType] = useState(0)
  const [isSalon, setIsSalon] = useState(true)
  const [isGroup, setIsGroup] = useState()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  const handleChangeImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const onValuesChange = async (changedValues: any) => {
    // if (changedValues?.provinceId !== undefined) {
    //   const res: any = await getDistrict(changedValues.provinceId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, districtId: res.data }))
    //   }
    //   form.resetFields(['districtId', 'wardId'])
    // }
    // if (changedValues?.districtId !== undefined) {
    //   const res: any = await getWard(changedValues.districtId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, wardId: res.data }))
    //   }
    //   form.resetFields(['wardId'])
    // }
    // if (changedValues?.salonId !== undefined) {
    //   const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
    //   form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    // }
    // if (changedValues?.addedType !== undefined) {
    //   setIsAddedType(changedValues?.addedType)
    // }
    // if (changedValues?.staffTypeId !== undefined) {
    //   const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
    //     (i) => i.id === changedValues?.staffTypeId
    //   )
    //   dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    //   if (changedValues?.staffTypeId === 17) setIsSalon(false)
    //   else setIsSalon(true)
    // }
  }
  const ListDepartment = [
    { label: 'Stylist', value: 1 },
    { label: 'Skiner', value: 2 },
    { label: 'Bảo vệ', value: 3 },
    { label: 'Chuyên viên tư vấn', value: 3 }
  ]
  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10'>CHỈNH SỬA THÔNG TIN DỊCH VỤ</h3>
        <div className=' mt-12 custom-form-details-form'>
          <Form.Item
            label='Tên dịch vụ:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Nhập tiêu đề dịch vụ' />
            <Checkbox style={{ marginTop: 10 }}>Combo</Checkbox>
          </Form.Item>
          <Form.Item
            label='Mã dịch vụ:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Nhập mã dịch vụ' />
          </Form.Item>
          <Form.Item label='Mô Tả:' name='idNo' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <TextArea maxLength={600} placeholder='Nhập mô tả thông tin dịch vụ' style={{ height: 120 }} />
          </Form.Item>
          <Form.Item
            label='Giá:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 1000000' style={{ width: '50%' }} />
            <span style={{ marginLeft: 20 }}>VNĐ</span>
          </Form.Item>
          <Form.Item
            label='Khuyến mại giảm giá:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 5' style={{ width: '50%' }} />
            <span style={{ marginLeft: 20 }}>%</span>
          </Form.Item>
          <Form.Item
            label='Hệ số điểm hài lòng:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 1' style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item
            label='Thời gian phục vụ Stylist (phút):'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 1' style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item
            label='Thời gian phục vụ Skinner (phút):'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 1' style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item
            label='Cấu hình bộ phận hiển thị:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Select
              defaultValue='1'
              style={{ width: '20%' }}
              onChange={handleChange}
              options={[
                { value: '1', label: '---Cả 2---' },
                { value: '2', label: 'Stylist' },
                { value: '3', label: 'Skiner' }
              ]}
            />
          </Form.Item>
          <Form.Item
            label='Danh mục dịch vụ:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Select
              defaultValue={'1'}
              style={{ width: '20%' }}
              onChange={handleChange}
              options={[
                { value: '1', label: 'Chọn danh mục' },
                { value: '2', label: 'SẢN PHẨM BÁN' },
                { value: '3', label: 'Vật tư xuất dùng' }
              ]}
            />
          </Form.Item>
          <Form.Item
            label='Nổi bật:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label='Phụ trợ ( Miễn phí ):'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label='Publish:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <div className='items-baseline flex gap-6 mb-5'>
            <div className='w-[345px] text-lg text-[#000] text-right font-bold'>CẤU HÌNH CHO MOBILE APP</div>
          </div>
          <Form.Item label='Thêm ảnh' name='idNo' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} className='mt-8'>
            <div className=''>
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                beforeUpload={beforeUpload}
                onChange={handleChangeImage}
              >
                {imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </div>
          </Form.Item>
          <Form.Item
            label='Thời gian phục vụ Skinner(phút):'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Link Youtube' style={{ width: '50%', display: 'block' }} />
            <Input placeholder='Tiêu đề video' style={{ width: '50%', display: 'block', marginTop: '10px' }} />
            <Input placeholder='Mô tả video' style={{ width: '50%', display: 'block', marginTop: '10px' }} />
          </Form.Item>
          <Form.Item
            label='Thứ tự:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Input placeholder='Số thứ tự sắp xếp' style={{ width: '50%', display: 'block' }} />
          </Form.Item>
          <Form.Item
            label='Dịch vụ chính:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label='Hiện trên app:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label='Loại dịch vụ:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Select
              defaultValue={'1'}
              style={{ width: '20%' }}
              onChange={handleChange}
              options={[
                { value: '1', label: 'Chọn danh mục' },
                { value: '2', label: 'SẢN PHẨM BÁN' },
                { value: '3', label: 'Vật tư xuất dùng' }
              ]}
            />
          </Form.Item>
          <Form.Item
            label='Có chấm SCSC:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label='Có chấm uốn:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label='Chụp ảnh liệu trình:'
            name='userName'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              },
              { max: 60, message: 'Không quá 50 ký tự' },
              {
                pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                message: 'Tên đăng nhập không hợp lệ'
              }
            ]}
          >
            <Checkbox />
          </Form.Item>
        </div>
        <Form.Item label='' name='idNo'>
          <Button
            className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
