// export const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
//   return new Promise((resolve, reject) => {
//     const img = new Image()
//     img.src = URL.createObjectURL(file)

//     img.onload = () => {
//       const canvas = document.createElement('canvas')
//       const ctx = canvas.getContext('2d')
//       const scale = Math.min(maxWidth / img.width, maxHeight / img.height)

//       canvas.width = img.width * scale
//       canvas.height = img.height * scale

//       ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

//       canvas.toBlob((blob) => {
//         resolve(blob)
//       }, file.type)
//     }

//     img.onerror = () => {
//       reject(new Error('Failed to load image'))
//     }
//   })
// }

import { notification } from 'antd'
import heic2any from 'heic2any'

const maxSizeInBytes = 900 * 1024 // 900KB

export const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    // Kiểm tra kích thước tệp
    if (file.size > maxSizeInBytes) {
      notification.warning({
        message: 'Cảnh báo',
        description: 'Kích thước file quá lớn, kích thước file ảnh nên chỉ dưới 900Kb.'
      })
      reject(new Error('File size exceeds the maximum allowed size.'))
      return
    }

    if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
      const arrayBufferPromise = file.arrayBuffer()

      arrayBufferPromise
        .then((arrayBuffer) => {
          const options = {
            blob: new Blob([arrayBuffer], { type: 'image/heic' }),
            toType: 'image/jpeg',
            quality: 0.7
          }

          heic2any(options)
            .then((result) => {
              if (result instanceof Blob) {
                resolve(result)
              } else {
                reject(new Error('Invalid result from heic2any'))
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      const img = new Image()
      img.src = URL.createObjectURL(file)

      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const scale = Math.min(maxWidth / img.width, maxHeight / img.height)

        canvas.width = img.width * scale
        canvas.height = img.height * scale

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob((blob) => {
          if (blob instanceof Blob) {
            resolve(blob)
          } else {
            reject(new Error('Failed to create a Blob'))
          }
        }, file.type)
      }

      img.onerror = () => {
        reject(new Error('Failed to load image'))
      }
    }
  })
}
