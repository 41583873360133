import { ApproveShineMemberPage } from './approve'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerShineMember: Router = [
  {
    path: '/shinemember/approve',
    element: <ApproveShineMemberPage />
  }
]
