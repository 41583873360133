import { Button, Form, Input, Spin, message, Checkbox } from 'antd'
import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'

const { TextArea } = Input
export const AddSkill: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    // if (changedValues?.provinceId !== undefined) {
    //   const res: any = await getDistrict(changedValues.provinceId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, districtId: res.data }))
    //   }
    //   form.resetFields(['districtId', 'wardId'])
    // }
    // if (changedValues?.districtId !== undefined) {
    //   const res: any = await getWard(changedValues.districtId)
    //   if (res.statusCode === 0) {
    //     dispatch(setResource({ ...resource, wardId: res.data }))
    //   }
    //   form.resetFields(['wardId'])
    // }
    // if (changedValues?.salonId !== undefined) {
    //   const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
    //   form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    // }
    // if (changedValues?.addedType !== undefined) {
    //   setIsAddedType(changedValues?.addedType)
    // }
    // if (changedValues?.staffTypeId !== undefined) {
    //   const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
    //     (i) => i.id === changedValues?.staffTypeId
    //   )
    //   dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    //   if (changedValues?.staffTypeId === 17) setIsSalon(false)
    //   else setIsSalon(true)
    // }
  }

  const onChangeCheckbox = () => {
    console.log('oke')
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-5'>THÊM MỚI THÔNG TIN BẬC KỸ NĂNG</h3>
        <div className='mt-12  custom-form-details-form'>
          <div className='mt-20'>
            <Form.Item
              label='Bậc kỹ năng:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input placeholder='Nhập tiêu đề Nội dung quy trình / quy định' />
            </Form.Item>
            <Form.Item label='Mô Tả:' name='idNo' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <TextArea
                maxLength={600}
                placeholder='Nhập mô tả Nội dung quy trình / quy định'
                style={{ height: 120 }}
              />
            </Form.Item>
            <Form.Item
              label='Thứ tự sắp xếp:'
              name='userName'
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 60, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input placeholder='Nhập tiêu đề Nội dung quy trình / quy định' />
            </Form.Item>
            <Form.Item label='Publish:' name='userName' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
              <Checkbox onChange={onChangeCheckbox} defaultChecked={true} style={{ marginTop: 2 }}></Checkbox>
            </Form.Item>
          </div>
        </div>
        <Form.Item label='' name='idNo'>
          <Button
            className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
