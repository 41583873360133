import { Link } from 'react-router-dom'
import { MenuStaff } from './MenuStaff'
import { useAppDispatch, useAppSelector } from 'redux/store'
import {
  convertParamsAxios,
  getASMS,
  getCity,
  getContractType,
  getManagers,
  getPermission,
  getProfileType,
  getRecruitmentSource,
  getSalons,
  getStaffSkillLevel,
  getStaffType
} from '../api'
import { useContext, useEffect } from 'react'
import { fetchDataByApiKey } from '../hook/fetchViewStaff'
import { AppContext } from 'layouts/DashboardLayout'

const Staff: React.FC = () => {
  const { notification } = useContext(AppContext)
  const dispatch = useAppDispatch()
  const searchParams = useAppSelector((state) => state.staff.searchParams)
  const handleFetchData = async () => {
    try {
      await Promise.all([
        fetchDataByApiKey('staffTypeId', getStaffType, dispatch, notification, 1),
        fetchDataByApiKey('staffTypeId2', getStaffType, dispatch, notification, 2),
        fetchDataByApiKey('allSkillLevel', getStaffSkillLevel, dispatch, notification),
        fetchDataByApiKey('salonId', getSalons, dispatch, notification),
        fetchDataByApiKey('provinceId', getCity, dispatch, notification),
        fetchDataByApiKey('recruitmentSource', getRecruitmentSource, dispatch, notification),
        fetchDataByApiKey('permission', getPermission, dispatch, notification),
        fetchDataByApiKey('profileTypes', getProfileType, dispatch, notification),
        fetchDataByApiKey('contractType', getContractType, dispatch, notification),
        fetchDataByApiKey('asms', getASMS, dispatch, notification),
        fetchDataByApiKey('managers', getManagers, dispatch, notification)
      ])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  return (
    <>
      <div className='w-full float-right px-5 py-5 text-sm flex gap-x-3 text-[#b7b3b3]'>
        <Link className='hover:text-black' to='#'>
          Quản lý nhân viên
        </Link>
        <span>{`>`}</span>
        <Link className='hover:text-black' to={`/staff/list?${convertParamsAxios(searchParams)}`}>
          Danh sách nhân viên
        </Link>
        <span>{`>`}</span>
        <span className='text-black'>Sửa thông tin nhân viên</span>
      </div>
      <div className='min-h-[100vh] w-full m-auto bg-[#F3F3F3] flex'>
        <div className='m-auto'>
          <h3 className='text-center font-bold text-[22px] mt-3 mb-6'>SỬA THÔNG TIN NHÂN VIÊN</h3>
          <MenuStaff />
        </div>
      </div>
    </>
  )
}

export default Staff
