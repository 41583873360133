import React from 'react'
import { useRoutes } from 'react-router-dom'

import { ServiceListPage } from './list'
import { AddServicePage } from './list/add'
import { DetailService } from './list/detail'
import { AddConfigServicePage } from './config/add'
import { ServicesConfig } from './config/index'

function ServiceRouter() {
  const routes = useRoutes([
    {
      path: '/list',
      element: <ServiceListPage />
    },
    {
      path: '/list/add',
      element: <AddServicePage />
    },
    {
      path: '/config',
      element: <ServicesConfig />
    },
    {
      path: '/config/add',
      element: <AddConfigServicePage />
    },
    {
      path: '/detail/*',
      element: <DetailService />
    }
  ])

  return <>{routes}</>
}

export default ServiceRouter
