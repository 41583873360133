import '../index.scss'
import { useState } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Switch } from 'antd'
import { getListSalon } from '../api'

export const List = () => {
  const [reload, setReload] = useState(false)
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'Saon',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Saon Backup',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Tên tắt',
      dataIndex: 'ShortName',
      key: 'staffTypeName'
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'Address',
      key: 'skillLevelName'
    },
    {
      title: 'Loại salon',
      dataIndex: 'typeSalon',
      key: 'typeSalon'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'Phone',
      key: 'Phone'
    },
    {
      title: 'Publish',
      key: 'Publish',
      render: (record) => (
        <Switch defaultChecked={record.Publish} onChange={() => handleChangeStatus(record, 'Publish')} />
      )
    },
    {
      title: 'Salon Hội Quán',
      key: 'IsSalonHoiQuan',
      render: (record) => (
        <Switch defaultChecked={record.IsSalonHoiQuan} onChange={() => handleChangeStatus(record, 'IsSalonHoiQuan')} />
      )
    },
    {
      title: 'Salon OTP',
      key: 'SalonOTP',
      render: (record) => (
        <Switch defaultChecked={record.SalonOTP} onChange={() => handleChangeStatus(record, 'SalonOTP')} />
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên Salon',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên Salon, Salonbackup'
      // require: true
    },
    {
      label: 'Loại Salon',
      param: 'statusName',
      type: TypeSearch.select,
      options: [
        { value: 1, label: 'A+' },
        { value: 2, label: 'A' },
        { value: 3, label: 'B+' },
        { value: 4, label: 'B' },
        { value: 5, label: 'C+' },
        { value: 4, label: 'C' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Khu vực/Tỉnh',
      param: 'statusName',
      type: TypeSearch.select,
      options: [
        { value: 1, label: 'Hà Nội' },
        { value: 2, label: 'Sài Gòn' },
        { value: 0, label: 'Điện Biên' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Địa chỉ',
      param: 'statusName',
      type: TypeSearch.select,
      options: [
        { value: 1, label: 'Số 7 Cát Linh' },
        { value: 2, label: '346 Khâm Thiên' },
        { value: 0, label: '82 Trần Đại Nghĩa' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Publish',
      param: 'Publish',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    },
    {
      label: 'Salon Hội Quán',
      param: 'IsSalonHoiQuan',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    },
    {
      label: 'Salon OTP',
      param: 'SalonOTP',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/salon/list'
        title='Danh sách Salon'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListSalon}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/salon/list/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
