/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import {
  Button,
  Table,
  Tag,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  notification,
  Modal,
  Upload,
  Checkbox,
  Space
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import ApiEndpoint from './api'
const { TextArea } = Input

export const UpdateScriptPage: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [initialValues, setInitialValues] = useState({
    Name: '',
    Script: ''
  })
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [dataSelector, setDataSelector] = useState([])
  const [scriptDataId, setScriptDataId] = useState('')
  const [dataScript, setDataScript] = useState('')
  const [scriptName, setScriptName] = useState('')

  const handleGetData = async () => {
    setLoading(true)
    try {
      const response: any = await ApiEndpoint.getListSelectScript()
      const { statusCode, data, message } = response
      if (statusCode === 0) {
        setDataSelector(data)
        setLoading(false)
      } else {
        setLoading(false)
        console.log(message, 'message')
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    // return () => {
    handleGetData()
  }, [])
  const onFinish = async (values) => {
    console.log(values)
    setIsloading(true)
    try {
      const body = {
        Name: scriptName,
        Script: dataScript
      }
      console.log(body, 'body')

      // Gửi dữ liệu và đợi cho đến khi nó hoàn thành.
      const response = await ApiEndpoint.updateScript(scriptDataId, body)
      console.log(response, 'response')

      setIsloading(false)
      notification.success({
        description: 'Thành công',
        message: 'Thành công cập nhật dữ liệu cho Script'
      })
      handleGetData()
      setScriptName('')
      setDataScript('')
      setScriptDataId('')
    } catch (error) {
      console.error('Error checking Name:', error)
      notification.error({
        description: 'Lỗi',
        message: 'Lỗi xảy ra trong quá trình update dữ liệu'
      })
      setIsloading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    console.log('')
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    setScriptDataId(value)
  }
  const handleReadFile = async () => {
    // console.log('oke')
    const dataSelect: any = dataSelector.filter((item) => {
      return Number(item.value) === Number(scriptDataId)
    })
    if (dataSelect && dataSelect.length > 0) {
      setScriptName(dataSelect[0].label)
    }
    try {
      const response: any = await ApiEndpoint.getScriptFromId(scriptDataId)
      const { statusCode, data, message } = response
      console.log(response, 're')
      if (statusCode === 0) {
        setDataScript(data)
        setLoading(false)
      } else {
        setLoading(false)
        console.log(message, 'message')
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }

  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    try {
      const body = {
        IsDelete: 1
      }
      // Gửi dữ liệu và đợi cho đến khi nó hoàn thành.
      const response = await ApiEndpoint.updateScript(scriptDataId, body)
      console.log(response, 'response')

      setConfirmLoading(false)
      notification.success({
        description: 'Thành công',
        message: 'Thành công cập nhật dữ liệu cho Script'
      })
      setOpen(false)
      handleGetData()
      setScriptName('')
      setDataScript('')
      setScriptDataId('')
    } catch (error) {
      setConfirmLoading(false)
      console.error('Error checking Name:', error)
      notification.error({
        description: 'Lỗi',
        message: 'Lỗi xảy ra trong quá trình update dữ liệu'
      })
      setIsloading(false)
      setOpen(false)
    }
  }

  const handleCancel = () => {
    console.log('Clicked cancel button')
    setOpen(false)
  }
  return (
    <Spin tip='Đang cập nhật dữ liệu ...' spinning={isLoading}>
      <Modal
        title='Xác nhận xoá script ?'
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Xác nhận Script: {scriptName} này ? </p>
      </Modal>
      <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
        <Form
          className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
          onValuesChange={onValuesChange}
          form={form}
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          autoComplete='off'
        >
          <h3 className='font-bold text-[22px] mt-3 ml-10'>CHỈNH SỬA CÂU LỆNH SQL</h3>
          <div className=' mt-12 custom-form-details-form'>
            <div className=' mt-12 mb-4 custom-form-details-form-header'>
              <Form.Item
                label='Chọn'
                name='ScriptDataId'
                // rules={[{ required: true, message: 'Vui lòng chọn script!' }]}
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Select
                  showSearch
                  style={{ width: 580 }}
                  value={scriptDataId || undefined}
                  onChange={handleChange}
                  options={dataSelector}
                  placeholder='Chọn câu lệnh'
                  optionFilterProp='children'
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase())}
                  // filterSort={(optionA, optionB) =>
                  //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  // }
                />
                <Button
                  type={!scriptDataId ? 'default' : 'primary'}
                  style={{ marginLeft: 20, marginTop: 3 }}
                  onClick={handleReadFile}
                  disabled={!scriptDataId ? true : false}
                >
                  Đọc file
                </Button>
              </Form.Item>
            </div>
            <div>
              <Input
                type='text'
                className='mt-5'
                placeholder='Tên file'
                style={{ width: '100%' }}
                value={scriptName}
                onChange={(e) => setScriptName(e.target.value)}
              />
              <TextArea
                placeholder=''
                id='textarea'
                className='mt-5'
                value={dataScript}
                onChange={(e) => setDataScript(e.target.value)}
              />
            </div>
          </div>
          <Form.Item label='' name='idNo'>
            <Button
              className='w-[90px] mt-12 mb-12 bg-[#4096ff] text-white button-submit-table'
              htmlType='submit'
              disabled={dataScript ? false : true}
              type={!dataScript ? 'default' : 'primary'}
            >
              {!loading ? 'Chỉnh sửa' : <Spin />}
            </Button>
            <Button
              className='w-[90px] mt-12 mb-12 ml-10'
              type={'default'}
              onClick={showModal}
              disabled={dataScript ? false : true}
            >
              {'Xoá Script'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
}
