import { BookingList } from './list'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerBooking: Router = [
  {
    path: '/booking/list',
    element: <BookingList />
  }
]
