export const DateFormat = 'YYYY-MM-DD'
export const TypeSearch = {
  date: 'DatePicker',
  day: 'Date',
  input: 'Input',
  select: 'Select',
  radio: 'Radio',
  autocomplete: 'AutoComplete',
  break: 'Break'
}
export type ColumnConfig = {
  title: string | JSX.Element
  key: string
  dataIndex?: string
  render?: (item) => JSX.Element
}
export type SearchConfig = {
  label?: string
  type: string
  param?: string
  placeholder?: string
  require?: boolean
  mode?: string
  options?: Array<any>
  buttonDate?: boolean
  defaultValue?: number | string
  defaultDate?: boolean
  col?: number
  dateDefault?: boolean
  fieldNames?: {
    label: string
    value: string
  }
}
export type AxiosResponseList = {
  data: { listData: any[]; pageCount: number; pageIndex: number; pageSize: number; totalRecords: number }
  statusCode: number
  message: string
}
export type ParamsSearchDefault = {
  PageSize: number
  PageIndex: number
}
export const transformObject = (inputObj) => {
  const outputObj = {}
  for (const key in inputObj) {
    const propName = key.split('_')[0]
    const propValue = inputObj[key][0]
    if (!outputObj[propName]) {
      outputObj[propName] = []
    }
    outputObj[propName].push(propValue)
  }
  return outputObj
}
export const convertQueryStringToObjectBase = (queryString, columnSearch: SearchConfig[]) => {
  const params = {}
  if (queryString) {
    const pairs = queryString.split('&')
    pairs.forEach((pair) => {
      const [key, value] = pair.split('=')
      const newValue = Number.isInteger(Number(value)) ? parseInt(value, 10) : value
      if (key && value) {
        if (
          [
            'PageSize',
            'PageIndex',
            ...columnSearch
              .filter((i) => {
                return (
                  [TypeSearch.input, TypeSearch.radio].includes(i.type) ||
                  (i.type === TypeSearch.select && i?.mode !== 'multiple')
                )
              })
              .map((i) => i.param)
          ].includes(key)
        ) {
          params[key] = newValue
        }
        // eslint-disable-next-line no-prototype-builtins
        else if (params.hasOwnProperty(key)) {
          if (!Array.isArray(params[key])) {
            params[key] = [params[key]]
          }
          params[key].push(newValue)
        } else {
          params[key] = [newValue]
        }
      }
    })
  }

  return params
}
export const convertParamsAxiosBase = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}
export const JSONToCsv = (data) => {
  const csvRows = []
  const headers = Object.keys(data[0])
  csvRows.push(headers.join(','))
  for (const obj of data) {
    const values = headers.map((header) => {
      const field = obj[header]
      const escapedField = typeof field === 'string' ? `"${field.replace(/"/g, '""')}"` : field
      return escapedField
    })
    csvRows.push(values.join(','))
  }
  const csvString = csvRows.join('\n')

  return csvString
}
