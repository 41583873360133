import { KCSPage } from './kcs'
import { SatisfiedPage } from './satisfied'
import CheckSCSC from './scsc'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerQuality: Router = [
  {
    path: '/quality/check-scsc',
    element: <CheckSCSC />
  },
  {
    path: '/quality/view-scsc',
    element: <CheckSCSC />
  },
  {
    path: '/quality/kcs',
    element: <KCSPage />
  },
  {
    path: '/quality/satisfied',
    element: <SatisfiedPage />
  }
]
