import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const HairStylesEndpoint = getInstanceAxios(process.env.REACT_APP_API_STAFF)

const CheckTitle = (title: string) => {
  return HairStylesEndpoint.get(`/api/StylistInfor/CheckHairStyle?name=${title}`)
}

const CheckTitleVariant = (id: string, idDetail: string, title: string) => {
  return HairStylesEndpoint.get(`/api/StylistInfor/CheckHairStyleDetail?id=${id}&name=${title}&idDetail=${idDetail}`)
}

const getHairStyles = () => {
  return HairStylesEndpoint.get(`/api/StylistInfor/Gets?staffId=0`)
}

const createUpdateHairStyles = (body: any) => {
  return HairStylesEndpoint.post(`/api/StylistInfor/CreateOrUpdate?staffId=0`, body)
}

export default {
  CheckTitle,
  CheckTitleVariant,
  getHairStyles,
  createUpdateHairStyles
}
