import React, { useState, Key, Dispatch, SetStateAction, useRef } from 'react'
import { Button, Modal, Input, Form, notification } from 'antd'
import { validateHairStyleName } from '../utils/validate'
import ApiEndpoint from '../api'
import './style.scss'

type FieldType = {
  title: string
}
interface TitleBoxProps {
  setLoading: Dispatch<SetStateAction<boolean>>
  data: any
  handleGetData: any
}

const TitleBox: React.FC<TitleBoxProps> = ({ setLoading, data, handleGetData }) => {
  const formRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState({
    title: ''
  })
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    setLoading(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onFinish = async (values: FieldType) => {
    // Xử lý khi Form hoàn thành
    console.log('Received values:', values)
    setLoading(true)
    setIsModalOpen(false)
    try {
      const body = {
        name: values.title,
        isDelete: false,
        createdBy: 'Admin',
        hairStyleDetails: []
      }
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode, data, message } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công thêm mới kiểu tóc'
        })
        formRef.current.resetFields() // reset form
        handleGetData()
        setLoading(false)
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: {
    values: FieldType
    errorFields: { name: Key[]; errors: string[] }[]
    outOfDate: boolean
  }) => {
    console.log('Failed:', errorInfo)
  }

  const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setInputValue(value)
    setDisableSubmit(!value)
  }
  return (
    <>
      <div className='title'>
        <h3 className='title-text'>Bộ sưu tập tư vấn kiểu tóc</h3>
        <Button type='primary' className='title-button' onClick={showModal}>
          Thêm kiểu tóc
        </Button>
      </div>
      <Modal title='Tên kiểu tóc' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          ref={formRef}
        >
          <Form.Item<FieldType>
            label=''
            name='title'
            rules={[{ validator: (_, value) => validateHairStyleName(value) }]}
          >
            <Input
              placeholder='Nhập tên kiểu tóc'
              onKeyPress={preventEnterSubmit}
              onChange={handleInputChange}
              //   value={inputValue}
            />
          </Form.Item>
          <Button
            type='primary'
            className={disableSubmit ? 'button-submit-disable' : 'button-submit'}
            disabled={disableSubmit}
            htmlType='submit'
          >
            Lưu
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default TitleBox
