import CVStaff from './cvStaff'
import { CheckinDaily } from './checkinDaily'
import { CheckinReport } from './checkinReport'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerCheckin: Router = [
  {
    path: '/checkin/cv-staff',
    element: <CVStaff />
  },
  {
    path: '/checkin/list',
    element: <CheckinDaily />
  },
  {
    path: '/checkin/report',
    element: <CheckinReport />
  }
]
