import { Select } from 'antd'
import { useEffect, useState } from 'react'

interface Props {
  ListNoti: { value: string; label: string }[]
  setSelectedNotification: any
  SelectedNotification: { value: string; label: string }[]
}

export default function SelectComponent(props: Props) {
  const { ListNoti, setSelectedNotification, SelectedNotification } = props
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined)

  const onChange = (value: string) => {
    setSelectedValue(value)
    setSelectedNotification(value)
  }

  useEffect(() => {
    if (SelectedNotification && SelectedNotification?.length > 0) {
      setSelectedValue(SelectedNotification[0]?.value)
      setSelectedNotification(SelectedNotification[0]?.value)
    }
  }, [SelectedNotification])

  return (
    <Select
      showSearch
      style={{ width: 400 }}
      placeholder='Chọn thông báo'
      optionFilterProp='children'
      onChange={onChange}
      filterOption={(input, option) => (option?.value ?? '').includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
      }
      value={selectedValue}
      options={ListNoti}
    />
  )
}
