/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import '../index.scss'
import { Button, Table, Tag, Form, Input, Radio, Select, Spin, message, Upload, Checkbox, Space } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const { Column, ColumnGroup } = Table
interface DataType {
  key: React.Key
  isChoice: boolean
  title: string
  price: string
  score_factor: number
}
export const AddConfigServicePage: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const onFinish = async () => {
    try {
      setLoading(true)
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    console.log('')
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }
  const columns: ColumnsType<DataType> = [
    {
      title: 'Tất cả',
      dataIndex: 'title',
      render: (text: string) => <>{text}</>
    },
    {
      title: 'Giá',
      dataIndex: 'price'
    },
    {
      title: '	Hệ số điểm HL',
      dataIndex: 'score_factor'
    }
  ]
  const data: DataType[] = [
    {
      key: '1',
      isChoice: true,
      title: '[DV] Shine Combo 100k cho campaign cũ (53)',
      price: '100000.0000',
      score_factor: 1
    },
    {
      key: '2',
      isChoice: false,
      title: '[AEQ] Combo cắt 6 bước 55k (70)',
      price: '55000.0000',
      score_factor: 0
    },
    {
      key: '3',
      isChoice: false,
      title: '[AEQ] Uốn 180k (71)',
      price: '55000.0000',
      score_factor: 0
    },
    {
      key: '4',
      isChoice: false,
      title: '[AEQ] Nhuộm 130k (72)',
      price: '55000.0000',
      score_factor: 0
    },
    {
      key: '5',
      isChoice: false,
      title: '[AEQ] Tẩy tóc 70k (73)',
      price: '55000.0000',
      score_factor: 0
    },
    {
      key: '6',
      isChoice: false,
      title: '(2022) Duỗi tóc 260k (75)',
      price: '55000.0000',
      score_factor: 0
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    }
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10'>CẤU HÌNH THÔNG TIN DỊCH VỤ</h3>
        <div className=' mt-12 custom-form-details-form'>
          <div className=' mt-12 custom-form-details-form-header'>
            <div className=' mt-4 mb-3 custom-form-details-form-header-item-once'>
              <span className='mr-3'>Salon </span>
              <Select
                defaultValue='1'
                style={{ width: 200 }}
                onChange={handleChange}
                options={[
                  { value: '1', label: '82 TDN' },
                  { value: '2', label: '40 TDH' },
                  { value: '3', label: '60 LKT' },
                  { value: '4', label: '23 NTĐ', disabled: true }
                ]}
              />
            </div>
            <div className=' mt-4 mb-3 custom-form-details-form-header-item'>
              <span className='mr-3'>Dịch vụ </span>
              <Select
                defaultValue='1'
                style={{ width: 200 }}
                onChange={handleChange}
                options={[
                  { value: '1', label: 'Chọn dịch vụ' },
                  { value: '2', label: '40 TDH' },
                  { value: '3', label: '60 LKT' },
                  { value: '4', label: '23 NTĐ', disabled: true }
                ]}
              />
            </div>
          </div>
          <div>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
        </div>
        <Form.Item label='' name='idNo'>
          <Button
            className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white button-submit-table'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
