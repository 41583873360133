/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import { Button, Table, Tag, Form, Input, Radio, Select, Spin, message, Upload, Checkbox, Space } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import ApiEndpoint from './api'

export const ExportDataPage: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [isLoading, setIsloading] = useState(false)
  const [dataSelector, setDataSelector] = useState([])
  const [scriptDataId, setScriptDataId] = useState('')
  const [dataScript, setDataScript] = useState('')

  const handleGetData = async () => {
    setLoading(true)
    try {
      const response: any = await ApiEndpoint.getListSelectScript()
      const { statusCode, data, message } = response
      if (statusCode === 0) {
        setDataSelector(data)
        setLoading(false)
      } else {
        setLoading(false)
        console.log(message, 'message')
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    // return () => {
    handleGetData()
  }, [])
  const onFinish = async (values) => {
    console.log(values)
    setIsloading(true)
    try {
      const response: any = await ApiEndpoint.downloadExcel(values.fileName, scriptDataId, setIsloading)
      console.log(response, 'response')
    } catch (error) {
      console.error('Error checking Name:', error)
    } finally {
      // setIsloading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    console.log('')
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    setScriptDataId(value)
  }
  const { TextArea } = Input
  const handleReadFile = async () => {
    console.log('oke')
    try {
      const response: any = await ApiEndpoint.getScriptFromId(scriptDataId)
      const { statusCode, data, message } = response
      console.log(response, 're')
      if (statusCode === 0) {
        setDataScript(data)
        setLoading(false)
      } else {
        setLoading(false)
        console.log(message, 'message')
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }
  console.log(dataScript == '', 'dataScript')
  return (
    <Spin tip='Đang thực hiện nén file Excel ..' spinning={isLoading}>
      <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
        <Form
          className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
          onValuesChange={onValuesChange}
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete='off'
        >
          <h3 className='font-bold text-[22px] mt-3 ml-10'>EXPORT EXCELL THEO YÊU CẦU</h3>
          <div className=' mt-12 custom-form-details-form'>
            <div className=' mt-12 mb-4 custom-form-details-form-header'>
              <Form.Item
                label='Chọn'
                name='ScriptDataId'
                // rules={[{ required: true, message: 'Vui lòng chọn script!' }]}
                style={{ width: '100%', textAlign: 'left' }}
              >
                <Select
                  showSearch
                  style={{ width: 580 }}
                  onChange={handleChange}
                  options={dataSelector}
                  placeholder='Chọn câu lệnh'
                  optionFilterProp='children'
                  filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase())}
                  // filterSort={(optionA, optionB) =>
                  //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  // }
                />
                <Button
                  type={!scriptDataId ? 'default' : 'primary'}
                  style={{ marginLeft: 20, marginTop: 3 }}
                  onClick={handleReadFile}
                  disabled={!scriptDataId ? true : false}
                >
                  Đọc file
                </Button>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label=''
                name='fileName'
                rules={[{ required: true, message: 'Vui lòng nhập tên file!' }]}
                style={{ width: '100%' }}
              >
                <Input type='text' className='mt-5' placeholder='Tên file' style={{ width: '100%' }} />
              </Form.Item>

              <TextArea placeholder='' id='textarea' className='mt-5' disabled={true} value={dataScript} />
            </div>
          </div>
          <Form.Item label='' name='idNo'>
            <Button
              className='w-[90px] mt-12 mb-12 bg-[#4096ff] text-white button-submit-table'
              htmlType='submit'
              disabled={dataScript ? false : true}
              type={!dataScript ? 'default' : 'primary'}
            >
              {!loading ? 'Xuất Excell' : <Spin />}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  )
}
