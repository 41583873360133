import { useEffect, useState } from 'react'
import './App.scss'
import config from 'configs/env'
import { SSO } from '@30shine/sso-erp'
import axios from 'axios'
import DashboardLayout from 'layouts/DashboardLayout'
import { getListMenuAccess } from 'api/users'
import { setUrlAccess } from 'redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/store'

const sso = new SSO(process.env.REACT_APP_ENV || 'test')
function App() {
  const dispatch = useDispatch()
  const { userInfo } = useAppSelector((state) => state.user)
  console.log(userInfo, 'userInfo')

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const fetchCredentials = async () => {
    try {
      const credentials = await sso.ssoLogin()
      if (credentials) {
        localStorage.setItem('AccessToken', credentials.AccessToken)
        localStorage.setItem('RefreshToken', credentials.RefreshToken)
        localStorage.setItem('IdToken', credentials.IdToken)
        const request = axios.create({
          baseURL: config.base_authenticator_v2_url
        })
        request.defaults.headers.common['Authorization'] = 'Bearer ' + credentials.AccessToken
        request.interceptors.response.use(
          (response) => {
            return response
          },
          async (error) => {
            console.log(error)
            // const originalRequest = error.config
            if (error.response.status === 401) {
              // const data = await sso.refreshToken()
              // originalRequest.headers = {
              //   Authorization: 'Bearer ' + data.AccessToken
              // }
              // localStorage.setItem('AccessToken', data.AccessToken)
              // localStorage.setItem('RefreshToken', data.RefreshToken)
              // localStorage.setItem('IdToken', data.IdToken)
              // return request(originalRequest)
              const myUrl = window.location.href
              const domain_login = process.env.REACT_APP_DOMAIN_LOGIN
              window.location.href = domain_login + '/?redirect=' + myUrl
            }
            return Promise.reject(error)
          }
        )
        await fetchAuthorization()
        setIsAuthenticated(true)
      }
    } catch (e) {
      console.log(e)
      if (e?.message === 'Request failed with status code 403') {
        setIsAuthenticated(true)
      }
    }
  }
  const fetchAuthorization = async () => {
    try {
      const menu: any = await getListMenuAccess()
      if (menu.errorCode === 0) {
        dispatch(setUrlAccess(menu.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCredentials()
  }, [])

  return <div className='App'>{isAuthenticated ? <DashboardLayout /> : null}</div>
}

export default App
