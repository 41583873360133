import '../index.scss'
import { useState, useEffect } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { getListStylist, getListSkiner } from '../api'
import { useLocation } from 'react-router-dom'

export const ListPerfomanceV2 = () => {
  const location = useLocation();
  const [reload, setReload] = useState(false)
  const searchParams = new URLSearchParams(location.search);
  const tabsValue = searchParams.get('tabs');
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const columnSkiner: ColumnConfig[] = [
    {
      title: 'ID Nhân viên',
      dataIndex: 'StaffID',
      key: 'StaffID'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'Fullname',
      key: 'Fullname'
    },
    {
      title: 'DT Mỹ phẩm',
      dataIndex: 'cosmeticSales',
      key: 'cosmeticSales'
    },
    {
      title: 'DT dịch vụ/khách',
      dataIndex: 'serviceRevenueGuest',
      key: 'serviceRevenueGuest'
    },
    {
      title: 'DT dịch vụ/giờ',
      dataIndex: 'serviceRevenueHour',
      key: 'serviceRevenueHour'
    },
    {
      title: 'DT dịch vụ',
      dataIndex: 'serviceRevenue',
      key: 'serviceRevenue'
    },
    {
      title: 'Shine combo',
      dataIndex: 'highendBending',
      key: 'highendBending'
    },
    {
      title: 'SC +2',
      dataIndex: 'standardBending',
      key: 'standardBending'
    },
    {
      title: 'SC +3',
      dataIndex: 'highclassDyeing',
      key: 'highclassDyeing'
    },
    {
      title: 'SC +4',
      dataIndex: 'standardDyeing',
      key: 'standardDyeing'
    },
    {
      title: 'DV Skinner',
      dataIndex: 'hairCare',
      key: 'hairCare'
    },
    {
      title: 'Lượt khách',
      dataIndex: 'passengers',
      key: 'passengers'
    },
    {
      title: 'Giờ làm',
      dataIndex: 'workTime',
      key: 'workTime'
    }
  ]
  const columnStylist: ColumnConfig[] = [
    {
      title: 'ID Nhân viên',
      dataIndex: 'StaffID',
      key: 'index'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'Fullname',
      key: 'Fullname'
    },
    {
      title: 'DT mỹ phẩm',
      dataIndex: 'cosmeticSales',
      key: 'cosmeticSales'
    },
    {
      title: 'DT dịch vụ/khách',
      dataIndex: 'serviceRevenueGuest',
      key: 'serviceRevenueGuest'
    },
    {
      title: 'DT dịch vụ/giờ',
      dataIndex: 'serviceRevenueHour',
      key: 'serviceRevenueHour'
    },
    {
      title: 'DT dịch vụ',
      dataIndex: 'serviceRevenue',
      key: 'serviceRevenue'
    },
    {
      title: 'Uốn cao cấp',
      dataIndex: 'highendBending',
      key: 'highendBending'
    },
    {
      title: 'Uốn tiêu chuẩn',
      dataIndex: 'standardBending',
      key: 'standardBending'
    },
    {
      title: 'Nhuộm cao cấp',
      dataIndex: 'highclassDyeing',
      key: 'highclassDyeing'
    },
    {
      title: 'Nhuộm tiêu chuẩn',
      dataIndex: 'standardDyeing',
      key: 'standardDyeing'
    },
    {
      title: 'Dưỡng tóc',
      dataIndex: 'hairCare',
      key: 'hairCare'
    },
    {
      title: 'Lượt khách',
      dataIndex: 'passengers',
      key: 'passengers'
    },
    {
      title: 'Giờ làm',
      dataIndex: 'workTime',
      key: 'workTime'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'createdDate',
      type: TypeSearch.date,
      require: true,
      // buttonDate:true
    },
    {
    label: 'Chọn Văn phòng - Salon',
    param: 'tabs',
    type: TypeSearch.select,
    options: [
      {label: "VP HCM", value: '1'},
      {label: "VP HN", value: '2'},
      {label: "CSVC HN", value: '3'},
      {label: "CSVC HCM", value: '4'},
      {label: "QLCL", value: '5'},
      {label: "346 KT", value: '6'},
      {label: "235 DC", value: '7'},
      {label: "168 NVC", value: '8'},
      {label: "386 NGT BN", value: '9'},
    ],
    placeholder: 'Chọn Văn phòng - Salon',
    fieldNames: { label: 'label', value: 'value' },
    require: true
    },
    {
    label: 'Chọn loại nhân viên',
    param: 'tabs',
    type: TypeSearch.select,
    options: [
      {label: "Skiner", value: '1'},
      {label: "Stylist", value: '2'},
    ],
    placeholder: 'Chọn loại nhân viên',
    fieldNames: { label: 'label', value: 'value' },
    require: true
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.tabs) {
      console.log(changedValues)
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/report/perfomance-v2'
        title='Báo cáo năng suất V2'
        column={tabsValue === '1' ? columnSkiner : columnStylist}
        columnSearch={columnSearch}
        fetchData={tabsValue === '1' ? getListSkiner : getListStylist}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        // linkADD='/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
