import React from 'react'
import { useRoutes } from 'react-router-dom'
import ReportVHV3 from './index'

function ReportRouter() {
  const routes = useRoutes([
    {
      path: '/report',
      element: <ReportVHV3 />
    }
  ])

  return <>{routes}</>
}

export default ReportRouter
