import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'
import axios from 'axios'
import Cookies from 'js-cookie'
import { notification } from 'antd'

export const WebFormEndpoint = getInstanceAxios(process.env.REACT_APP_API_WEBFORM_ERP)

const getListSelectScript = () => {
  return WebFormEndpoint.get(`/api/v1/list-select/script-list`)
}

const getScriptFromId = (ScriptDataId: string) => {
  return WebFormEndpoint.get(`/api/v1/bi-manage/read-file?ScriptDataId=${ScriptDataId}`)
}

const exportExcell = (fileName: string, ScriptDataId: string) => {
  return WebFormEndpoint.get(`/api/v1/bi-manage/export?fileName=${fileName}&ScriptDataId=${ScriptDataId}`)
}

const createScript = (body: any) => {
  return WebFormEndpoint.post(`/api/v1/bi-manage/create`, body)
}

const updateScript = (ScriptDataId: string, body: any) => {
  return WebFormEndpoint.patch(`/api/v1/bi-manage/update/${ScriptDataId}`, body)
}
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken') // Thay đổi key này nếu cần
}

const downloadExcel = async (fileName: string, ScriptDataId: string, setIsloading: any) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url =
    process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/bi-manage/export?fileName=${fileName}&ScriptDataId=${ScriptDataId}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob
    timeout: 600000,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName + `.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
      notification.success({
        description: 'Thành công',
        message: 'Đã xuất dữ liệu ra file excel, Kiểm tra thư mục tải xuống của bạn'
      })
      setIsloading(false)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
      notification.error({
        description: 'Lỗi',
        message: 'Thời gian xử lý của databse quá lâu hoặc sai lệnh query, vui lòng thử lại'
      })
      setIsloading(false)
    })
}

export default {
  getListSelectScript,
  getScriptFromId,
  exportExcell,
  downloadExcel,
  createScript,
  updateScript
}
