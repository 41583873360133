import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
    data: any
}
export default function DeleteNotification(props: Props) {
    const { data } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button
                style={{ display: "flex", alignItems: "center", borderColor: "#1890FF" }}
                shape="round"
                onClick={showModal}

            >
                <span style={{ color: "#1890FF" }}>Xóa</span>
            </Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={true}
                width={500}
                okText="Đồng ý"
                cancelText="Hủy"
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ExclamationCircleOutlined style={{ color: "#1890FF", fontSize: "22px" }} />
                    <span style={{ lineHeight: "30px", marginLeft: "10px", fontSize: "22px", fontWeight: "500" }}>Xác nhận</span>
                </div>
                <div style={{ marginLeft: "32px", marginBottom: "20px", marginTop: "10px" }}>
                    <span style={{ lineHeight: "22px", fontSize: "16px" }}>
                        Bạn có chắc chắn muốn xóa lịch bắn noti
                        <span style={{ fontWeight: "500" }}> {data.name[0].label} </span>
                        được lên lịch bắn vào
                        <span style={{ fontWeight: "500" }}> {data?.date}</span>
                        ?
                    </span>
                </div>
            </Modal>
        </>
    );
};