/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, DatePicker, Form, Input, Radio, Select, Spin } from 'antd'
import { useContext, useEffect, useState } from 'react'
import WrapForm from './WrapForm'
import dayjs from 'dayjs'
import { antIcon } from '../list/page'
import { Contract_type, PayloadCreate, PayloadCreateSubmit, SelectASMSType, SelectType } from '../interface'
import { Link, useNavigate } from 'react-router-dom'
import { setResource } from '../staffSlice'
import { AppContext } from 'layouts/DashboardLayout'
import { useAppDispatch, useAppSelector } from 'redux/store'
import {
  convertParamsAxios,
  createStaff,
  getASMS,
  getCity,
  getContractType,
  getDistrict,
  getManagers,
  getPermission,
  getProfileType,
  getRecruitmentSource,
  getSalons,
  getStaffSkillLevel,
  getStaffType,
  getWard
} from '../api'
import { fetchDataByApiKey } from '../hook/fetchViewStaff'

export const AddStaff: React.FC = () => {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { resource }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  const searchParams = useAppSelector((state) => state.staff.searchParams)

  const [isAddedType, setIsAddedType] = useState(0)
  const [isGroup, setIsGroup] = useState()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: PayloadCreateSubmit) => {
    const payload: PayloadCreate = {
      detail: {
        idName: values.idName,
        idNo: values.idNo,
        idIssuedDate: dayjs(values.idIssuedDate).format('YYYY-MM-DD'),
        idIssuedLocation: values.idIssuedLocation,
        birthday: dayjs(values.birthday).format('YYYY-MM-DD'),
        gender: values.gender,
        genderName: 'string',
        contracts: resource.contractType
          .map((item: Contract_type) => {
            // @ts-ignore: Unreachable code error
            if (values[`contracts_${item.id}`]) {
              return {
                id: item.id,
                name: item.name,
                // @ts-ignore: Unreachable code error
                signedDate: dayjs(values[`contracts_${item.id}`]).format('YYYY-MM-DD')
              }
            }
          })
          .filter((i: Contract_type) => i !== undefined),
        provinceId: values.provinceId,
        districtId: values.districtId,
        wardId: values.wardId,
        address: values.address,
        phone: values.phone,
        email: values.email,
        insuranceNumber: values.insuranceNumber,
        taxCode: values.taxCode,
        bankName: values.bankName,
        bankAccount: values.bankAccount,
        bankAccountName: values.bankAccountName,
        bankBranch: values.bankBranch,
        profileTypes: values.profileTypes,
        jobDesc: values.jobDesc
      },
      working: {
        groupId: values.groupId,
        salonId: values.salonId,
        salonName: resource.salonId.find((i: SelectType) => i.id === values.salonId)?.name,
        staffTypeId: values.staffTypeId,
        managerId: values.managerId,
        skillLevel: values.skillLevel,
        workingDate: dayjs(values.workingDate).format('YYYY-MM-DD'),
        seniorityDate: dayjs(values.seniorityDate).format('YYYY-MM-DD'),
        addedType: values.addedType,
        parentId: Number(values.parentId),
        requireEnroll: values.requireEnroll,
        appearence: values.appearence,
        recruitmentSourceId: values.recruitmentSourceId,
        skillLevelName: resource.skillLevel.find((i: SelectType) => i.id === values.skillLevel)?.name,
        recruitmentSourceName: resource.recruitmentSource.find((i: SelectType) => i.id === values.recruitmentSourceId)
          ?.name,
        staffTypeName: [...resource.staffTypeId, ...resource.staffTypeId2].find(
          (i: SelectType) => i.id === values.staffTypeId
        )?.name
      },
      permission: {
        userName: values.userName.toLowerCase(),
        isAccountLogin: values.isAccountLogin,
        permission: values.permission
      }
    }
    try {
      setLoading(true)
      const res: any = await createStaff(payload)
      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? 'Thêm mới nhân sự thành công' : res.message
      )
      if (res.statusCode === 0) {
        navigate(`/staff/detail/${res.data.id}`)
      }
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    if (changedValues?.provinceId !== undefined) {
      const res: any = await getDistrict(changedValues.provinceId)
      if (res.statusCode === 0) {
        dispatch(setResource({ ...resource, districtId: res.data }))
      }
      form.resetFields(['districtId', 'wardId'])
    }
    if (changedValues?.districtId !== undefined) {
      const res: any = await getWard(changedValues.districtId)
      if (res.statusCode === 0) {
        dispatch(setResource({ ...resource, wardId: res.data }))
      }
      form.resetFields(['wardId'])
    }
    if (changedValues?.salonId !== undefined) {
      const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
      form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    }
    if (changedValues?.addedType !== undefined) {
      setIsAddedType(changedValues?.addedType)
    }
    if (changedValues?.staffTypeId !== undefined) {
      const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
        (i) => i.id === changedValues?.staffTypeId
      )
      dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    }
  }
  const handleFetchData = async () => {
    try {
      await Promise.all([
        fetchDataByApiKey('staffTypeId', getStaffType, dispatch, notification, 1),
        fetchDataByApiKey('staffTypeId2', getStaffType, dispatch, notification, 2),
        fetchDataByApiKey('allSkillLevel', getStaffSkillLevel, dispatch, notification),
        fetchDataByApiKey('salonId', getSalons, dispatch, notification),
        fetchDataByApiKey('provinceId', getCity, dispatch, notification),
        fetchDataByApiKey('recruitmentSource', getRecruitmentSource, dispatch, notification),
        fetchDataByApiKey('permission', getPermission, dispatch, notification),
        fetchDataByApiKey('profileTypes', getProfileType, dispatch, notification),
        fetchDataByApiKey('contractType', getContractType, dispatch, notification),
        fetchDataByApiKey('asms', getASMS, dispatch, notification),
        fetchDataByApiKey('managers', getManagers, dispatch, notification)
      ])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div className='px-5 py-5 min-h-[calc(100vh)] m-auto pb-28 bg-[#F3F3F3] '>
      <div className='w-full text-sm flex gap-x-3 text-[#b7b3b3]'>
        <Link className='hover:text-black' to='#'>
          Quản lý nhân viên
        </Link>{' '}
        <span>{`>`}</span>
        <Link className='hover:text-black' to={`/staff/list?${convertParamsAxios(searchParams)}`}>
          Danh sách nhân viên
        </Link>
        <span>{`>`}</span>
        <span className='text-black'>Thêm mới nhân viên</span>
      </div>
      <Form
        className='m-auto'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 17 }}
        style={{ maxWidth: 900 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='text-center font-bold text-[22px] mt-3 w-[120%]'>THÊM MỚI NHÂN SỰ</h3>
        <div className='personal_information mt-12'>
          <div className='items-baseline flex gap-6 '>
            <div className='w-[180px] text-lg text-[#000] text-right font-bold mb-3'>Thông tin cá nhân</div>
            <div className='w-[700px] text-xs text-[#898989]'>
              <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
            </div>
          </div>
          <div>
            <Form.Item
              label='Tên đăng nhập:'
              name='userName'
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 50, message: 'Không quá 50 ký tự' },
                {
                  pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                  message: 'Tên đăng nhập không hợp lệ'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Họ và tên nhân viên:'
              name='idName'
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                },
                { max: 300, message: 'Không quá 300 ký tự' }
              ]}
            >
              <Input placeholder='Họ và tên theo CCCD' />
            </Form.Item>
            <Form.Item
              label='Số CCCD:'
              name='idNo'
              rules={[
                { required: true, message: 'Số CCCD không được để trống' },
                {
                  pattern: /^(\d{12}|\d{9})$/,
                  message: 'Số CCCD/CMND gồm 9-12 số'
                }
              ]}
            >
              <Input maxLength={12} placeholder='Nhập CCCD 12 chữ số' />
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <span className='text-[red] text-[16px]'>{`* `}</span>
                  Ngày cấp / Nơi cấp
                </div>
              }
            >
              <div className='flex gap-x-3'>
                <Form.Item
                  name='idIssuedDate'
                  className='w-[205px]'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                </Form.Item>
                <Form.Item
                  className='w-[-webkit-fill-available]'
                  name='idIssuedLocation'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Input className='' placeholder='Nhập nơi cấp' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <span className='text-[red] text-[16px]'>{`* `}</span>
                  Ngày sinh / Giới tính
                </div>
              }
            >
              <div className='flex jus gap-x-3'>
                <Form.Item
                  name='birthday'
                  className='w-[205px]'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                </Form.Item>
                <Form.Item
                  className='w-[-webkit-fill-available]'
                  name='gender'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Select options={resource.gender} placeholder='Chọn Giới tính' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <span className='text-[red] text-[16px]'>{`* `}</span>
                  Địa chỉ
                </div>
              }
            >
              <div className='flex gap-x-3'>
                <Form.Item
                  name='provinceId'
                  className='w-[100%]'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Select
                    options={resource.provinceId}
                    placeholder='Tỉnh / thành phố'
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
                <Form.Item
                  className='w-[-webkit-fill-available]'
                  name='districtId'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Select
                    options={resource.districtId}
                    placeholder='Quận / huyện'
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <div>
                  <span className='text-[red] text-[16px]'>{`* `}</span>
                  Địa chỉ cụ thể
                </div>
              }
            >
              <div className='flex gap-x-3'>
                <Form.Item
                  name='wardId'
                  className='w-[50%]'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Select
                    options={resource.wardId}
                    placeholder='Phường / xã / thị trấn'
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
                <Form.Item
                  className='w-[50%]'
                  name='address'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Input placeholder='Số nhà, tên đường, phường / xã / thị trấn ...' />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <div>
                  <span className='text-[red] text-[16px]'>{`* `}</span>
                  SĐT / Mail
                </div>
              }
            >
              <div className='flex gap-x-3'>
                <Form.Item
                  name='phone'
                  rules={[
                    { required: true, message: 'Không được để trống' },
                    {
                      pattern: /^[0][1-9][0-9]{8}$/,
                      message: 'Số điện thoại gồm 10 ký tự và bắt đầu bằng 0'
                    }
                  ]}
                >
                  <Input maxLength={10} className='w-[205px]' placeholder='Số điện thoại' />
                </Form.Item>
                <Form.Item
                  name='email'
                  className='w-[-webkit-fill-available]'
                  rules={[
                    {
                      pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                      message: 'Sai định dạng mail'
                    }
                  ]}
                >
                  <Input placeholder='Mail' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Số BHXH/Thuế:'>
              <div className='flex gap-x-3'>
                <Form.Item name='insuranceNumber'>
                  <Input className='w-[100%]' placeholder='MS BHXH' />
                </Form.Item>
                <Form.Item name='taxCode'>
                  <Input className='w-[100%]' placeholder='MS thuế' />
                </Form.Item>
              </div>
            </Form.Item>
          </div>
        </div>
        <WrapForm title='Thông tin ngân hàng'>
          <div>
            <Form.Item label='Tên ngân hàng:' name='bankName'>
              <Input />
            </Form.Item>
            <Form.Item label='Số tài khoản:' name='bankAccount'>
              <Input />
            </Form.Item>
            <Form.Item label='Tên tài khoản:' name='bankAccountName'>
              <Input />
            </Form.Item>
            <Form.Item label='Chi nhánh ngân hàng:' name='bankBranch'>
              <Input />
            </Form.Item>
          </div>
        </WrapForm>

        <WrapForm title='Thông tin hồ sơ'>
          <div>
            <Form.Item label='Hồ sơ lý lịch:' name='profileTypes'>
              <Select
                allowClear
                mode='multiple'
                options={resource.profileTypes}
                fieldNames={{ label: 'name', value: 'id' }}
                placeholder='Chọn loại hồ sơ'
              />
            </Form.Item>
            <Form.Item label='Mô tả công việc:' name='jobDesc'>
              <Input />
            </Form.Item>
          </div>
        </WrapForm>

        <WrapForm title='Hợp đồng'>
          <div>
            {resource.contractType.map((item: SelectType, index: number) => (
              <Form.Item key={index} label={item.name} name={`contracts_${item.id}`}>
                <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
              </Form.Item>
            ))}
          </div>
        </WrapForm>

        <div className='working_information'>
          <div className='items-baseline flex gap-6 mb-5'>
            <div className='w-[180px] text-lg text-[#000] text-right font-bold'>Thông tin làm việc</div>
            <div className='w-[700px] text-xs text-[#898989]'>
              <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
            </div>
          </div>
          <div>
            <Form.Item label='Khối:' name='groupId' rules={[{ required: true, message: 'Không được để trống' }]}>
              <Select
                placeholder='Chọn khối nhân sự'
                options={resource.groupId}
                fieldNames={{ label: 'name', value: 'id' }}
                onChange={(e) => {
                  setIsGroup(e)
                  form.resetFields(['managerId', 'salonId', 'staffTypeId'])
                }}
              />
            </Form.Item>
            {isGroup === 1 && (
              <Form.Item label='Salon:' name='salonId' rules={[{ required: true, message: 'Không được để trống' }]}>
                <Select
                  placeholder='Chọn Salon'
                  options={role === 'ADMIN' ? resource.salonId : userInfo.listSalon}
                  fieldNames={{ label: 'name', value: 'id' }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                />
              </Form.Item>
            )}

            <Form.Item label='Bộ phận:' name='staffTypeId' rules={[{ required: true, message: 'Không được để trống' }]}>
              <Select
                placeholder='Chọn bộ phận'
                options={isGroup ? (isGroup === 1 ? resource.staffTypeId : resource.staffTypeId2) : []}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </Form.Item>
            {isGroup === 2 && (
              <Form.Item
                label='Tên người quản lý trực tiếp:'
                name='managerId'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Select
                  disabled={isGroup === 1}
                  placeholder='ID - Họ và tên theo CCCD'
                  options={
                    isGroup
                      ? (isGroup === 1 ? resource.asms : resource.managers).map((i: SelectASMSType) => ({
                          ...i,
                          name: `${i?.id} - ${i.name}`
                        }))
                      : []
                  }
                  fieldNames={{ label: 'name', value: 'id' }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (`${option?.id} - ${option.name}` ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            )}
            {isGroup === 1 && resource.skillLevel.length > 0 && (
              <Form.Item
                label='Bậc kỹ năng:'
                name='skillLevel'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Select
                  placeholder='Chọn bậc kỹ năng'
                  options={resource.skillLevel}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>
            )}

            <Form.Item
              label='Ngày vào làm việc:'
              name='workingDate'
              rules={[{ required: true, message: 'Không được để trống' }]}
            >
              <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
            </Form.Item>
            <Form.Item
              label='Ngày tính thâm niên:'
              name='seniorityDate'
              rules={[{ required: true, message: 'Không được để trống' }]}
            >
              <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
            </Form.Item>

            <Form.Item
              label='Loại mã nhân viên:'
              name='addedType'
              rules={[{ required: true, message: 'Không được để trống' }]}
            >
              <Radio.Group options={resource.addedType} />
            </Form.Item>
            {isAddedType === 2 && (
              <Form.Item
                label='Nhập ID mã chính:'
                name='parentId'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Input type='number' />
              </Form.Item>
            )}

            <Form.Item
              label='Yêu cầu điểm danh:'
              name='requireEnroll'
              rules={[{ required: true, message: 'Không được để trống' }]}
            >
              <Radio.Group options={resource.requireEnroll} />
            </Form.Item>
            <Form.Item
              label='Đánh giá ngoại hình:'
              name='appearence'
              rules={[{ required: true, message: 'Không được để trống' }]}
            >
              <Radio.Group options={resource.appearence} />
            </Form.Item>
            <Form.Item label='Nguồn lao động:' name='recruitmentSourceId'>
              <Select allowClear options={resource.recruitmentSource} fieldNames={{ label: 'name', value: 'id' }} />
            </Form.Item>
          </div>
        </div>

        <div className='erp_role'>
          <div className='items-baseline flex gap-6 '>
            <div className='w-[182px] text-lg text-[#000] text-right font-bold'>Phân quyền ERP</div>
            <div className='w-[700px] text-xs text-[#898989]'>
              <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
            </div>
          </div>
          <div>
            <Form.Item
              label='Loại tài khoản:'
              name='isAccountLogin'
              rules={[{ required: true, message: 'Không được để trống' }]}
            >
              <Radio.Group options={resource.isAccountLogin} />
            </Form.Item>
            <Form.Item label='Phân quyền:' name='permission'>
              <Select
                allowClear
                mode='multiple'
                placeholder='Chọn phân quyền'
                options={resource.permission}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </div>
        </div>
        <Button
          className='float-right w-[90px] mt-16 mb-12 bg-[#4096ff] text-white'
          type='primary'
          htmlType='submit'
          disabled={loading}
        >
          {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
        </Button>
      </Form>
    </div>
  )
}
