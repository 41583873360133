import { useState, useEffect, useContext } from 'react'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { Checkbox } from 'antd'
import { AppContext } from 'layouts/DashboardLayout'
import { getSalons, getStaffType } from './api'

export const CheckinReport = () => {
  const [reload, setReload] = useState(false)
  const [options, setOptions] = useState({ salon: [], staffType: [] })
  const { notification } = useContext(AppContext)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'customer_name',
      key: 'customer_name'
    },
    {
      title: 'Bộ phận',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Ca làm việc',
      dataIndex: 'booking_time',
      key: 'booking_time'
    },
    {
      title: 'Giờ checkin',
      dataIndex: 'booking_date',
      key: 'booking_date'
    },
    {
      title: 'Giờ checkout',
      dataIndex: 'stylist_book',
      key: 'stylist_book'
    },

    {
      title: 'Trạng thái',
      dataIndex: 'stylist_current',
      key: 'stylist_current'
    },
    {
      title: 'Quản lý phản hồi',
      dataIndex: 'salonName',
      key: 'salonName'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'date',
      type: TypeSearch.date,
      require: true
    },

    {
      label: 'Chọn salon',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: options.salon,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Bộ phận',
      param: 'staffType',
      type: TypeSearch.select,
      options: options.staffType,
      placeholder: 'Chọn bộ phận',
      fieldNames: { label: 'name', value: 'id' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const handleFetchData = async () => {
    try {
      const [res1, res2, salon]: any = await Promise.all([getStaffType(1), getStaffType(2), getSalons()])
      if (res1.statusCode === 0 && res2.statusCode === 0 && salon.statusCode === 0) {
        setOptions({
          salon: [...salon.data],
          staffType: [...res1.data, ...res2.data]
        })
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/checkin/list'
        title='Thống kê chấm công'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        reload={reload}
        setReload={setReload}
      />
    </div>
  )
}
