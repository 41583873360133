import React, { useState, Key, Dispatch, SetStateAction, useRef, useEffect } from 'react'
import { Button, Modal, Input, Form, notification } from 'antd'
import { validateHairStyleName } from '../utils/validate'
// import { ExclamationCircleOutlined } from '@ant-design/icons'
import VariantData from './VariantData'
import ModalVariantData from './ModalVariantData'
import ApiEndpoint from '../api'
import './style.scss'

type FieldType = {
  title: string
}
interface TitleBoxProps {
  setLoading: Dispatch<SetStateAction<boolean>>
  data: any
  handleGetData: any
}

const HairStyles: React.FC<TitleBoxProps> = ({ setLoading, data, handleGetData }) => {
  const formRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalVariantOpen, setIsModalVariantOpen] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState({
    title: ''
  })
  //   const [modal, contextHolder] = Modal.useModal()
  //   console.log(contextHolder, 'contextHolder')
  useEffect(() => {
    setInitialValues({
      title: data.name
    })
  }, [data])
  const handleOk = () => {
    setIsModalOpen(false)
    setLoading(true)
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onFinish = async (values: FieldType) => {
    // Xử lý update tiêu đề ảnh
    console.log('Received values:', values)
    const body = {
      id: data.id,
      name: values.title,
      createdBy: 'Admin',
      hairStyleDetails: []
    }
    setLoading(true)
    try {
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode, data } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công cập nhật dữ liệu cho kiểu tóc'
        })
        formRef.current.resetFields()
        handleGetData()
        setIsModalOpen(false)
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: {
    values: FieldType
    errorFields: { name: Key[]; errors: string[] }[]
    outOfDate: boolean
  }) => {
    console.log('Failed:', errorInfo)
  }

  const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setInputValue(value)
    setDisableSubmit(!value)
  }
  const handleDeleteVariant = () => {
    console.log('oke')

    Modal.confirm({
      title: 'Xác nhận',
      content: `Bạn có chắc chắn muốn xóa kiểu tóc ${data.name}?`,
      onOk() {
        // Xử lý khi người dùng nhấn nút "OK" (hoặc nút khác tùy theo cấu hình)
        console.log('OK button clicked')
        handleDeleteHairStyle()
      },
      onCancel() {
        // Xử lý khi người dùng nhấn nút "Cancel" (hoặc nút khác tùy theo cấu hình)
        console.log('Cancel button clicked')
      }
    })
  }

  const handleDeleteHairStyle = async () => {
    setLoading(true)
    try {
      const body = {
        id: data.id,
        name: data.name,
        isDelete: true,
        hairStyleDetails: []
      }
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode, message } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công xoá kiểu tóc'
        })
        handleGetData()
        setLoading(false)
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }
  return (
    <div className='hair-wrap'>
      <div className='navbar'>
        <div
          className='navbar-title'
          onClick={() => setIsModalOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsModalOpen(true)
            }
          }}
          role='button'
          tabIndex={0}
        >
          <div className='navbar-title-text'>{data.name}</div>
          <img src='/pen.svg' alt='Kiểu Layer' className='navbar-title-img' />
        </div>
        <div className='navbar-btn'>
          <Button className='navbar-btn-item' onClick={handleDeleteVariant}>
            Xóa
          </Button>
          <Button className='navbar-btn-item' type='primary' onClick={() => setIsModalVariantOpen(true)}>
            Thêm biến thể
          </Button>
        </div>
      </div>
      {data && data.hairStyleDetails?.length > 0 && (
        <div className='body'>
          <div className='body-title'>Biến thể</div>
          <div className='body-wrap'>
            {data.hairStyleDetails?.map((item) => (
              <>
                <VariantData
                  setLoading={setLoading}
                  handleGetData={handleGetData}
                  dataHairStyle={data}
                  createdBy={item.createdBy}
                  createdDate={item.createdDate}
                  id={item.id}
                  isDelete={item.isDelete}
                  name={item.name}
                  urlImage={item.urlImage}
                />
              </>
            ))}
          </div>
        </div>
      )}

      <Modal title='Tên kiểu tóc' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          ref={formRef}
        >
          <Form.Item<FieldType>
            label=''
            name='title'
            rules={[{ validator: (_, value) => validateHairStyleName(value) }]}
          >
            <Input placeholder='Nhập tên kiểu tóc' onKeyPress={preventEnterSubmit} onChange={handleInputChange} />
          </Form.Item>
          <Button type='primary' className='button-submit' disabled={disableSubmit} htmlType='submit'>
            Lưu
          </Button>
        </Form>
      </Modal>
      <ModalVariantData
        nameHairStye={data.name}
        idHairStye={data.id}
        setIsModalVariantOpen={setIsModalVariantOpen}
        isModalVariantOpen={isModalVariantOpen}
        setLoading={setLoading}
        handleGetData={handleGetData}
      />
    </div>
  )
}

export default HairStyles
