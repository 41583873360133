import { createSlice } from '@reduxjs/toolkit'

const innitState = {
  resource: {
    salonId: []
  },
  searchParamsAvatar: {
    Keyword: undefined,
    SalonId: undefined,
    Status: undefined,
    PageSize: 10,
    PageIndex: 1
  },
  searchParamsHair: {
    Keyword: undefined,
    SalonId: undefined,
    Status: undefined,
    PageSize: 10,
    PageIndex: 1
  },
  searchParamsIntroduction: {
    Keyword: undefined,
    SalonId: undefined,
    Status: undefined,
    PageSize: 10,
    PageIndex: 1
  }
}

const stylistSlice = createSlice({
  name: 'stylist',
  initialState: innitState,
  reducers: {
    setResourceStylist: (state, action) => {
      state.resource = action.payload
    },
    setSearchParamsAvatar: (state, action) => {
      state.searchParamsAvatar = action.payload
    },
    setSearchParamsHair: (state, action) => {
      state.searchParamsHair = action.payload
    },
    setSearchParamsIntroduction: (state, action) => {
      state.searchParamsIntroduction = action.payload
    }
  }
})

const { reducer: userReducer, actions } = stylistSlice
export const { setResourceStylist, setSearchParamsAvatar, setSearchParamsIntroduction, setSearchParamsHair } = actions
export default userReducer
