import { useEffect, useState, useContext } from 'react'
import { ColumnConfig } from 'pages/base/util'
import { Button, Col, Form, Row, Select, Table, Input, Modal, notification } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { AppContext } from 'layouts/DashboardLayout'
const { TextArea } = Input
export const AddCSVCPage = () => {
  const { notification } = useContext(AppContext)
  const [open, setOpen] = useState(false)

  const [resource, setResource] = useState({
    city: [
      { name: 'HN', id: 1 },
      { name: 'HCM', id: 2 },
      { name: 'DN', id: 3 }
    ],
    salon: [
      { name: 'salon 1', id: 1 },
      { name: 'salon 2', id: 2 },
      { name: 'salon 3', id: 3 }
    ],
    catalog: [
      { name: 'catalog 1', id: 1 },
      { name: 'catalog 2', id: 2 },
      { name: 'catalog 3', id: 3 }
    ],
    issue: [
      { name: 'issue 1', id: 1 },
      { name: 'issue 2', id: 2 },
      { name: 'issue 3', id: 3 }
    ],
    reason: [
      { name: 'reason 1', id: 1 },
      { name: 'reason 2', id: 2 },
      { name: 'reason 3', id: 3 }
    ],
    position: [
      { name: 'position 1', id: 1 },
      { name: 'position 2', id: 2 },
      { name: 'position 3', id: 3 }
    ]
  })
  const [data, setData] = useState([])
  const [form] = Form.useForm()

  const debouncedHandleChange = debounce((item, value) => {
    setData(
      data.map((i, index) => {
        if (item.index !== index + 1) return i
        else return { ...i, description: value }
      })
    )
  }, 0)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Khu vực',
      dataIndex: 'city',
      key: 'city',
      render: (item) => <p>{item?.name}</p>
    },

    {
      title: 'Salon',
      dataIndex: 'salon',
      key: 'salon',
      render: (item) => <p>{item?.name}</p>
    },
    {
      title: 'Hạng mục',
      dataIndex: 'catalog',
      key: 'catalog',
      render: (item) => <p>{item?.name}</p>
    },
    {
      title: 'Vấn đề thường gặp',
      dataIndex: 'issue',
      key: 'issue',
      render: (item) => <p>{item?.name}</p>
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'reason',
      key: 'reason',
      render: (item) => <p>{item?.name}</p>
    },

    {
      title: 'Vị trí',
      dataIndex: 'position',
      key: 'position',
      render: (item) => <p>{item?.name}</p>
    },
    {
      title: 'Diễn giải hỏng hóc',
      key: 'description',
      render: (item) => (
        <TextArea
          onChange={(e) => {
            debouncedHandleChange(item, e.target.value)
          }}
          value={item.description}
          placeholder='Controlled autosize'
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      )
    },
    {
      title: '',
      key: 'action',
      render: (item) => (
        <div className='flex justify-center items-center text-[red] hover:text-[#ccc]'>
          <CloseOutlined
            onClick={() => {
              setData(data.filter((i) => i.index !== item.index).map((i, index) => ({ ...i, index: index + 1 })))
            }}
          />
        </div>
      )
    }
  ]

  const onValuesChange = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const onFinish = (values) => {
    setData(
      [
        ...data,
        {
          city: resource.city.find((i) => i.id === values.city),
          salon: resource.salon.find((i) => i.id === values.salon),
          catalog: resource.catalog.find((i) => i.id === values.catalog),
          issue: resource.issue.find((i) => i.id === values.issue),
          reason: resource.reason.find((i) => i.id === values.reason),
          position: resource.position.find((i) => i.id === values.position),
          description: `- Sự cố là gì: ....
- Diện tích/ số lượng/ độ dài: ...`
        }
      ].map((i, index) => ({ ...i, index: index + 1 }))
    )
    form.resetFields()
  }
  const handleFetchData = async () => {
    // try {
    // } catch (error) {
    // }
    console.log('===')
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12 pl-8 pt-6 pr-8'>
      <h2 className='text-2xl mb-7'>Thêm mới yêu cầu</h2>
      <Form
        layout='vertical'
        className='pb-10 pr-5 w-[82vw]'
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span={2}>
            <Form.Item
              name={'city'}
              label={'Khu vực '}
              rules={[{ required: true, message: 'Trường bắt buộc phải nhập' }]}
            >
              <Select
                allowClear
                className='w-full'
                placeholder='Chọn khu vực'
                options={resource.city}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  console.log(e)
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              name={'salon'}
              label={'Salon'}
              rules={[{ required: true, message: 'Trường bắt buộc phải nhập' }]}
            >
              <Select
                allowClear
                className='w-full'
                placeholder='Chọn Salon'
                options={resource.salon}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  console.log(e)
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name={'catalog'}
              label={'Hạng mục '}
              rules={[{ required: true, message: 'Trường bắt buộc phải nhập' }]}
            >
              <Select
                allowClear
                className='w-full'
                placeholder='Chọn hạng mục'
                options={resource.salon}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  console.log(e)
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name={'issue'}
              label={'Vấn đề thường gặp'}
              rules={[{ required: true, message: 'Trường bắt buộc phải nhập' }]}
            >
              <Select
                allowClear
                className='w-full'
                placeholder='Chọn vấn đề'
                options={resource.catalog}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  console.log(e)
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name={'reason'}
              label={'Nguyên nhân'}
              rules={[{ required: true, message: 'Trường bắt buộc phải nhập' }]}
            >
              <Select
                allowClear
                className='w-full'
                placeholder='Chọn nguyên nhân'
                options={resource.reason}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  console.log(e)
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              name={'position'}
              label={'Vị trí '}
              rules={[{ required: true, message: 'Trường bắt buộc phải nhập' }]}
            >
              <Select
                allowClear
                className='w-full'
                placeholder='Chọn vị trí '
                options={resource.position}
                fieldNames={{ label: 'name', value: 'id' }}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  console.log(e)
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type='primary'
              htmlType='submit'
              className='mt-[30px] ml-4 flex items-center justify-center hover:bg-[none]'
            >
              <PlusOutlined />
              Thêm vào danh sách
            </Button>
          </Col>
        </Row>
        <Row className='flex justify-end pt-4 pb-4'>
          <Button onClick={() => setOpen(true)} className='border-[#2dbf2d] text-[#2dbf2d]'>
            Hoàn tất danh sách
          </Button>
          <Button
            onClick={() => {
              if (data.length > 0) {
                setData([])
                notification('success', 'Thành công', 'Hủy bỏ danh sách thành công')
              }
            }}
            danger
          >
            Hủy bỏ danh sách
          </Button>
        </Row>
        <Table className='min-w-[1000px] mt-2' dataSource={data} columns={column} pagination={false} bordered />
      </Form>
      <Modal
        title='Xác nhận'
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okText='Hoàn tất'
        cancelText='Hủy'
      >
        <p>Hoàn tất danh sách</p>
      </Modal>
    </div>
  )
}
