import React, { useEffect, useState } from 'react'
import { Button, Form, Radio, RadioChangeEvent, Upload, UploadFile } from 'antd'
import '../index.scss'
import { UploadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import SelectComponent from '../Component/selectNotification'
import SelectTimePush from '../Component/selectTimePush'

const noti = [
  {
    value: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 1',
    label: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 1'
  },
  {
    value: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 2',
    label: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 2'
  },
  {
    value: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 3',
    label: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 3'
  }
]

export default function NotiFile() {
  const [selectedNotification, setSelectedNotification] = useState([])
  const [pushNow, setPushNow] = useState(null)
  const [selectedTime, setSelectedTime] = useState('')
  const [valueRadio, setValueRadio] = useState(null)
  const [time, setTime] = useState(false)
  const [isFull, setIsFull] = useState(false)
  const navigate = useNavigate()

  const onFinish = (values: any) => {
    console.log('Success:', values)
    navigate(`/pushNotification`)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    switch (true) {
      case pushNow:
        setTime(true)
        break
      case !pushNow && selectedTime?.length > 0:
        setTime(true)
        break
      case !pushNow && selectedTime?.length === 0:
        setTime(false)
        break
      default:
        setTime(false)
    }
  }, [pushNow, selectedTime])

  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [file, setFile] = useState([])
  const props = {
    onRemove: (file: any) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file: any) => {
      const newFileList = [file]
      setFileList(newFileList)
      const reader = new FileReader()
      reader.onload = (evt) => {
        setFile(file)
      }
      reader.readAsBinaryString(file)
      return false
    },
    fileList
  }

  const onChange = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value)
  }

  useEffect(() => {
    if (valueRadio && selectedNotification.length > 0 && time) {
      setIsFull(true)
    } else {
      setIsFull(false)
    }
  }, [valueRadio, selectedNotification, time])
  return (
    <div className='cover w-[calc(100vw-300px)]' style={{ padding: 20 }}>
      <h2 className='title'>Gửi thông báo (File)</h2>
      <div className='content'>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label='Danh sách thông báo'
            name='chooseNoti'
            rules={[{ required: selectedNotification ? false : true, message: 'Chọn thông báo' }]}
          >
            <SelectComponent
              ListNoti={noti}
              SelectedNotification={null}
              setSelectedNotification={setSelectedNotification}
            />
          </Form.Item>
          <Form.Item label='File data' name='filedata'>
            <Upload {...props} accept='.csv'>
              <Button style={{ display: 'flex', alignItems: 'center' }} icon={<UploadOutlined />}>
                Import file
              </Button>
            </Upload>
            <span style={{ color: '#1890FF', fontStyle: 'italic' }}>Tải lên file có 1 cột ID khách hàng/ staff</span>
          </Form.Item>
          <Form.Item
            label='Chọn đối tượng bắn'
            name='choseTarget'
            rules={[{ required: valueRadio ? false : true, message: 'Chọn thông báo' }]}
          >
            <Radio.Group onChange={onChange} value={valueRadio}>
              <Radio value={1}>Customer</Radio>
              <Radio value={2}>Staff</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='Lựa chọn lên lịch'
            name='chooseTypePush'
            rules={[{ required: time ? false : true, message: 'Chọn thời gian' }]}
          >
            <SelectTimePush setPushNow={setPushNow} selectedDateTime={null} setSelectedTime={setSelectedTime} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
            <Button
              type={isFull ? 'primary' : 'default'}
              style={{ width: '200px', marginTop: '50px', marginBottom: '100px' }}
              htmlType='submit'
              disabled={!isFull}
            >
              Send notification
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
