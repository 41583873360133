/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useContext } from 'react'
import { FileExcelOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Form, Button, Row, Table, Pagination, Spin, DatePicker, Col, Select, Input, Radio, Space, Tag } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { SpinIndicator } from 'antd/es/spin'
import { AppContext } from 'layouts/DashboardLayout'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { makeId } from 'utils/helpers'
import {
  SearchConfig,
  ParamsSearchDefault,
  TypeSearch,
  convertQueryStringToObjectBase,
  transformObject,
  JSONToCsv,
  DateFormat,
  convertParamsAxiosBase,
  AxiosResponseList
} from 'pages/base/util'
import { getListStaffChange } from 'pages/staff/api'
const { Column, ColumnGroup } = Table
const { RangePicker } = DatePicker
export const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />

export default function TKGS() {
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const refCSV = useRef<HTMLAnchorElement>()
  const [form] = Form.useForm()

  const [params, setParams] = useState<ParamsSearchDefault & any>({
    PageSize: 10,
    PageIndex: 1
  })

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [checkCSV, setCheckCSV] = useState<boolean>(false)
  const [dataCSV, setDataCSV] = useState<any>()
  const [mount, setMount] = useState(false)
  const columnSearch: SearchConfig[] = [
    {
      label: 'Ngày',
      param: 'date',
      type: TypeSearch.date,
      require: true
    }
  ]

  const handleFetchListStaff = async (paramsSearch: ParamsSearchDefault & any) => {
    try {
      setLoading(true)
      const res: AxiosResponseList & any = await getListStaffChange(paramsSearch)
      setLoading(false)
      if (res.statusCode === 0) {
        setTotal(res.data.totalRecords)
        setData(res.data.listData)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
    setLoading(false)
  }

  const prefetchDataStaffList = () => {
    const check = {}
    const paramsSearchResult = convertQueryStringToObjectBase(window.location.search.replace('?', ''), columnSearch)
    if (Object.keys(paramsSearchResult).length > 0) {
      Object.keys(paramsSearchResult).forEach((i) => {
        if (i.includes('_to') || i.includes('_from')) {
          check[i] = paramsSearchResult[i]
          delete paramsSearchResult[i]
        }
      })
      const result: ParamsSearchDefault & any = { ...transformObject(check), ...paramsSearchResult }
      setParams(result)
      handleFetchListStaff(result)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  const onExportStaff = async () => {
    try {
      setDataCSV(JSONToCsv(data))
      setCheckCSV(!checkCSV)
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }

  const onFinish = () => {
    const result: ParamsSearchDefault = {
      PageSize: 0,
      PageIndex: 0
    }
    const value = form.getFieldsValue()
    result.PageSize = params.PageSize
    result.PageIndex = params.PageIndex
    Object.keys(value).map((i) => {
      if (
        typeof value[i] === 'object' &&
        value[i]?.length === 2 &&
        value[i].every((item) => typeof item === 'object')
      ) {
        result[`${i}_from`] = dayjs(value[i][0]).format(DateFormat)
        result[`${i}_to`] = dayjs(value[i][1]).format(DateFormat)
      } else result[i] = value[i]
    })
    for (const key in result) {
      if (result[key] === null || result[key] === undefined) {
        delete result[key]
      }
    }
    navigate(`/supervise/tkgs?${convertParamsAxiosBase({ ...result, PageIndex: 1 })}`)
  }

  const RenderSearchComponent = (type: string, config?: SearchConfig) => {
    const result = {
      DatePicker: (
        <Col span={4}>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <RangePicker
              placeholder={['Từ ngày', 'Đến ngày']}
              format={'DD-MM-YYYY'}
              value={
                params[config.param]
                  ? [dayjs(params[config.param][0] || null), dayjs(params[config.param][1] || null)]
                  : undefined
              }
            />
          </Form.Item>
        </Col>
      ),
      Select: (
        <Col span={3}>
          <Form.Item
            name={config.param}
            label={config.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <Select
              allowClear
              // @ts-ignore: Unreachable code error
              mode={config?.mode}
              className='w-full'
              value={params[config.param]}
              placeholder={config?.placeholder}
              options={config?.options}
              fieldNames={config?.fieldNames}
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>
        </Col>
      ),
      Input: (
        <Col span={3}>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <Input value={params[config.param]} placeholder={config?.placeholder} />
          </Form.Item>
        </Col>
      ),
      Radio: (
        <Col span={3}>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <Radio.Group value={params[config.param]}>
              {(config?.options || []).map((i) => (
                <Radio key={makeId()} value={i.value}>
                  {i.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      )
    }
    return result[type]
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params,
        [keyName]: value ? [dayjs(value[0]).format(DateFormat), dayjs(value[1]).format(DateFormat)] : undefined
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    // handleChangeValue(changedValues, onReset)
  }
  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])
  useEffect(() => {
    if (dataCSV) {
      refCSV.current.click()
    }
  }, [checkCSV])
  useEffect(() => {
    if (mount) {
      columnSearch.forEach((element) => {
        if (element?.type) {
          if (element?.type === TypeSearch.date) {
            const newParams = params[element.param] ? params[element.param].map((i) => dayjs(i)) : ''
            form.setFieldValue(`${element.param}`, newParams)
          } else {
            form.setFieldValue(`${element.param}`, params[element.param])
          }
        }
      })
    }
  }, [mount])

  return (
    <div style={{ padding: 20 }} className='w-full search-wrapper'>
      <Form layout='vertical' className='pb-10 pr-5' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <h2 className='mb-7 text-2xl'>{'Báo cáo thống kê lỗi giám sát nhân viên'}</h2>
        <Row className='items-start flex gap-y-4 xl:w-[90%] 2xl:w-[98%]'>
          {columnSearch.map((item) => {
            return RenderSearchComponent(item.type, item)
          })}
        </Row>
        <div className='bg-white mt-8 2xl:w-[84%] xl:w-[80%]' style={{ padding: '20px 20px 100px 20px' }}>
          <Row className=' flex gap-x-3'>
            <Button
              type='default'
              htmlType='submit'
              className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={onExportStaff}
              type='default'
              className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
            >
              <div className='flex items-center justify-center'>
                <FileExcelOutlined className='mr-1 text-white' />
                Xuất excel
              </div>
            </Button>
            <a
              ref={refCSV}
              style={{ display: 'none' }}
              href={`data:text/csv;charset=utf-8,${encodeURIComponent('\uFEFF' + dataCSV)}`}
              download='danh_sách.csv'
            />

            <Button
              onClick={() => form.resetFields()}
              type='default'
              className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>

          <Row style={{ display: 'contents' }} gutter={18}>
            <div className='flex items-center justify-between'>
              <h3>
                {'Tổng số : '}
                <span className='text-[red] font-semibold'>{total}</span>
              </h3>
              <Pagination
                className='float-right pb-8 mt-8'
                current={params.PageIndex}
                pageSize={params.PageSize}
                total={total}
                onChange={(page: number, pageSize: number) => {
                  setParams({ ...params, PageSize: pageSize, PageIndex: params.PageSize !== pageSize ? 1 : page })

                  navigate(
                    `/supervise/tkgs?${convertParamsAxiosBase({
                      ...params,
                      PageSize: pageSize,
                      PageIndex: params.PageSize !== pageSize ? 1 : page
                    })}`
                  )
                }}
                showSizeChanger
              />
            </div>
            <Table dataSource={data} bordered className='overflow-auto'>
              <Column title='STT' dataIndex='index' key='index' />
              <Column title='Salon' dataIndex='salon' key='salon' />
              <ColumnGroup title='Vệ sinh 3s' className='bg-[red]'>
                <Column title='Nhắc nhở' dataIndex='export' key='export' />
                <Column title='Có lỗi' dataIndex='sell' key='sell' />
                <Column title='Không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
                <Column title='Không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
              </ColumnGroup>
              <ColumnGroup title='Quy định' className='bg-[red]'>
                <Column title='Nhắc nhở' dataIndex='export' key='export' />
                <Column title='Có lỗi' dataIndex='sell' key='sell' />
                <Column title='Lỗi nghiêm trọng' dataIndex='sell' key='sell' />
                <Column title='Không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
                <Column title='Không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
              </ColumnGroup>
              <ColumnGroup title='Quy trình' className='bg-[red]'>
                <Column title='Nhắc nhở' dataIndex='export' key='export' />
                <Column title='Có lỗi' dataIndex='sell' key='sell' />
                <Column title='Lỗi nghiêm trọng' dataIndex='sell' key='sell' />
                <Column title='Không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
                <Column title='Không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
              </ColumnGroup>
              <ColumnGroup title='Văn hóa 5c' className='bg-[red]'>
                <Column title='Nhắc nhở' dataIndex='export' key='export' />
                <Column title='Có lỗi' dataIndex='sell' key='sell' />
                <Column title='Lỗi nghiêm trọng' dataIndex='sell' key='sell' />
                <Column title='Không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
                <Column title='Không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
              </ColumnGroup>
              <ColumnGroup title='Fanpage' className='bg-[red]'>
                <Column title='Nhắc nhở' dataIndex='export' key='export' />
                <Column title='Có lỗi' dataIndex='sell' key='sell' />
                <Column title='Lỗi nghiêm trọng' dataIndex='sell' key='sell' />
                <Column title='Không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
                <Column title='Không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
              </ColumnGroup>
              <ColumnGroup title='Safety' className='bg-[red]'>
                <Column title='Nhắc nhở' dataIndex='export' key='export' />
                <Column title='Có lỗi' dataIndex='sell' key='sell' />
                <Column title='Lỗi nghiêm trọng' dataIndex='sell' key='sell' />
                <Column title='Không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
                <Column title='Không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
              </ColumnGroup>
              <Column title='Tổng chưa xử lý' dataIndex='use_sv' key='use_sv' />
              <Column title='Tổng nhắc nhở' dataIndex='use_sv' key='use_sv' />
              <Column title='Tổng có lỗi' dataIndex='use_sv' key='use_sv' />
              <Column title='Tổng lỗi nghiêm trọng' dataIndex='use_sv' key='use_sv' />
              <Column title='Tổng không lỗi do khách quan' dataIndex='use_sv' key='use_sv' />
              <Column title='Tổng không lỗi do ghi nhận sai' dataIndex='use_sv' key='use_sv' />
            </Table>
            <Pagination
              className='float-right pb-8 mt-8'
              current={params.PageIndex}
              pageSize={params.PageSize}
              total={total}
              onChange={(page: number, pageSize: number) => {
                setParams({ ...params, PageSize: pageSize, PageIndex: params.PageSize !== pageSize ? 1 : page })
                navigate(
                  `/supervise/tkgs?${convertParamsAxiosBase({
                    ...params,
                    PageSize: pageSize,
                    PageIndex: params.PageSize !== pageSize ? 1 : page
                  })}`
                )
                setTimeout(
                  () =>
                    window.document.getElementById('body__page').scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    }),
                  500
                )
              }}
              showSizeChanger
            />
          </Row>
        </div>
      </Form>
    </div>
  )
}
