/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactNode, useState } from 'react'
type WrapFormProps = {
  children: ReactNode
  title: string | ReactNode
  styleLabel?: string
}
export default function WrapForm({ children, title, styleLabel }: WrapFormProps) {
  const [visible, setVisible] = useState(true)
  return (
    <div className={`${!visible ? 'h-10 overflow-hidden' : ''}`}>
      <div className={`items-baseline flex gap-6 mb-5 ${styleLabel}`}>
        <div className='w-[215px] ml-[-35px] text-lg text-[#000] text-right font-bold'>{title}</div>
        <span onClick={() => setVisible(!visible)} className={`text-xl cursor-pointer`}>
          <img
            width={16}
            height={10}
            alt='icon'
            className={`${visible ? 'rotate-0' : 'rotate-[-90deg]'}`}
            src='/vector1.png'
          />
        </span>
      </div>
      {children}
    </div>
  )
}
