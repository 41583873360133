import { ListProduct } from './list'
import ConfigProduct from './configProduct'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerProduct: Router = [
  {
    path: '/product/list',
    element: <ListProduct />
  },
  {
    path: '/product/config',
    element: <ConfigProduct />
  }
]
