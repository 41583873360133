import React, { useCallback, createContext, useEffect, useState } from 'react'
import { Layout, Dropdown, Button, Menu, Spin, notification } from 'antd'
import { CaretDownOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import './styles.scss'
import MainRouter from '../routes/MainRouter'
import logo from 'images/logo_30shine.png'
import personIcon from 'images/person.png'
import { useMediaQuery } from 'react-responsive'
import { SSO } from '@30shine/sso-erp'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchUser } from 'redux/slices/userSlice'
import MainMenu from './MainMenu'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { orNull } from 'utils/selector'
type NotificationType = 'success' | 'info' | 'warning' | 'error'

export const AppContext = createContext({
  notification: undefined,
  loading: false,
  setLoading: undefined
})
const sso = new SSO(process.env.REACT_APP_ENV || 'test')
const { Header, Sider, Content } = Layout

function DashboardLayout() {
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({ message, description })
  }
  const [loading, setLoading] = useState(false)

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const { userInfo } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const user = JSON.parse(localStorage.getItem('user'))
  const fullName = user?.fullName || ''
  const logOut = async () => {
    if (window.location.pathname === '/403') {
      localStorage.clear()
      window.location.href = process.env.REACT_APP_DOMAIN_LOGIN + '/?redirect=' + process.env.REACT_APP_BASE_URL
    } else {
      // Thực hiện xử lý đăng xuất bình thường ở trạng thái khác
      localStorage.clear()
      sso.ssoLogout()
    }
  }

  const menu = (
    <Menu>
      {isMobile && <strong style={{ marginLeft: 10 }}>Xin chào, {orNull('fullName', userInfo)}</strong>}
      <Menu.Item style={{ width: 150 }} key='1' icon={<LogoutOutlined />} onClick={logOut}>
        Đăng xuất
      </Menu.Item>
    </Menu>
  )

  const ComponentLayout = () => {
    const [collapsed, setCollapsed] = useState(false)
    const [show, setShow] = useState(false)
    const [isWebview, setIsWebview] = useState(true)

    const location = useLocation()

    const MainRouterComponent = useCallback(() => {
      return <MainRouter />
    }, [userInfo])

    useEffect(() => {
      setIsWebview(location.pathname.includes('webview'))
    }, [location])

    return (
      <AppContext.Provider
        value={{
          notification: openNotificationWithIcon,
          loading,
          setLoading
        }}
      >
        <Layout id='layout'>
          {contextHolder}
          {!isWebview && (
            <Header className='layout__header' id='layout__header'>
              {isMobile &&
                (show ? (
                  <MenuFoldOutlined
                    onClick={() => setShow(!show)}
                    style={{ color: '#fff', fontSize: 30, marginRight: 20 }}
                  />
                ) : (
                  <MenuUnfoldOutlined
                    onClick={() => setShow(!show)}
                    style={{ color: '#fff', fontSize: 30, marginRight: 20 }}
                  />
                ))}
              <a href={'https://erp.30shine.com'} className='header__logo' target={'_blank'} rel='noreferrer'>
                <img src={logo} alt='' />
              </a>
              <div className='hidden ml-16'>
                {!isMobile &&
                  (show ? (
                    <MenuFoldOutlined
                      onClick={() => {
                        setShow(!show)
                        setCollapsed(false)
                      }}
                      style={{ color: '#fff', fontSize: 30, marginRight: 20 }}
                    />
                  ) : (
                    <MenuUnfoldOutlined
                      onClick={() => {
                        setShow(!show)
                        setCollapsed(true)
                      }}
                      style={{ color: '#fff', fontSize: 30, marginRight: 20 }}
                    />
                  ))}
              </div>
              <Dropdown overlay={menu}>
                <Button className='header__account-button'>
                  <img src={personIcon} alt='' />
                  {!isMobile && (
                    <>
                      <strong style={{ marginLeft: 10 }}>{`Xin chào, ${fullName}`}</strong>
                      <CaretDownOutlined color='white' />
                    </>
                  )}
                </Button>
              </Dropdown>
            </Header>
          )}
          <Layout className={`layout__body ${isWebview ? 'webview' : ''}`}>
            {!isWebview && (show || !isMobile) && (
              <div className='responsive_dashboard'>
                <Sider
                  style={{ width: '230px !important' }}
                  className={`${!collapsed && 'body__sidebar'} w-[230px]`}
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                  defaultCollapsed
                >
                  <MainMenu />
                </Sider>
              </div>
            )}

            <Content
              className={`body__page relative left-[${isMobile ? 0 : collapsed ? '80px' : '230px'}]`}
              id='body__page'
            >
              <Spin spinning={loading}>
                <div className={`md:w-[${collapsed ? 'calc(100vw-80px)' : 'calc(100vw-230px)'}]`}>
                  <MainRouterComponent />
                </div>
              </Spin>
            </Content>
          </Layout>
        </Layout>
      </AppContext.Provider>
    )
  }

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  return (
    <BrowserRouter>
      <ComponentLayout />
    </BrowserRouter>
  )
}

export default DashboardLayout
