import '../index.scss'
import { useState } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Switch, Button } from 'antd'
import { getListSalon } from '../api'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

export const Partitions = () => {
  const [reload, setReload] = useState(false)
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'ID',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Bộ phận',
      dataIndex: 'ShortName',
      key: 'staffTypeName'
    },
    {
      title: 'Chức năng',
      key: 'Publish',
      render: (record) => (
        <>
        <Button onClick={() => handleChangeStatus(record, 'Publish')} ><EditOutlined /></Button>
        <Button onClick={() => handleChangeStatus(record, 'Publish')} style={{ marginLeft : 20 }}><DeleteOutlined /></Button>
        </>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên nhân viên',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên nhân viên'
      // require: true
    },
    {
      label: 'Id nhân viên',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập ID nhân viên'
      // require: true
    },
    {
      label: 'Bộ phận',
      param: 'statusName',
      type: TypeSearch.select,
      options: [
        { value: 1, label: 'CTV' },
        { value: 2, label: 'Quản lý vùng (ASM)' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/salon/shrink'
        title='Quản lý phân vùng Salon'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListSalon}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        // linkADD='/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
