import '../index.scss'
import { useState } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Switch, Button } from 'antd'
import { getListSalon } from '../api'
import { EditOutlined } from '@ant-design/icons'

export const ListRule = () => {
  const [reload, setReload] = useState(false)
  const handleChangeStatus = (record: any, state: string) => {
    console.log(record, 'record')
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'Id',
      key: 'index'
    },
    {
      title: 'ID',
      dataIndex: 'Name',
      key: 'idSql'
    },
    {
      title: 'Quy trình/Quy định',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'Mô tả',
      dataIndex: 'ShortName',
      key: 'staffTypeName'
    },
    {
      title: 'Publish',
      key: 'Publish',
      render: (record) => (
        <Switch defaultChecked={record.Publish} onChange={() => handleChangeStatus(record, 'Publish')} />
      )
    },
    {
      title: 'Chỉnh sửa',
      key: 'Publish',
      render: (record) => (
        <Button onClick={() => handleChangeStatus(record, 'Publish')}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên Quy trình/Quy định',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập tên Quy trình/Quy định'
      // require: true
    },
    {
      label: 'ID Quy trình/Quy định',
      type: TypeSearch.input,
      param: 'staffName',
      placeholder: 'Nhập ID Quy trình/Quy định'
      // require: true
    },
    {
      label: 'Chọn bộ phận',
      param: 'statusName',
      type: TypeSearch.select,
      options: [
        { value: 1, label: 'Stylist' },
        { value: 2, label: 'Skiner' },
        { value: 3, label: 'Lễ tân' },
        { value: 4, label: 'Bảo vệ' },
        { value: 5, label: 'Chuyên viên tư vấn' },
        { value: 5, label: 'Checkout' },
        { value: 6, label: 'Học viên S4M' },
        { value: 7, label: 'Bộ phận chung' }
      ],
      placeholder: 'Chọn bộ phận',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Publish',
      param: 'Publish',
      type: TypeSearch.radio,
      options: [
        { value: 1, label: 'Có' },
        { value: 2, label: 'Không' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/employee/rule'
        title='Quản lý danh mục Quy trình/Quy định'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListSalon}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/employee/rule/add'
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
