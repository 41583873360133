interface EnvInterface {
  greeting: string
  base_authenticator_v2_url: string
}

const env: EnvInterface = {
  greeting: process.env.REACT_APP_GREETING,
  base_authenticator_v2_url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL
}

export default env
