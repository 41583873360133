import React, { useEffect, useState } from 'react'
import { Button, Form } from 'antd';
import SelectComponent from '../Component/selectNotification';
import SelectTimePush from '../Component/selectTimePush';
import "../index.scss"
import { useLocation } from 'react-router-dom';
import TransferComponent from '../Component/transfer';
import { useNavigate } from 'react-router-dom'


const listSalon1 = [
    {
        id: 1,
        name: "Salon 1",
    },
    {
        id: 2,
        name: "Salon 2",
    },
    {
        id: 3,
        name: "Salon 3",
    },
    {
        id: 4,
        name: "Salon 4",
    },

    {
        id: 5,
        name: "Salon 5",
    },
    {
        id: 6,
        name: "Salon 6",
    },
];
const Position = [
    {
        id: 1,
        name: "Stylist",
    },
    {
        id: 2,
        name: "Skinner",
    },
    {
        id: 3,
        name: "Bộ phận chung",
    },
    {
        id: 4,
        name: "Checkout",
    },
    {
        id: 5,
        name: "Bảo vệ",
    },
    {
        id: 6,
        name: "CEO",
    },
];
const noti = [
    {
        value: '1',
        label: 'Ngày hội cha con',
    },
    {
        value: '2',
        label: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 2',
    },
    {
        value: '3',
        label: 'KHAI TRƯƠNG SPA, SOI DA MIỄN PHÍ 3',
    },
];

export default function UpdateNotiSalon() {
    const location = useLocation();
    const [selectedData, setSelectedData] = useState(null);
    const [selectedSalonList, setSelectedSalonList] = useState([]);
    const [selectedPositionList, setSelectedPositionList] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState([]);
    const [pushNow, setPushNow] = useState(null);
    const [selectedTime, setSelectedTime] = useState("");
    const [isFull, setIsFull] = useState(false);
    const [time, setTime] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const record = location.state;
        setSelectedData(record)
    }, [location]);

    const onFinish = (values: any) => {
        console.log(selectedSalonList)
        console.log(selectedPositionList)
        console.log(selectedNotification)
        console.log(pushNow, "pushNow")
        console.log(selectedTime)
        navigate(`/pushNotification`);

    };

    const onFinishFailed = (errorInfo: any) => {
        console.log(errorInfo)
    };

    useEffect(() => {
        switch (true) {
            case pushNow:
                setTime(true);
                break;
            case !pushNow && selectedTime?.length > 0:
                setTime(true);
                break;
            case !pushNow && selectedTime?.length === 0:
                setTime(false);
                break;
            default:
                setTime(false);
        }
    }, [pushNow, selectedTime]);

    useEffect(() => {
        if (selectedSalonList.length > 0
            && selectedPositionList.length > 0
            && selectedNotification.length > 0
            && time
        ) {
            setIsFull(true)
        } else {
            setIsFull(false)

        }
    }, [selectedSalonList, selectedPositionList, selectedNotification, time]);



    return (
        <div className="float-right cover" style={{ width: 'calc(100vw - 240px)', padding: 20 }}>
            <h2 className="title">Sửa thông báo (Salon - bộ phận)</h2>
            <div className="content">
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Danh sách salon"
                        name="chooseSalon"
                        rules={[{ required: selectedSalonList ? false : true, message: 'Chọn Salon nhận thông báo' }]}
                    >
                        <TransferComponent
                            listDataP={listSalon1}
                            listDataSelected={selectedData?.listSalon}
                            setSelectedList={setSelectedSalonList}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Danh sách bộ phận"
                        name="choosePosition"
                        rules={[{ required: selectedPositionList ? false : true, message: 'Chọn bộ phận nhận thông báo' }]}

                    >
                        <TransferComponent
                            listDataP={Position}
                            listDataSelected={selectedData?.listPosition}
                            setSelectedList={setSelectedPositionList}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Danh sách thông báo"
                        name="chooseNoti"
                        rules={[{ required: selectedNotification ? false : true, message: 'Chọn thông báo' }]}
                    >
                        <SelectComponent
                            ListNoti={noti}
                            SelectedNotification={selectedData?.name}
                            setSelectedNotification={setSelectedNotification}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Lựa chọn lên lịch"
                        name="chooseTypePush"
                        rules={[{ required: time ? false : true, message: 'Chọn thời gian' }]}
                    >
                        <SelectTimePush
                            setPushNow={setPushNow}
                            selectedDateTime={selectedData?.date}
                            setSelectedTime={setSelectedTime}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                        <Button
                            type={isFull ? "primary" : "default"}
                            style={{ width: "200px", marginTop: "50px", marginBottom: "100px" }}
                            htmlType="submit"
                            disabled={!isFull}
                        >
                            Lên lịch bắn
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}