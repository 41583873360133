import { DatePicker, Radio, RadioChangeEvent, Select, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import dayjs, { Dayjs } from 'dayjs'
dayjs.extend(customParseFormat)

interface Props {
  setSelectedTime: any
  setPushNow: any
  selectedDateTime: any
}
export default function SelectTimePush(props: Props) {
  const { setSelectedTime, setPushNow, selectedDateTime } = props
  const [valueRadio, setValueRadio] = useState(null)
  const [showSelectDate, setShowSelectDate] = useState(false)
  const [selectDate, setSelectDate] = useState('')
  const [selectTime, setSelectTime] = useState('')
  const [time, setTime] = useState('')

  //radio chọn tg bắn
  const onChange = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value)
  }

  const range = (start: number, end: number) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  //giới hạn ngày bắn
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const minDate = dayjs().startOf('day')
    const maxDate = dayjs().add(30, 'day').endOf('day')
    return current && (current < minDate || current > maxDate)
  }
  // giới hạn giờ bắn
  const disabledTime = () => ({
    disabledHours: () => range(0, 24).splice(0, hours),
    disabledMinutes: () => range(0, minutes),
    disabledSeconds: () => range(0, seconds)
  })
  const currentDate = dayjs().format('YYYY-MM-DD')
  const [hours, setHours] = useState(null)
  const [minutes, setMinutes] = useState(null)
  const [seconds, setSeconds] = useState(null)
  // gán giới hạn giờ bắn
  useEffect(() => {
    if (selectDate === currentDate) {
      setHours(dayjs().hour())
      setMinutes(dayjs().add(20, 'minutes').minute())
      setSeconds(dayjs().second())
      return
    }
    setHours(null)
    setMinutes(null)
    setSeconds(null)
  }, [selectDate])

  // thay đổi ngày
  const onChangeDate = (value: DatePickerProps['value'], dateString: string) => {
    setSelectDate(dateString)
  }
  // thay đổi giờ
  const onChangeTime = (time: Dayjs, timeString: string) => {
    setSelectTime(timeString)
  }
  // ghép ngày và giờ
  useEffect(() => {
    if (selectTime) {
      setTime(selectDate + ' ' + selectTime)
    }
  }, [selectTime, selectDate])
  // lấy thời gian từ id cần sửa
  useEffect(() => {
    if (selectedDateTime) {
      setValueRadio(2)
      const dateTime = dayjs(selectedDateTime)
      const Sdate = dateTime.format('YYYY-MM-DD')
      const Stime = dateTime.format('HH:mm:ss')
      setSelectDate(Sdate)
      setSelectTime(Stime)
      if (time) {
        setSelectedTime(time)
      }
      return
    }
  }, [selectedDateTime])

  //gán thời gian khi chọn đẩy ngay hoặc lên lịch
  useEffect(() => {
    if (valueRadio === 1) {
      setShowSelectDate(false)
      setSelectedTime('')
      setPushNow(true)
    } else if (valueRadio === 2) {
      setPushNow(false)
      setShowSelectDate(true)
      if (time) {
        setSelectedTime(time)
      }
    }
  }, [valueRadio, time])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Radio.Group onChange={onChange} value={valueRadio}>
        <Radio value={1}>Bắn noti ngay</Radio>
        <Radio value={2}>Lên lịch bắn</Radio>
      </Radio.Group>
      <div style={{ display: showSelectDate ? 'flex' : 'none', marginTop: '10px' }}>
        <DatePicker
          style={{ width: '200px' }}
          format='YYYY-MM-DD'
          disabledDate={disabledDate}
          onChange={onChangeDate}
          showToday={false}
          value={selectDate ? dayjs(selectDate, 'YYYY-MM-DD') : undefined}
        />
        <TimePicker
          style={{ width: '200px' }}
          onChange={onChangeTime}
          format='HH:mm:ss'
          disabledTime={disabledTime}
          disabled={selectDate?.length > 0 ? false : true}
          showNow={false}
          hideDisabledOptions
          value={selectTime ? dayjs(selectTime, 'HH:mm:ss') : undefined}
        />
      </div>
    </div>
  )
}
